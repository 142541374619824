<div class="save-document" *ngIf="!hidden">
  <div class="buttons" *ngIf="!showUpload()">
    <button class="md-button md-button-highlighted button import" 
            [disabled]="!canSaveNewDocument()" 
            (click)="saveNewDocument();" 
            *ngIf="mode === 'Import'">
      Import
    </button>
    <button class="md-button md-button-highlighted button save" 
            [disabled]="!canSaveNewVersion()"
            (click)="saveNewVersion()"
            id="save-new-version-button"
            *ngIf="mode === 'Edit'">
      Save New Version
    </button>
    <mat-checkbox [(ngModel)]='saveAndClose' [disabled]='(mode === "Import" && !canSaveNewDocument()) || (mode === "Edit" && !canSaveNewVersion())'>Save and close</mat-checkbox>
  </div>
  
  <!-- UPLOAD PROGRESS -->
  <div class="upload" *ngIf="showUpload()">
    <div *ngIf="saveDocStatus.details" class="status">{{saveDocStatus.details}}</div>
    <mat-progress-bar
      *ngIf="!showDetailedUpload()"
      [color]="'accent'" 
      mode="determinate" 
      [value]="saveDocStatus.overallProgress">
    </mat-progress-bar>
    <mat-progress-bar
      *ngIf="showDetailedUpload()"
      [color]="'accent'" 
      mode="determinate" 
      [value]="detailedProgressPercentage()">
    </mat-progress-bar>
    <span *ngIf="showDetailedUpload()">
      {{detailedProgressText()}}
    </span>
    <button class="md-button md-button-highlighted button cancel" (click)="saveDocStatus.cancel()">Cancel</button>
  </div>

</div>