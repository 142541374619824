import { Directive, Input, HostListener, OnInit, ComponentRef, ElementRef, TemplateRef, OnDestroy } from '@angular/core';
import { OverlayRef, Overlay, OverlayPositionBuilder, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[libTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {

  @Input('libTooltip') libTooltip: TemplateRef<any>;
  @Input() libTooltipPosition: 'below' | 'above' | 'left' | 'right' = 'below';


  private overlayRef: OverlayRef;

  constructor(private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef,
              private overlay: Overlay) {}

  ngOnInit()
  {
    const position = this.getPosition();
    const positionStrategy = this.overlayPositionBuilder
    // Create position attached to the elementRef
    .flexibleConnectedTo(this.elementRef)
    // Describe how to connect overlay to the elementRef
    // Means, attach overlay's center bottom point to the
    // top center point of the elementRef.
    .withPositions([position]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy()
  {
    this.overlayRef.detach();
  }

  private getPosition(): ConnectedPosition
  {
    switch(this.libTooltipPosition)
    {
      case 'above':
        return {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }
      case 'below':
        return {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }
      case 'right':
        return {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center'
        }
      case 'left':
        return {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center'
        }
      default: // below
        return {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }
    }
  }

  @HostListener('mouseenter')
  show()
  {
    // Create tooltip portal
    const tooltipPortal = new ComponentPortal(TooltipComponent);

    // Attach tooltip portal to overlay
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

    // To prevent flickering when the mouse is over the tooltip and the element with the tooltip
    this.overlayRef.overlayElement.setAttribute('style', 'pointer-events: none;');

    // Pass content to tooltip component instance
    tooltipRef.instance.template = this.libTooltip;
  }

  @HostListener('mouseleave')
  hide()
  {
    this.overlayRef.detach();
  }
}
