<div class="header">
  <!-- Task -->
  <div class="task" *ngIf="currentTask">
    <div class="task-header">
      <div class="current-task" (click)="closeTask()" placeholder="Close Task">Exit Task</div>
      <div class="info" (click)="openTaskPreview(currentTask)">
        <span>Open Task details</span>
        <mat-icon class="notranslate">filter_none</mat-icon>
      </div>
    </div> 
    <div class="task-content">
      <div class="task-name">
        <div class="title uppercase">Task {{currentTask.taskNumber}} | <span>{{currentTask.name}}</span></div>
        <!-- <div class="priority">
          <mat-icon class="notranslate">star</mat-icon>
          <mat-icon class="notranslate" *ngIf="currentTask.priority > 1">star</mat-icon>
          <mat-icon class="notranslate" *ngIf="currentTask.priority > 2">star</mat-icon>
        </div> -->
      </div>  
      <div class="task-status" [matTooltip]="getTaskStatus(currentTask)">{{getTaskStatus(currentTask)}}</div>
      <div class="subtask-messages" *ngIf="currentDocument && currentDocIsFromTask() && subTaskMessagesCount && subTaskMessagesCount > 0" (click)="openSubTaskView()">
        {{subTaskMessagesCount}} messages for this document
      </div>
    </div>  
    <div class="task-footer" *ngIf="mode === 'Edit'">
      <button class="add-btn md-textbutton" *ngIf="currentDocument && !docFromTask" (click)="addDocToTask()">
        <mat-icon class="notranslate">add</mat-icon>
        <div>Add document</div>
      </button>
      <mat-icon class="notranslate sub-menu" *ngIf="subTaskState() !== 'Done' && (canSendToDesign() || canSendToReview())" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="canSendToReview()" (click)="changeSubTaskStatus('InReview')" [disabled]="saving">Send to Review</button>
        <button mat-menu-item *ngIf="canSendToDesign()" (click)="changeSubTaskStatus('ToDo')" [disabled]="saving">Back To Do</button>
      </mat-menu>
    </div>
  </div>
  
  <!-- Document -->
  <div class="document" *ngIf="currentDocument && !currentDocument.isPdf">
    
    <div class="document-type" *ngIf="currentDocument && mode === 'Edit'">
      <label class="doc-type" *ngIf="currentDocument.documentType" [innerText]="currentDocument.documentType.name" matTooltip="Document type" matTooltipPosition="above"></label>
      <mat-icon class="edit notranslate" *ngIf="canReclassify" (click)="changeInfos()" matTooltip="Change document information" matTooltipPosition="above" id="edit-classifiers">edit</mat-icon>
    </div>

    <div class="document-name">
      <label class="title moon-number" (click)="copyMoonNumber()">{{currentDocument.moonNumber}}</label>
      <label class="title" [matTooltip]="getTitleTooltip()">{{ getDocName() }}</label>
    </div>

    <div class="document-version" *ngIf="currentDocument.editingVersion && mode === 'Edit'">
      <mat-icon class="notranslate version-info" [libTooltip]="docVersionInfo">info</mat-icon>
      <div class="version-text" id="document-version-text" [innerText]="getVersionText()"></div>
      <div class="version-status">
        <div class="draft" *ngIf="currentDocument.editingVersion.status === 'Draft'">
          <span>Draft</span>
          <mat-icon class="edit notranslate" *ngIf="canApprove" (click)="openVersionStatusDialog()" matTooltip="Change document status" matTooltipPosition="above">edit</mat-icon>
        </div>
        <div class="preapproved" *ngIf="currentDocument.editingVersion.status === 'Preapproved'">
          <span>Preapproved</span>
          <mat-icon class="edit notranslate" *ngIf="canApprove" (click)="openVersionStatusDialog()" matTooltip="Change document status" matTooltipPosition="above">edit</mat-icon>
        </div>
        <div class="approved" *ngIf="currentDocument.editingVersion.status === 'Approved'">
          <span>Approved</span>
          <mat-icon class="edit notranslate" *ngIf="canApprove" (click)="openVersionStatusDialog()" matTooltip="Change document status" matTooltipPosition="above">edit</mat-icon>
        </div>
      </div>
      <div class="check-rules" [ngClass]="{'disabled' : busy }" (click)="checkDocRules()" matTooltip="Check rules for this document" matTooltipPosition="above">
        <mat-icon class="notranslate">done_all</mat-icon>
        <span class="span"> Check Rules </span>
        <mat-progress-spinner *ngIf="busy" class="spinner" diameter="11" color="accent" mode="indeterminate"></mat-progress-spinner>
      </div>
    </div>
    <div class="check-rules" [ngClass]="{'disabled' : busy }" *ngIf="mode !== 'Edit'" (click)="checkDocRules()" matTooltip="Check rules for this document" matTooltipPosition="above">
      <mat-icon class="notranslate">done_all</mat-icon>
      <span class="span"> Check Rules </span>
      <mat-progress-spinner *ngIf="busy" class="spinner" diameter="11" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
    
    <!-- <div class="create-rule" *ngIf="isSuperUser()" (click)="createRule()">
      <span class="label">CREATE RULE</span>
    </div> -->
    <!--MoonDesk Tooltip Content-->
    <ng-template #docVersionInfo>
      <div>{{getVersionTooltip()}}</div>
      <div>{{getStatusTooltip()}}</div>
    </ng-template>    

    <!-- Tags -->
    <tag-list
      [saveChanges]="mode === 'Edit'"
      [docVersionId]="currentDocument?.editingVersion?.id"
      [(tags)]="currentDocument.editingVersion.documentTags"
      class="tags">
    </tag-list>
    <div class="copy-tags-checkbox" *ngIf="mode === 'Edit'">
      <mat-checkbox [ngModel]="keepTags" (ngModelChange)="onKeepTagsChange($event)">
        <span translate>lid.ext.pages.work.work-header.keepTags</span>
      </mat-checkbox>
    </div>
  </div>
</div>