import { Injectable, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IllustratorService } from './illustrator.service';
import { ConfigurationComponent } from '../pages/user/configuration/configuration.component';
import { BACKEND_INFO } from './illustrator-config.service';
import { LoggingService,
         MoonDeskDocument,
         AuthService,
         FeedbackService,
         EventHubService,
         TaskView, 
         TaskDescriptionPopupData} from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { VersionCheckService } from '../pages/_shared/new-version-dialog/version-check.service';
import { TaskDescriptionPopupComponent } from '../pages/tasks/task-description-popup/task-description-popup.component';

@Injectable({
  providedIn: 'root'
})
export class NavigationService
{
  currentPage: 'search' | 'import' | 'edit' | 'tasks' | 'library' | 'work' | 'rules' = 'search';

  constructor(private illService: IllustratorService, private logService: LoggingService,
    private dialog: MatDialog, private feedbackService: FeedbackService,
    private authService: AuthService, private eventHubService: EventHubService,
    private versionCheckService: VersionCheckService)
    {
      this.eventHubService.goToWorkEdit.subscribe((docsOrTask: MoonDeskDocument[] | TaskView) => this.goToEdit(docsOrTask));
      this.eventHubService.goToWorkImport.subscribe((taskView: TaskView) => this.goToImport(taskView));

      this.eventHubService.openTaskView.subscribe(data => this.openTaskDescription(data));
      this.eventHubService.goToTaskWebsite.subscribe((taskView: TaskView) => this.taskWebsite(taskView));
      this.eventHubService.searchLinkedParentDocuments.subscribe(() => this.goToMyLabels());

      this.authService.identityChanged.subscribe(identity =>
      {
        if (identity && identity.user && identity.company)
        {
          this.versionCheckService.checkVersions();
          this.versionCheckService.checkIllustratorVersion();
        }
      });
    }

  navigated: EventEmitter<'search' | 'import' | 'edit' | 'tasks' | 'library' | 'work' | 'rules'>
                = new EventEmitter<'search' | 'import' | 'edit' | 'tasks' | 'library' | 'work' | 'rules'>();

  navToMyLabels: EventEmitter<void> = new EventEmitter<void>();
  // WORKSWITCH
  navToImport: EventEmitter<TaskView> = new EventEmitter<TaskView>();
  navToEdit: EventEmitter<MoonDeskDocument[] | TaskView> = new EventEmitter<MoonDeskDocument[] | TaskView>();
  navToMyTasks: EventEmitter<void> = new EventEmitter<void>();
  navToLibrary: EventEmitter<void> = new EventEmitter<void>();
  navToRules: EventEmitter<void> = new EventEmitter<void>();

  // ##################### Page navigation ##########################
  async goToMyLabels()
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('search');
  }

  // WORKSWITCH
  // async goToImport(task?: MoonTask) {
  //   if(this.authService.getCurrentIdentity() == undefined)
  //   {
  //     return;
  //   }
  //   this.emit('import', null, task);
  // }

  // async goToEdit(docs: MoonDeskDocument[] | TaskView) {
  //   if(this.authService.getCurrentIdentity() == undefined)
  //   {
  //     return;
  //   }
  //   this.emit('edit', docs);
  // }
  async goToImport(taskView: TaskView)
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('import', taskView);
  }

  async goToEdit(docsOrTask: MoonDeskDocument[] | TaskView)
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('edit', docsOrTask);
  }

  async openTaskDescription(data: TaskDescriptionPopupData)
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(TaskDescriptionPopupComponent, {
        width: '90%',
        height: '90%',
        data: <TaskDescriptionPopupData>
        {
          taskId: data.taskId,
          subTaskId: data.subTaskId
        }
      });
      dialogRef.afterClosed().subscribe(() => resolve());
    });
  }

  async goToTasks()
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('tasks', null);
  }

  async goToLibrary()
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('library', null);
  }

  async goToRules()
  {
    if (this.authService.getCurrentIdentity() === undefined)
    {
      return;
    }
    this.emit('rules', null);
  }

  private emit(page: 'search' | 'import' | 'edit' | 'tasks' | 'library' | 'rules', docsOrTask?: MoonDeskDocument[] | TaskView)
  {
    this.logService.trackPageView(page);

    this.currentPage = page;
    this.navigated.emit(page);

    switch (page) {
      case 'search':
        this.navToMyLabels.emit();
      break;
      case 'import':
        this.navToImport.emit(<TaskView>docsOrTask);
      break;
      case 'edit':
        this.navToEdit.emit(docsOrTask);
      break;
      case 'tasks':
        this.navToMyTasks.emit();
      break;
      case 'library':
        this.navToLibrary.emit();
      break;
      case 'rules':
        this.navToRules.emit();
      break;
      default:
        throw Error(`unsupported page: ${page}`);
    }
  }
  async settings(): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(ConfigurationComponent, {
        width: '90%',
        height: '90%'
      });
      dialogRef.afterClosed().subscribe(() => resolve());
    });
  }

  taskWebsite(taskView: TaskView)
  {
    try
    {
      const id = this.authService.getCurrentIdentity();
      if (!id || !id.company)
      {
        throw new Error('No company');
      }
      let url: string;
      if (taskView.subTask)
      {
        url = `${BACKEND_INFO.ipAddress}/company/${id.company.id}/tasks/review/${taskView.task.taskNumber}/subtask/${taskView.subTask.id}`;
      }
      else
      {
        url = `${BACKEND_INFO.ipAddress}/company/${id.company.id}/tasks/review/${taskView.task.taskNumber}`;
      }
      this.logService.trackEvent(`Open url`, null, {['url']: url});
      this.illService.openUrl(url);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error opening web url', err);
    }
  }

  website(): boolean
  {
    const url = BACKEND_INFO.ipAddress;
    this.logService.trackEvent(`Open url`, null, {['url']: url});
    this.illService.openUrl(url);
    return true;
  }

  support(): boolean
  {
    const hubSpotInfo = this.authService.getHubSpotLoginInfo();
    const url = 'https://app.moondesk.design' + '/opensupport/' + hubSpotInfo.email + '/' + hubSpotInfo.username;
    this.logService.trackEvent(`Open url`, null, {['url']: url});
    this.illService.openUrl(url);
    return true;
  }

  async tutorial(): Promise<boolean>
  {
    return new Promise<boolean>(async (res, rej) =>
    {
      const userLang = this.illService.frontendInfo.frontendLocale;
      const languageCode = userLang.split('_');
      let url: string;
      if (languageCode !== undefined && languageCode.length > 0 && languageCode[0] === 'es')
      {
        url = 'https://ayuda.moondesk.design';
      } // spanish 'manual'
      else
      {
        url = 'https://help.moondesk.design';
      } // english
      this.logService.trackEvent(`Open url`, null, {['url']: url});
      const result = this.illService.openUrl(url);
      res(true);
    });
  }

  privacy()
  {
    const url = BACKEND_INFO.ipAddress + '/privacy';
    this.illService.openUrl(url);
  }

  termsAndConditions()
  {
    const url = BACKEND_INFO.ipAddress + '/termsandconditions';
    this.illService.openUrl(url);
  }
}
