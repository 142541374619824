import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';

import { DocumentSharingService, ShareUploadProgress } from '../../../services/document-sharing.service';
import { ExportOptions } from '../../../_models/export-options';
import { AuthService, MoonDeskDocument } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.scss']
})
export class DownloadDocumentsComponent implements OnInit {

  busy: boolean;
  initializing: boolean = true;
  feedbackMsg: string;
  downloadProgress: number;
  status: string;
  downloadService: DocumentSharingService;
  documents: MoonDeskDocument[];
  exportOptions: ExportOptions;
  error: string;
  cancelled: boolean;


  constructor(
        public dialogRef: MatDialogRef<DownloadDocumentsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.downloadService = data.service;
    this.documents = data.documents;
  }

  async ngOnInit()
  {
    this.exportOptions = await this.downloadService.getExportOptions();


    this.initializing = false;
  }

  getTitle()
  {
    if (this.documents.length === 1)
    {
      return 'Share';
    }
    return `Share ${this.documents.length} files`;
  }

  canShare(): boolean
  {
    return !this.busy &&
            ((this.exportOptions.aiFile || this.exportOptions.jpgFile || this.exportOptions.outlineFile ||
            this.exportOptions.pdfFile || this.exportOptions.pngFile || this.exportOptions.zipFile) &&
            (this.exportOptions.downloadFiles || this.exportOptions.uploadFiles));
  }


  async shareFiles()
  {
    if (this.busy)
    {
      return;
    }
    this.exportOptions.zipFile = false;
    this.busy = true;
    this.cancelled = false;
    this.documents.forEach(document => document.cancelExport = false);
    if (!_.some(Object.values(this.exportOptions), option => option === true))
    {
      this.error = 'Error! Please select a file format first';
      return;
    }
    this.downloadProgress = 0;
    this.error = undefined; // important, if not the old error will stay there until the new share finishes
    this.feedbackMsg = undefined;
    this.exportOptions.downloadFiles = false;
    this.error = await this.downloadService.export(this.documents, this.exportOptions, (progress: ShareUploadProgress) =>
    {
      this.downloadProgress = progress.overallProgress;
      if (this.cancelled)
      {
        this.status = 'Cancelling...';
        if (progress.currentProgress)
        {
          progress.currentProgress.cancelAction();
        }
      }
      else
      {
        this.status = progress.details;
      }
    });
    this.busy = false;
    if (!this.error)
    {
      if (this.exportOptions.uploadFiles)
      {
        this.feedbackMsg = 'Well done! Your files are online now and can be downloaded via Web.';
      }
      else
      {
        this.feedbackMsg = 'Well done! Your files has been successfully downloaded';
      }
    }
    else
    {
      this.downloadProgress = 0;
      this.status = '';
    }
  }

  close()
  {
    if (!this.busy)
    {
      this.dialogRef.close();
    }
  }

  cancel()
  {
    this.documents.forEach(document => document.cancelExport = true);
    this.cancelled = true;
    this.status = 'Cancelling...';
  }
}
