<div class="taglist">
  <mat-form-field class="tags" *ngIf="_tags">
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let tag of _tags" [selectable]="false"
                [removable]="true" (removed)="removeTag(tag)">
        <div class="text">{{tag.value}}</div>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input mat-input placeholder="Tags..."
              #tagInput
              id="input-tag-list"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [disabled]="disabled"
              [matAutocomplete]="auto"
              [formControl]="filterTagsController"
              (matChipInputTokenEnd)="addTag($event)"
              (focus)="onInputFocus()">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
      <ng-container *ngIf="busy">
        <mat-option disabled>
          <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="!busy">
        <mat-option *ngFor="let tag of filteredTags" [value]="tag">
          {{tag}}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <div class="spinner" *ngIf="busy">
      <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
  </mat-form-field>
</div>