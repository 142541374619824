<div class="md-popup">

  <div *ngIf="profileBusy" class="md-spinner">
    <mat-progress-spinner class="spinner" diameter="48"  color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>  
  
  <div class="md-popup-header">
    <mat-icon matTooltip="Close" (click)="onNoClick()">close</mat-icon>
    <div class="title">Configuration</div>
  </div>

  <div class="md-popup-content configuration">
    
    <mat-accordion>

      <!-- SYSTEM INFO -->
      <mat-expansion-panel expanded="true" class="sysinfo">
        <mat-expansion-panel-header>
            <span class="md-paneltitle">System Info</span>
        </mat-expansion-panel-header>
        <div class="info">
          <div>Extension Info:</div>
          <div *ngIf="!frontendInfo">
            No frontend info
          </div>
          <div *ngIf="frontendInfo">
            Illustrator: {{frontendInfo.adobeVersion}}
            <br>
            AdobeId: {{frontendInfo.adobeUserId}}
            <br>
            Extension: {{frontendInfo.frontendVersion}}
          </div>
        </div>
        <br>
        <div class="info">
          <div>Backend Info:</div>
          <div *ngIf="!backendInfo">
            No backend info
          </div>
          <div *ngIf="backendInfo">
            Server: {{backendInfo.ipAddress}} ({{backendInfo.cloudName}})
            <br>
            Version: {{backendInfo.version}}
          </div>
        </div>
        <mat-action-row class="actions">            
          <button mat-button (click)="updateSysInfo()">Update</button>
        </mat-action-row>
      </mat-expansion-panel>

      <!-- ACCOUNT -->
      <mat-expansion-panel class="account">
        <mat-expansion-panel-header>
            <span class="md-paneltitle">Account</span>
        </mat-expansion-panel-header>
        <app-profile [user]="user"></app-profile>
      </mat-expansion-panel>

      <!-- SETTINGS -->
      <mat-expansion-panel class="settings">
        <mat-expansion-panel-header>
            <span class="md-paneltitle">Settings</span>
        </mat-expansion-panel-header>
        
        <mat-progress-spinner *ngIf="!configuration" diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
          
        <!-- <div *ngIf="configuration" class="configs">
          <mat-checkbox class="logging" [(ngModel)]="configuration.autoRefresh">Auto Refresh</mat-checkbox>
          <div class="connection">
            <mat-form-field>
              <input matInput placeholder="Server address" [(ngModel)]="configuration.backendUrl" required>
            </mat-form-field>
            <button mat-mini-fab (click)="testConnection()">Test</button>
          </div>
        </div> -->

        <mat-action-row class="actions">
          <div class="actions-button-wrapper">
            <button mat-button (click)="testConnection()">Test connection</button>
            <!-- <button mat-button (click)="save()">Save</button>
            <button mat-button (click)="discard()">Discard</button> -->
          </div>   
          <!-- <div class="actions-button-wrapper">
            <button mat-button (click)="default()">Default</button>
            <button mat-button (click)="goToDebug()">Debugger</button>
          </div>    -->
        </mat-action-row>
      </mat-expansion-panel>
      
      <mat-expansion-panel class="settings">
        <mat-expansion-panel-header>
          <span class="md-paneltitle">Temp Folder</span>
        </mat-expansion-panel-header>
        <mat-progress-spinner *ngIf="!configuration || cacheBusy" diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
        <ng-container *ngIf="configuration && !cacheBusy">
          <div class="tempdirectory">
            <mat-form-field [libTooltip]="tempdirectoryTooltip">
              <input class="directory-input" matInput placeholder="Temp directory" disabled="true" [ngModel]="configuration.tempDirectory" required>
            </mat-form-field>
            <button mat-icon-button (click)="updateTempFolder()">
              <mat-icon class="notranslate">folder</mat-icon>
            </button>
          </div>
          <ng-template #tempdirectoryTooltip>
            <div [innerText]="configuration.tempDirectory"></div> 
          </ng-template>
          <mat-action-row class="actions">
            <div class="actions-button-wrapper">
              <button mat-button (click)="openTempFolder()">Open cache folder</button>
              <button mat-button (click)="deleteTempFolder()">Delete cache</button>
            </div>
          </mat-action-row>
        </ng-container>
      </mat-expansion-panel>

      <!-- SUPER-USERS -->    
      <mat-expansion-panel *ngIf="user && user.isSuperUser" class="superusers">
        <mat-expansion-panel-header>
          <span class="md-paneltitle">Super Users</span>
        </mat-expansion-panel-header>

        Stuff for super users
              
        <mat-action-row class="actions">
            <div class="actions-button-wrapper">
              <button mat-button (click)="test1()">Test 1</button>
              <button mat-button (click)="test2()">Test 2</button>
              <button mat-button (click)="test3()">Test 3</button>
            </div>
        </mat-action-row>
      </mat-expansion-panel>

    </mat-accordion>  
  </div>
</div>