<div class="rules">
    <div class="rules-content">
        <div class="rule-group task-rules" *ngIf="currentTask">
            <div class="header">
                <div class="left-align" (click)="taskRulesToggled = !taskRulesToggled">
                    <span class="arrow-icon">
                        <mat-icon class="notranslate" [ngClass]="{'rotate-90': taskRulesToggled, 'rotate-back': !taskRulesToggled}">play_arrow</mat-icon>
                    </span>
                    <span class="name" matTooltip="Rules based on tasks contents" matTooltipPosition="above">TASK CONTENTS</span>
                </div>
                <div class="right-align">
                    <span class="refresh-icon" (click)="loadTaskRules()">
                        <mat-icon class="notranslate" matTooltip="Refresh" matTooltipPosition="above">refresh</mat-icon>
                    </span>
                </div>
            </div>
            <div class="rules-list" *ngIf="showTaskRules()" [@slideInOut]>
                <div class="rule-card" *ngFor="let contentRule of taskRules">
                    <div class="card-header">
                        <div class="left-align" (click)="contentRule.toggled = !contentRule.toggled">
                            <span class="arrow-icon">
                                <mat-icon class="notranslate" [ngClass]="{'rotate-90': contentRule.toggled, 'rotate-back': !contentRule.toggled}">play_arrow</mat-icon>
                            </span>
                            <span class="name">
                                {{contentRule.contentRuleConfig.ruleDescription}}
                            </span>
                        </div>
                        <div class="right-align">
                            <ng-container *ngIf="!contentRule.busy">
                                <span class="run-icon" [ngClass]="{'disabled': !canRunRule()}">
                                    <mat-icon class="notranslate" (click)="runRule(contentRule)" matTooltip="Run rule" matTooltipPosition="above">play_circle_outline</mat-icon>
                                </span>
                                <!-- <span class="edit-icon" [ngClass]="{'disabled': !contentRule.canEdit}">
                                    <mat-icon class="notranslate" (click)="editRule(contentRule)" matTooltip="Edit rule" matTooltipPosition="above">edit</mat-icon>
                                </span> -->
                            </ng-container>
                            <div class="rule-busy" *ngIf="contentRule.busy">
                                <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="contentRule.toggled" [@slideInOut]>
                        <!-- <span class="line"> 
                            Rule name: {{contentRule.contentTextRuleConfig.ruleDescription}}
                        </span> -->
                        <span class="line" [innerHTML]="contentRuleConfigToString(contentRule)">
                        </span>
                        <span class="line" *ngIf="contentRule.contentRuleConfig.textSize && contentRule.contentRuleConfig.textSize != 0">
                            Text size: {{contentRule.contentRuleConfig.textSize}} mm.
                        </span>
                        <span class="line" *ngIf="contentRule.contentRuleConfig.checkTextCaseSensitive">
                            Case sensitive
                        </span>
                    </div>
                </div>
            </div>
            <div class="busy" *ngIf="taskRulesToggled && taskRulesBusy">
                <span>Getting task rules</span>
                <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
            <div class="empty" *ngIf="taskRulesToggled && !taskRulesBusy && (!taskRules || taskRules.length === 0)">
                <span>No task is open or the current task has no rules configured</span>
            </div>
        </div>

        <div class="rule-group workspace-rules">
            <div class="header">
                <div class="left-align" (click)="workspaceRulesToggled = !workspaceRulesToggled">
                    <span class="arrow-icon">
                        <mat-icon class="notranslate" [ngClass]="{'rotate-90': workspaceRulesToggled, 'rotate-back': !workspaceRulesToggled}">play_arrow</mat-icon>
                    </span>
                    <span class="name" matTooltip="Current workspace custom rules" matTooltipPosition="above">WORKSPACE RULES</span>
                </div>
                <div class="right-align">
                    <span class="add-rule-icon" (click)="createRule()">
                        <mat-icon class="notranslate" matTooltip="Add rule" matTooltipPosition="above">add</mat-icon>
                    </span>
                    <span class="refresh-icon" (click)="loadWorkspaceRules()">
                        <mat-icon class="notranslate" matTooltip="Refresh" matTooltipPosition="above">refresh</mat-icon>
                    </span>
                </div>
            </div>
            <div class="rules-list" *ngIf="showWorkspaceRules()" [@slideInOut]>
                <div class="rule-card" *ngFor="let contentRule of workspaceRules">
                    <div class="card-header">
                        <div class="left-align" (click)="contentRule.toggled = !contentRule.toggled">
                            <span class="arrow-icon">
                                <mat-icon class="notranslate" [ngClass]="{'rotate-90': contentRule.toggled, 'rotate-back': !contentRule.toggled}">play_arrow</mat-icon>
                            </span>
                            <span class="name"> <!--[matTooltip]="contentRule.contentTextRuleConfig.ruleDescription"-->
                                {{contentRule.contentRuleConfig.ruleDescription}}
                            </span>
                        </div>
                        <div class="right-align">
                            <ng-container *ngIf="!contentRule.busy">
                                <span class="tag-icon">
                                    <mat-icon class="notranslate" [libTooltip]="tagTooltip" [libTooltipPosition]="'above'">label</mat-icon>
                                    <ng-template #tagTooltip>
                                        <div class="tag-tooltip">{{ruleTagsToString(contentRule.ruleConfiguration.ruleTags)}}</div> 
                                    </ng-template>
                                </span>
                                <span class="run-icon" [ngClass]="{'disabled': !canRunRule()}">
                                    <mat-icon class="notranslate" (click)="runRule(contentRule)" matTooltip="Run rule" matTooltipPosition="above">play_circle_outline</mat-icon>
                                </span>
                                <span class="edit-icon" *ngIf="contentRule.canEdit">
                                    <mat-icon class="notranslate" (click)="editRule(contentRule)" matTooltip="Edit rule" matTooltipPosition="above">edit</mat-icon>
                                </span>
                                <span class="edit-icon" *ngIf="!contentRule.canEdit">
                                    <mat-icon class="notranslate" (click)="goToWebRule(contentRule)" matTooltip="Edit in web" matTooltipPosition="above">open_in_new</mat-icon>
                                </span>
                            </ng-container>
                            <div class="rule-busy" *ngIf="contentRule.busy">
                                <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="contentRule.toggled" [@slideInOut]>
                        <span class="line">
                            Rule name: {{contentRule.contentRuleConfig.ruleDescription}}</span>
                        <span class="line" [innerHTML]="contentRuleConfigToString(contentRule.contentRuleConfig)">
                        </span>
                        <span class="line" *ngIf="contentRule.contentRuleConfig.textSize && contentRule.contentRuleConfig.textSize != 0">
                            Text size: {{contentRule.contentRuleConfig.textSize}} mm.
                        </span>
                        <span class="line" *ngIf="contentRule.contentRuleConfig.checkTextCaseSensitive">
                            Case sensitive
                        </span>
                    </div>
                </div>
            </div>
            <div class="busy" *ngIf="workspaceRulesToggled && workspaceRulesBusy">
                <span>Getting workspace rules</span>
                <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
            <div class="empty" *ngIf="workspaceRulesToggled && !workspaceRulesBusy && (!workspaceRules || workspaceRules.length === 0)">
                <span>No document is open or no rule applies (a document type must be selected).</span>
            </div>
            <div class="old-results" *ngIf="!workspaceRulesBusy && workspaceRulesToggled && oldResults">
                <span>Document content has changed. Please <span class="refresh-button" (click)="loadWorkspaceRules()">refresh</span> the rule list.</span>
            </div>
        </div>

    </div>
</div>