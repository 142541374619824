import { Component, Input } from '@angular/core';
import {
  MoonTask,
  Identity,
  AuthService,
  SubTask,
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { WorkManagerService, DocumentQueueElement } from './work-manager.service';
import * as _ from 'underscore';
import { FilesystemService } from '../../../services/filesystem.service';
import { CurrentDocumentService } from '../current-document-manager/current-document.service';

@Component({
  selector: 'app-work-manager',
  templateUrl: './work-manager.component.html',
  styleUrls: ['./work-manager.component.scss']
})
export class WorkManagerComponent
{
  changingSubTaskStatus: boolean;

  selectedTabIndex: number = 1; // 0 for tasks / 1 for documents;

  identity: Identity;

  disabled: boolean;
  busy: boolean;

  _task: MoonTask;
  templatesQueue: DocumentQueueElement[];
  docsQueue: DocumentQueueElement[];

  @Input() mode: 'Import' | 'Edit';

  constructor(private authService: AuthService,
              public workManagerService: WorkManagerService,
              private fileService: FilesystemService,
              private currentDocMng: CurrentDocumentService)
  {
    this.authService.identityChanged.subscribe(() =>
    {
      this.loadIdentity();
    });
    this.loadIdentity();

    this.workManagerService.queueChange.subscribe((queue: DocumentQueueElement[]) =>
    {
      this.docsQueue = _.filter(queue, element => !element.isTemplate);
      this.templatesQueue = _.filter(queue, element => element.isTemplate);
    });

    this.workManagerService.currentTaskChange.subscribe((task: MoonTask) =>
    {
      this._task = task;
    });
  }

  getTaskList(): MoonTask[]
  {
    const cd = this.currentDocMng.editDocument;
    if (!cd)
    {
      return [];
    }
    const activeElement = this.docsQueue ? _.find(this.docsQueue, q => cd ? q.document.id === cd.id : undefined) : undefined;
    if (!activeElement || !activeElement.tasks)
    {
      return [];
    }
    return activeElement.tasks;
  }

  private loadIdentity()
  {
    this.identity = this.authService.getCurrentIdentity();
  }

  // DOCUMENTS

  getDocNameForList(path: string)
  {
    return path ? this.fileService.filenameOf(path) : '--';
  }

  removeTodo(element: DocumentQueueElement)
  {
    this.workManagerService.removeQueueElement(element);
  }

  selectTodo(element: DocumentQueueElement)
  {
    this.workManagerService.selectQueueElement(element);
  }

  // TASKS

  async openTaskPreview(task: MoonTask)
  {
    this.workManagerService.openTaskPreview(task.id);
  }

  subTaskState(): 'ToDo' | 'InReview' | 'Done' | 'Closed'
  {
    if (this._task)
    {
      const subtask = this.findCurrentSubTask();
      if (subtask)
      {
        return subtask.status;
      }
    }
    return undefined;
  }

  async changeSubTaskStatus()
  {
    if (!this._task)
    {
      return;
    }
    const subtask = this.findCurrentSubTask();
    if (!subtask)
    {
      return;
    }
    if (subtask.status === 'ToDo')
    {
      await this.workManagerService.changeSubTaskStatus(subtask, 'InReview');
    }
    else
    {
      await this.workManagerService.changeSubTaskStatus(subtask, 'ToDo');
    }
  }

  private findCurrentSubTask(): SubTask
  {
    const subtask = this.currentDocMng.editDocument
                          ? _.find(this._task.subTasks, st => st.document.id === this.currentDocMng.editDocument.id)
                          : undefined;
    return subtask;
  }

  isElementActive(element: DocumentQueueElement): boolean
  {
    const currentWorkingCopy = this.currentDocMng.editDocument ? this.currentDocMng.editDocument.workingCopy : undefined;
    if (currentWorkingCopy)
    {
      return currentWorkingCopy === element.document.workingCopy;
    }
    return false;
  }

  showComponent()
  {
    if (this.mode === 'Edit')
    {
      return this.docsQueue && this.docsQueue.length > 0;
    }
    else if (this.mode === 'Import')
    {
      return this.templatesQueue && this.templatesQueue.length > 0;
    }
    return false;
  }
}
