import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import { Identity, AuthService, EventHubService, FeedbackService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit
{
  @Input() showIcons: boolean;
  loading: boolean = true;
  identity: Identity;

  @ViewChild('registerpanel', { static: false }) registerPanel: MatExpansionPanel;

  constructor(private authService: AuthService,
              private eventHub: EventHubService,
              private feedback: FeedbackService)
  {
    this.eventHub.toggleSideNav.subscribe(x =>
    {
      if (x === 'Register' && this.registerPanel)
      {
        this.registerPanel.expanded = true;
      }
    });
  }

  async ngOnInit()
  {
    try
    {
      this.authService.identityChanged.subscribe(identity =>
      {
        this.identityChanged(identity);
      });
      await this.authService.waitForInit();
      this.identityChanged(await this.authService.getCurrentIdentity());
    }
    catch (err)
    {
      this.feedback.notifyError(err);
    }
    this.loading = false;
  }

  private identityChanged(identity: Identity)
  {
    this.identity = identity;
  }

  isSuperUser()
  {
    return this.identity.user.isSuperUser;
  }

  getCompanyRoleString()
  {
    if (this.identity && this.identity.company)
    {
      return this.identity.company.name + ' | ' + this.getRoleString(this.identity.role);
    }
    else
    {
      return 'No company selected';
    }
  }

  private getRoleString(role: number) {
    switch (role)
    {
      case 0:
        return 'None';
      case 1:
        return 'Stakeholder';
      case 2:
        return 'User';
      case 3:
        return'Admin';
    }
  }
}
