<div class="md-popup rule-create">
    <div class="md-popup-header rule-header">
        <span class="title">Rule Edit</span>
    </div>
    <div class="md-popup-content rule-content">
        <div class="rule-name">
            <!-- <span class="label">Rule Name</span> -->
            <mat-form-field>
                <input matInput type="text" placeholder="Rule Name" color="primary" [formControl]="description" required>
            </mat-form-field>
        </div>
        <div class="rule-configs">
            <fieldset class="rule-config">
                <legend class="label">If the document is</legend>
                <div class="doc-config">
                    <div class="doc-types">
                        <ng-container *ngIf="selectedDocumentTypes && selectedDocumentTypes.length > 0">
                            <ng-container *ngFor="let docType of selectedDocumentTypes; let i = index;">
                                <div class="card logical-op" *ngIf="selectedDocumentTypes[i-1]">
                                    <span>Or</span>
                                </div>
                                <div class="card item">
                                    <span>DocType: {{docType.name}}</span>
                                    <span (click)="removeDocType(docType)">
                                        <mat-icon class="notranslate">clear</mat-icon>
                                    </span>
                                </div>
                            </ng-container>
                            <span class="add-button" (click)="toggleDocTypesSearch($event.target)">
                                <mat-icon class="notranslate">add_circle</mat-icon>
                            </span>
                            <!-- <ng-container *ngTemplateOutlet="searchDocTypes"></ng-container> -->
                        </ng-container>
                        <div class="empty" *ngIf="!selectedDocumentTypes || selectedDocumentTypes.length == 0" (click)="toggleDocTypesSearch($event.target)">
                            <span class="add-button">
                                <mat-icon class="notranslate">add_circle</mat-icon>
                            </span>
                            <span>Click here to add document types</span>
                        </div>
                        <!-- <ng-container *ngTemplateOutlet="searchDocTypes"></ng-container> -->
                    </div>
                    <div class="class-values">
                        <ng-container *ngIf="selectedClassValues && selectedClassValues.length > 0">
                            <ng-container *ngFor="let classValue of selectedClassValues; let i = index;">
                                <div class="card logical-op" *ngIf="selectedClassValues[i-1]">
                                    <span *ngIf="selectedClassValues[i-1].classId === classValue.classId">Or</span>
                                    <span *ngIf="selectedClassValues[i-1].classId !== classValue.classId">And</span>
                                </div>
                                <div class="card item">
                                    <span>{{classValue.class.name}}: {{classValue.name}}</span>
                                    <span (click)="removeClassValue(classValue)">
                                        <mat-icon class="notranslate">clear</mat-icon>
                                    </span>
                                </div>
                            </ng-container>
                            <span class="add-button" (click)="toggleClassValueSearch($event.target)">
                                <mat-icon class="notranslate">add_circle</mat-icon>
                            </span>
                            <!-- <ng-container *ngTemplateOutlet="searchClassValues"></ng-container> -->
                        </ng-container>
                        <div class="empty" *ngIf="!selectedClassValues || selectedClassValues.length == 0" (click)="toggleClassValueSearch($event.target)">
                            <span class="add-button">
                                <mat-icon class="notranslate">add_circle</mat-icon>
                            </span>
                            <span>Click here to add class values</span>
                        </div>
                        <!-- <ng-container *ngTemplateOutlet="searchClassValues" #test></ng-container> -->
                    </div>
                </div>
            </fieldset>
            <fieldset class="rule-config">
                <legend class="label" *ngIf="shouldExists">Should exist</legend>
                <legend class="label" *ngIf="!shouldExists">Should not exist</legend>
                <div class="text-config">
                    <div class="field">
                        <textarea *ngIf="!richCheckText" class="content-input" id="content" placeholder="Content" matInput [formControl]="documentContent" required></textarea>
                        <span *ngIf="richCheckText" [innerHTML]="getCheckTextString(richCheckText)"></span>
                    </div>
                    <div class="edit-hover" *ngIf="richCheckText">
                        <span class="edit-button" (click)="goToWebRule()">
                            <mat-icon class="notranslate" >open_in_new</mat-icon>
                            <span class="text">Edit in web</span>
                            <!-- <button class="md-button">Edit in web</button> -->
                        </span>
                    </div>
                </div>
            </fieldset>
            <div class="rule-details">
                <div class="field">
                    <mat-checkbox [(ngModel)]="caseSensitive">Case sensitive</mat-checkbox>
                </div>
                <div class="field">
                    <span>Min size </span>
                    <input class="size-input" matInput min="0" type="number" [formControl]="textSize">
                    <span>mm</span>
                </div>
            </div>
        </div>
    </div>
    <div class="md-popup-actions">
        <button class="md-button" *ngIf="!ruleBusy" (click)="close()">Cancel</button>
        <button class="md-button md-button-outstanding" *ngIf="!ruleBusy" (click)="save(true)">Save</button>
        <div class="busy" *ngIf="ruleBusy">
            <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
    <div class="footer">
        <span class="delete-icon">
            <mat-icon class="notranslate" [ngClass]="{'disabled': !(rule && rule.id)}" (click)="deleteRule()">delete</mat-icon>
        </span>
    </div>
</div>

<ng-template #searchDocTypes>
    <div class="doc-types-finder">
        <span class="search-input">
            <input matInput type="text" placeholder="Search" [formControl]='docTypeSearchText'>
        </span>
        <div class="items">
            <span class="item" *ngFor="let dt of filteredDocTypes" (click)="addDocType(dt)">
                DocType: {{dt.name}}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #searchClassValues>
    <div class="class-values-finder">
        <span class="search-input">
            <input matInput type="text" placeholder="Search" [formControl]='clsValueSearchText'>
        </span>
        <div class="items">
            <span class="item" *ngFor="let cv of filteredClassValues" (click)="addClassValue(cv)">
                {{cv.class.name}}: {{cv.name}}
            </span>
        </div>
    </div>
</ng-template>

