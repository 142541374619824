<div class="userinfo">
    <div *ngIf="user" class="fields">
        <mat-form-field class="username">
          <input #usernamefield matInput type="text" name="userNameField" placeholder="Username" [(ngModel)]="user.username" (input)="emit()" [disabled]="userNameEditable == false">
        </mat-form-field>
        <mat-form-field class="firstname">
          <input matInput type="text"  name="firstNameField" placeholder="First Name" [(ngModel)]="user.firstName" (input)="emit()">
        </mat-form-field>
        <mat-form-field class="lastname">
          <input matInput type="email" name="lastNameField" placeholder="Last Name" [(ngModel)]="user.lastName" (input)="emit()">
        </mat-form-field>
        <mat-form-field class="email">
          <input matInput type="email" name="emailField" placeholder="Email" [formControl]="emailControl" [(ngModel)]="user.email" (input)="emit()">
          <mat-error *ngIf="emailControl.invalid">Please enter a valid email address</mat-error>
        </mat-form-field>
        <span *ngIf="emailControl.dirty && existingUser" class="email-hint">We'll send an email with a verification link to your new email address. You'll need to open the email and click the link.</span>
        <div class="optional" *ngIf="showOldPassword">Change password (optional):</div>
        <mat-form-field class="password" *ngIf="showOldPassword && !hidePasswordFields">
          <input matInput type="password" placeholder="Old Password" [(ngModel)]="user.oldPassword" (input)="emit()">
        </mat-form-field>
        <mat-form-field class="password" *ngIf="!hidePasswordFields">
          <input matInput type="password" name="passwordField" placeholder="Password" [(ngModel)]="user.password" (input)="emit()">
        </mat-form-field>
        <mat-form-field class="password" *ngIf="!hidePasswordFields">
          <input matInput type="password" name="repeatPasswordField" placeholder="Repeat Password" (keyup)="keyup($event)" [(ngModel)]="user.repeatPassword" (input)="emit()">
        </mat-form-field>
    </div>
    <div *ngIf="!user">No user</div>
  </div>
