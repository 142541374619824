import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-library-naming-dialog',
  templateUrl: './library-naming-dialog.component.html',
  styleUrls: ['./library-naming-dialog.component.scss']
})
export class LibraryNamingDialogComponent {

  resourceName: string;
  constructor(public dialogRef: MatDialogRef<LibraryNamingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.resourceName = data.name;
  }

  confirm()
  {
    this.dialogRef.close(this.resourceName);
  }

  cancel()
  {
    this.dialogRef.close();
  }

  isValidFilename(): boolean
  {
    const invalidChars = /[\\\/:*?"<>| ]/;
    return !invalidChars.test(this.resourceName) && this.resourceName?.length > 0;
  }

}
