import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { FilesystemService } from 'src/app/services/filesystem.service';
import { IllustratorConfigService } from 'src/app/services/illustrator-config.service';
import {
        MoonDeskDocument,
        DocumentNameService,
        MoonTask
        } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class FilepathService {

  constructor(private fileService: FilesystemService,
              private illConfigService: IllustratorConfigService,
              private nameService: DocumentNameService)
              {}

  private previewImageFilename: string = 'previewImage.png';
  private fullImageFilename: string = 'fullImage.png';
  private tempLabelFilename: string = 'importLabel.ai';
  private captureFilename: string = 'capture.png';
  private previewPdfName: string = 'preview.pdf';
  private outlineFilename: string = 'outline.ai';

//   getLogFilepath(): string {
//       // CEPHtmlEngine8-ILST-22.1.0-com.ciclosoftware.contras.log
//       var path = this.fileService.getLogfileDirectory();
//       var fileName = `CEPHtmlEngine8-ILST-${this.illService.appInfo.version}-com.ciclosoftware.contras.log`;
//       var filePath = this.fileService.join(path, fileName);
//       return filePath;
//   }

  async createPreviewImageTempPath(): Promise<string>
  {
      const path = await this.getTempFilePath(this.previewImageFilename);
      return path;
  }

  async createFullImageTempPath(): Promise<string>
  {
      const path = await this.getTempFilePath(this.fullImageFilename);
      return path;
  }

  async createPreviewPdfTempPath(): Promise<string>
  {
      const path = await this.getTempFilePath(this.previewPdfName);
      return path;
  }

  async createCaptureTempPath(): Promise<string>
  {
      const path = await this.getTempFilePath(this.captureFilename);
      return path;
  }

  async createTempLabelPath(): Promise<string>
  {
      const path = await this.getTempFilePath(this.tempLabelFilename);
      return path;
  }

  async createOutlineTempPath(): Promise<string>
  {
    const path = await this.getTempFilePath(this.outlineFilename);
    return path;
  }

  /**
   * Like getWorkingCopyPath, but skipping files that already exist in disk.
   * Instead it's adding a number _x in the end...
   */
  async getFreeWorkingCopyPath(moonDeskDocument: MoonDeskDocument, filetype: 'InWork' | 'Export', fileExtension: string): Promise<string>
  {
    const folder = await this.getDocumentsPath(moonDeskDocument.id);
    const existingFiles = this.fileService.readdirSync(folder, false, 'files');
    let docname = this.nameService.createDocumentName(filetype, moonDeskDocument, null, fileExtension);
    let counter = 0;
    while (_.any(existingFiles, ex => ex.toLocaleLowerCase() === docname.toLowerCase()))
    {
      ++counter;
      docname = this.nameService.createDocumentName(filetype, moonDeskDocument, `_${counter}`, fileExtension);
    }
    const result = this.fileService.join(folder, docname);
    return result;
  }

  /**
   * The file path where to store a ai file from a label - could be any version,
   * and it's supposed to be a local working copy. Optionally gets a suffix, used
   * to distinguish versions for example.
   * @param suffix Added before file ending
   * @param filetype to use a diferent filename configuration
   */
  async getWorkingCopyPath(moonDeskDocument: MoonDeskDocument, suffix: string,
                           filetype: 'InWork' | 'Export', fileExtension: string): Promise<string>
  {
      const docname = this.nameService.createDocumentName(filetype, moonDeskDocument, suffix, fileExtension.toLowerCase());
      const path = await this.getDocumentsPath(moonDeskDocument.id, docname);
      return path;
  }

  async getTempFilePath(filename: string): Promise<string>
  {
      const config = await this.illConfigService.getConfiguration();
      let path = await this.join(config.tempDirectory, 'temp');
      path = this.fileService.join(path, filename);
      return path;
  }

  async getTempFolderPath(): Promise<string>
  {
      const config = await this.illConfigService.getConfiguration();
      const path = await this.join(config.tempDirectory, 'temp');
      return path;
  }

  async getCacheFilePath(): Promise<string>
  {
      const config = await this.illConfigService.getConfiguration();
      const path = await this.join(config.tempDirectory, 'cache');
      return path;
  }

  async getFreeTaskAttachmentFilePath(task: MoonTask, name: string): Promise<string>
  {
    let path = await this.getTaskAttachmentFilePath(task, name);
    const parsed = this.fileService.parse(path);
    let counter = 0;
    while (await this.fileService.exists(path))
    {
      path = this.fileService.join(parsed.dir, `${parsed.name}_${++counter}${parsed.ext}`);
    }
    return path;
  }

  async getTaskAttachmentFilePath(task: MoonTask, name: string): Promise<string>
  {
    const home = this.fileService.getHomeDirectory();
    const directory = this.fileService.join(home, 'Downloads');

    // before, we stored the attachments in the local cache folder...
    // var config = await this.illConfigService.getConfiguration();
    // var directory = await this.join(config.tempDirectory, "tasks");
    // directory = await this.join(directory, task.company.id);
    // directory = await this.join(directory, `${task.taskNumber}`);

    const path = await this.fileService.join(directory, name);
    return path;
  }

  /**
   * To get the path of a folder and/or file in the local temp.
   */
  private async getDocumentsPath(subfolder: string, filename?: string): Promise<string>
  {
      let directory = await this.getDocumentsFolder(subfolder);
      if (filename)
      {
        directory = this.fileService.join(directory, filename);
      }
      return directory;
  }

  /**
   * To get the folder where all the documents (working copies) are cached.
   * Optionally gives you a subfolder for a documentId.
   */
  async getDocumentsFolder(subfolder?: string): Promise<string>
  {
    const config = await this.illConfigService.getConfiguration();
    let directory = await this.join(config.tempDirectory, 'documents');
    if (subfolder)
    {
        directory = await this.join(directory, subfolder);
    }
    return directory;
  }

  /**
   * join's and creates the sub if necessary
   */
  async join(dir: string, sub: string)
  {
      if (!dir)
      {
          throw Error('Directory name cant be empty');
      }
      if (!sub)
      {
          throw Error(`Subpath for '${dir}' can't be empty`);
      }
      if (!await this.fileService.exists(dir))
      {
          await this.fileService.mkdir(dir);
      }
      const path = this.fileService.join(dir, sub);
      if (!await this.fileService.exists(path))
      {
          await this.fileService.mkdir(path);
      }
      return path;
  }
}
