<div class="md-popup companyselector">

  <div class="nocompany" *ngIf="!companies || companies.length==0">
    You're not assigned to any company. Please contact your administrator or our support.
    <mat-icon class="notranslate" [ngClass]="{'busy':busy}" (click)="refresh()" matTooltip="Refresh Assignments">refresh</mat-icon>
  </div>

  <div class="current-company" *ngIf="companies?.length > 0">
    <div class="company-name" (click)="onCompanyNameClick()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <span class="text" *ngIf="company.valid">{{company.value.name}}</span>
      <span class="text" *ngIf="!company.valid">Please choose a company</span>
      <mat-icon *ngIf="hasMoreThanOneCompany" class="arrow-icon" class="notranslate">arrow_drop_down</mat-icon>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isSelectorOpen"
      (overlayOutsideClick)="closeOverlay()"
    >
      <div class="company-selector-overlay">
        <div class="search-box">
          <input matInput #workspaceInput type="text" [placeholder]="'Search workspace'" (input)="filterCompanies($event.target.value)">
        </div>
        <div class="companies-list">
          <span class="company-option" *ngFor="let c of filteredCompanies" (click)="onCompanyOptionClick(c)">{{c.name}}</span>
        </div>
      </div>
    </ng-template>


    <mat-icon class="notranslate refresh-icon" [ngClass]="{'busy':busy}" (click)="refresh()" matTooltip="Refresh Assignments">refresh</mat-icon>
    <ng-container *ngIf="showIcons">
      <mat-icon class="notranslate" (click)="editCompany()" matTooltip="Edit company">edit</mat-icon>
      <mat-icon class="notranslate" (click)="addCompany()" matTooltip="Add company" >add_circle</mat-icon>
      <mat-icon class="notranslate" (click)="deleteCompany()" matTooltip="Delete company">delete</mat-icon>
    </ng-container>
  </div>

  <mat-progress-spinner *ngIf="busy" class="spinner" diameter="48"  color="accent" mode="indeterminate"></mat-progress-spinner>

</div>
