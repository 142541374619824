import { Injectable } from '@angular/core';
import {
    ContentLibraryRuleConfiguration,
    DocumentService,
    DocumentVersion,
    LibRuleDocVersion,
    MoonDeskDocument,
    Rule,
    RuleIDs,
    RuleLibraryContentResult
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { FilesystemService } from '../filesystem.service';
import { IllustratorService, LinkedFile } from '../illustrator.service';

interface LinkedFileForRule extends LinkedFile
{
    checksum?: string;
}

@Injectable({
    providedIn: 'root'
  })
export class LibraryContentRule implements Rule
{
    id = RuleIDs.LIBRARY_CONTENT;
    name: string = 'Library Content Check';

    actionName: string = 'Show';

    constructor(private illService: IllustratorService,
                private fileSystem: FilesystemService,
                private docService: DocumentService)
    {}

    async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string, config: any): Promise<RuleResult>
    {
        const libRuleConfig = <ContentLibraryRuleConfiguration>config;
        const links: LinkedFile [] = await this.illService.getVisibleLinkedFiles(filePath, 0);
        const libResults = await this.getEvaluationResult(libRuleConfig, links);
        const imagesExists = libRuleConfig.logicalOperator === 'and' ?
            libResults.every(lr => lr.found) :
            libResults.some(lr => lr.found);

        let failCause: string = '';
        if (!imagesExists)
        {
            failCause = libRuleConfig.logicalOperator === 'and' ?
                'All the images should be in the document' :
                'At least one of the images should be in the document';
        }
        else
        {
            const sizeCheckOk = libRuleConfig.logicalOperator === 'and' ?
                libResults.every(r => r.sizeMatch) :
                libResults.some(r => r.sizeMatch);
            if (!sizeCheckOk)
            {
                failCause = 'The specified size for the images is not respected';
            }
        }

        const result: RuleResult =
        {
            rule: this,
            filePath: filePath,
            applied: true,
            error: !imagesExists || !!failCause,
            data: failCause,
            description: config.ruleDescription,
            config: config,
            libraryContentResults: libResults
        };
       return result;
    }

    private async getEvaluationResult(
        libRuleConfig: ContentLibraryRuleConfiguration,
        links: LinkedFileForRule[]): Promise<RuleLibraryContentResult[]>
    {
        for (const link of links)
        {
            const checksum = await this.fileSystem.fileChecksum(link.filePath);
            link.checksum = checksum;
        }

        const libResults: RuleLibraryContentResult[] = [];
        for (const ruleDocVersion of libRuleConfig.docVersions)
        {
            const versionId: string = ruleDocVersion.versionId;
            // TODO - save docVersionChecksum with the RuleConfig on rule creation so we don't have to search for it here
            const docVersionChecksum = await this.docService.getDocumentVersionChecksum(versionId);
            const existingLink = links.find(l => l.checksum === docVersionChecksum);

            const result: RuleLibraryContentResult =
            {
                versionId: versionId,
                found: !!existingLink,
                imageHeightMm: existingLink?.height,
                imageWidthMm: existingLink?.width,
                sizeMatch: existingLink ? this.checkSize(ruleDocVersion, existingLink) : false
            };
            libResults.push(result);
        }
        return libResults;
    }

    action = (result: RuleResult) =>
    {
        alert(`todo select texts in document ${result.filePath}`);
    };

    parseConfig (config: string): any
    {
        let configuration: ContentLibraryRuleConfiguration;
        if (config)
        {
            try
            {
                configuration = JSON.parse(config);
            }
            catch (error)
            {
                throw Error('Bad configuration json string');
            }
            if (!configuration.docVersions)
            {
                throw Error('Bad configuration - DocVersions is required');
            }
            if (!configuration.ruleDescription)
            {
                throw Error('Bad configuration - RuleDescription is required');
            }
            return configuration;
        }
        throw Error('Bad configuration - the configuration can\'t be null');
    }

    private checkSize(docV: LibRuleDocVersion, link: LinkedFile): boolean
    {
        let rightSize: boolean;
        link.width = Math.round(link.width);
        link.height = Math.round(link.height);

        switch (docV.logicalOperatorSize)
        {
            case '<':
            {
                rightSize =
                    (docV.width === 0 || link.width < docV.width) &&
                    (docV.height === 0 || link.height < docV.height);
                break;
            }
            case '>':
            {

                rightSize =
                    (docV.width === 0 || link.width > docV.width) &&
                    (docV.height === 0 || link.height > docV.height);
                break;
            }
            case '=':
            {
                rightSize =
                    (docV.width === 0 || link.width === docV.width) &&
                    (docV.height === 0 || link.height === docV.height);
                break;
            }
            default:
                console.log('Invalid logicalOperatorSize: ' + docV.logicalOperatorSize);
                return true;
        }
        return rightSize;
    }
}
