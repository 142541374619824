<div class="md-popup save-document-popup">
    <div class="md-popup-header header">
        <span class="title">{{ isNewDoc ? 'IMPORT' : 'Save new version'}}</span>
    </div>
    <div class="md-popup-content save-body">
        <span class="subtitle-import" *ngIf="isNewDoc">Import document</span>
        <div class="save-option">
            <span class="title">DOCUMENT STATE</span>
            <div class="content section">
                <mat-radio-group [formControl]="modeController">
                    <mat-radio-button id="working-copy-radio-button" value="minorVersion" *ngIf="!isNewDoc">
                        <span class="radio-text">
                            <span>{{'Working Copy (Draft)'}}</span>
                            <mat-icon
                                class="notranslate" 
                                [matTooltip]="'A working copy is meant for saving design changes, and is only visible for designers in Illustrator. Not available for Reviews and Shares'">
                                help_outline
                            </mat-icon>
                        </span>
                    </mat-radio-button>
                    <mat-radio-button id="save-for-review-button" value="majorVersion">
                        <span class="radio-text">
                            <span>Draft</span>
                            <!-- <mat-icon class="notranslate">help_outline</mat-icon> -->
                        </span>
                    </mat-radio-button>
                    <mat-radio-button id="approve-when-save-button" [disabled]="!isApproveAllowed()" value="approve">
                        <span class="radio-text">
                            <span>Approve</span>
                            <mat-icon class="notranslate"
                                *ngIf="!canApprove || !isNewDoc"
                                [matTooltip]="canApprove ? 'Only If you are the owner of all the related tasks.' : 'You are not allowed to upload approved documents'">
                                help_outline
                            </mat-icon>
                        </span>
                    </mat-radio-button>
                </mat-radio-group>

                <!-- <span class="info" *ngIf="info && statusOfDocAsSubTask() !== 'Done'">{{info}}</span> -->
                <span class="warning-subtask-done" *ngIf="statusOfDocAsSubTask() === 'Done'">
                    The task {{currentTask.taskNumber}} "{{currentTask.name}}" has been <strong>approved for this document</strong>. 
                    Please exit the task or ask the owner for a change request.
                </span> 
            </div>
        </div>
        <div class="save-option add-to-task-option" *ngIf="currentTask && !isDocInTask()">
            <span class="title">ADD TO TASK <mat-icon class="help-add-to-task-icon" [matTooltip]="'MoonDesk allows through this option to add a document to the task you have open. If you do not have a task open in the extension this option will not be available.'">help</mat-icon></span>
            <div class="content section">
                <div class="add-to-task" *ngIf="currentTask && !isDocInTask() && mode === 'majorVersion'">
                    <mat-checkbox class="add-to-current-task" id="add-to-current-task-checkbox" (change)="updateInfo()" [(ngModel)]="addToTask">
                        <span>Add to task {{currentTask.taskNumber}} "{{currentTask.name}}"</span>
                    </mat-checkbox>

                    <mat-checkbox class="send-to-review-checkbox" [disabled]="!addToTask" id="send-to-review-in-task" (change)="updateInfo()" [(ngModel)]="sendToReview">
                        <span>Send to review</span>
                    </mat-checkbox>
                </div>

                <div class="add-to-task" *ngIf="currentTask && !isDocInTask() && mode === 'approve'">
                    <mat-checkbox id="add-to-currentTask-approved-checkbox" (change)="updateInfo()" [(ngModel)]="addToTask">
                        <span>Add to task {{currentTask.taskNumber}} "{{currentTask.name}}"</span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="save-option" id="save-options" *ngIf="mode !== 'minorVersion'">
            <span class="title">
                Select other formats to include in the save
                <mat-icon class="notranslate" [libTooltip]="formatsInfo">help_outline</mat-icon>
                <ng-template #formatsInfo>
                    <div>MoonDesk always saves the .ai file, together with a .png and a light .pdf for preview purposes. Here you can add more formats that can be helpful for sharing and reviewing with other users or reviewers in the web.</div>
                </ng-template>
            </span>
            <div class="content section">
                <mat-checkbox id="ai-checkbox" [(ngModel)]="exportOptions.outlineFile" [disabled]="missingFontsOrLinks">
                    <div class="export-checkbox">
                        <span>Ai Outline</span>
                        <span class="icon" [libTooltip]="missingFontsOrLinksTooltip" *ngIf="missingFontsOrLinks">
                            <mat-icon class="notranslate">warning</mat-icon>
                        </span>
                    </div>
                </mat-checkbox>
                <mat-checkbox id="pdf-checkbox" [(ngModel)]="exportOptions.pdfFile" [disabled]="missingFontsOrLinks">
                    <div class="export-checkbox">
                        <span>PDF</span>
                        <span class="icon" [libTooltip]="missingFontsOrLinksTooltip" *ngIf="missingFontsOrLinks">
                            <mat-icon class="notranslate">warning</mat-icon>
                        </span>
                    </div>
                </mat-checkbox>
                <!-- <mat-checkbox id="jpg-checkbox" [(ngModel)]="exportOptions.jpgFile">JPG</mat-checkbox> -->
                
                <ng-template #missingFontsOrLinksTooltip>
                    <div>PDF and Ai (Outline) cannot be shared if there are missing fonts or links</div>
                </ng-template>
            </div>
        </div>
        <div class="save-option" *ngIf="mode !== 'minorVersion'">
            <div class="content selection">
                <mat-checkbox id="create-copies-local-checkbox" [(ngModel)]="exportOptions.downloadFiles">Create copies in local download folder</mat-checkbox>
                
                <div class="local-copies-formats" *ngIf="exportOptions.downloadFiles">
                    <mat-checkbox [(ngModel)]="exportOptions.downloadAiFile">AI</mat-checkbox>
                    <mat-checkbox [(ngModel)]="exportOptions.downloadOutlineFile">AI Outline</mat-checkbox>
                    <mat-checkbox [(ngModel)]="exportOptions.downloadPdfFile">PDF</mat-checkbox>
                    <mat-checkbox [(ngModel)]="exportOptions.downloadLightPdfFile">Light PDF</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="actions">
            <button class="md-button md-button-outstanding" id="save-button" [disabled]="currentTask && statusOfDocAsSubTask() === 'Done'" (click)="save()">{{ isNewDoc ? 'Continue' : 'Save'}}</button>
            <button class="md-button md-button-highlighted" id="cancel-button" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>
