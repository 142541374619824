<div class="md-popup fonts-picker">
    <div class="md-popup-header">
        <span class="title" translate>
            lid.ext.pages._shared.fonts-picker.font-selector
        </span>
    </div>
    <div class="md-popup-content fonts-picker-body">
        <!-- <div class="selected-font" *ngIf="selectedFont">
            <span>Fuente seleccionada: "{{selectedFont.name}}"</span>
        </div> -->
        <mat-form-field class="fonts-search">
            <mat-label translate>lid.ext.pages._shared.fonts-picker.font</mat-label>
            <input type="text"
                    matInput
                    [formControl]="fontPickerControl"
                    [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name" (click)="ok(option)" matTooltip="{{option.name}}">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="footer">
        <div class="actions">
            <!-- <button class="md-button md-button-outstanding" [disabled]="!selectedFont" (click)="ok()">Ok</button> -->
            <button class="md-button md-button-highlighted" (click)="cancel()">
                <span translate>lid.ext.pages._shared.fonts-picker.cancel</span>
            </button>
        </div>
    </div>
</div>