<div class="md-popup rules-popup">
    <div class="md-popup-header rules-header">
      <!-- <mat-icon class="notranslate" matTooltip="Close" [hidden]="!confirm" id="close-rule-dialog-page" (click)="onNoClick()">close</mat-icon> -->
      <div class="title">
        <div>Rules results</div>
        <div (keyup.enter)="enterPressed()" tabindex="1"></div>
      </div>
    </div>
    <div class="spinner" *ngIf="loadingConfig">
      <mat-progress-spinner diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div class="wrapper" *ngIf="!loadingConfig">
      <div class="scrollcontent">
        <div class="md-popup-content rules-content" *ngIf="taskRules && taskRules.length > 0">
          <span class="title">TASK RULES</span>
          <div class="rules-list">
            <div class="card" *ngFor="let result of taskRules">
              <div class="header" (click)="ruleClicked(result)">
                <span class="arrow-icon">
                  <mat-icon class="notranslate" [ngClass]="{'rotate-90': result.toggled, 'rotate-back': !result.toggled}">play_arrow</mat-icon>
                </span>
                <span class="result-icon">
                  <mat-icon class="notranslate" [ngClass]="result.status">{{getIcon(result.status)}}</mat-icon>
                </span>
                <span class="name" [matTooltip]="result.name" [innerHTML]="result.richTextName ? result.richTextName : result.name"></span>
              </div>
              <div class="body" *ngIf="result.toggled" [@slideInOut]>
                <ng-container *ngIf="result.ruleContents && result.ruleContents.length > 0">
                  <span *ngFor="let content of result.ruleContents" class="content">
                    <span class="text" [libTooltip]="contentTooltip" [innerHTML]="content.uiText"></span>
                    <div class="actions" *ngIf="!result.busy">
                      <span class="action" matTooltip="Paste content" *ngIf="!result.busy">
                        <mat-icon class="notranslate" [inline]="true" (click)="pasteContent(result, content.contentData)">content_copy</mat-icon>
                      </span>
                      <span class="action" matTooltip="Search content">
                        <mat-icon class="notranslate" [inline]="true" (click)="findContents(result, content)">search</mat-icon>
                      </span>
                    </div>
                    <span class="actions" *ngIf="result.busy">
                      <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                    </span>
                    <ng-template #contentTooltip>
                      <div [innerHTML]="content.uiText"></div> 
                    </ng-template>
                  </span>
                </ng-container>
                <span class="description" [libTooltip]="descriptionTooltip" [innerHTML]="result.description"></span>
                <div class="fail-cause" *ngIf="result.failCause" [libTooltip]="errorTooltip">
                  <span class="dot-icon">
                    <mat-icon class="notranslate" [ngClass]="result.status">fiber_manual_record</mat-icon>
                  </span>
                  <span class="fail-description">{{result.failCause}}</span>
                </div>
                <ng-template #descriptionTooltip>
                  <div [innerHTML]="result.description"></div> 
                </ng-template>
                <ng-template #errorTooltip>
                  <div [innerText]="result.failCause"></div> 
                </ng-template>
              </div>
            </div>
          </div>
          <span class="spinner" *ngIf="loadingRules">
            <mat-progress-spinner diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
          </span>
        </div>

        <div class="md-popup-content rules-content">
          <span class="title">WORKSPACE RULES</span>
          <div class="rules-list" *ngIf="workspaceRules && workspaceRules.length > 0">
            <div class="card" *ngFor="let result of workspaceRules">
              <div class="header" (click)="ruleClicked(result)">
                <span class="arrow-icon">
                  <mat-icon class="notranslate" [ngClass]="{'rotate-90': result.toggled, 'rotate-back': !result.toggled}">play_arrow</mat-icon>
                </span>
                <span class="result-icon" >
                  <mat-icon class="notranslate" [ngClass]="result.status">{{getIcon(result.status)}}</mat-icon>
                </span>
                <span class="name" [matTooltip]="result.name" [innerHTML]="result.richTextName ? result.richTextName : result.name"></span>
              </div>
              <div class="body" *ngIf="result.toggled" [@slideInOut]>
                <ng-container *ngIf="result.ruleContents && result.ruleContents.length > 0">
                  <span *ngFor="let content of result.ruleContents" class="content">

                    <!-- <span class="text" [libTooltip]="contentTooltip" [innerHTML]="content.uiText"></span> -->
                    <span class="text" *ngIf="content.found" [libTooltip]="contentTooltip" [innerHTML]="'<b>(✓)</b> ' + content.uiText"></span>
                    <span class="text" *ngIf="!content.found" [libTooltip]="contentTooltip" [innerHTML]="'<b>(X)</b> ' + content.uiText"></span>

                    <div class="actions" *ngIf="!result.busy">
                      <span class="action" matTooltip="Paste content">
                        <mat-icon class="notranslate" [inline]="true" (click)="pasteContent(result, content.contentData)">content_copy</mat-icon>
                      </span>
                      <span class="action" matTooltip="Search content">
                        <mat-icon class="notranslate" [inline]="true" (click)="findContents(result, content)">search</mat-icon>
                      </span>
                    </div>
                    <div class="actions" *ngIf="result.busy">
                      <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-template #contentTooltip>
                      <div [innerHTML]="content.uiText"></div> 
                    </ng-template>
                  </span>
                </ng-container>
                <span class="description" [libTooltip]="descriptionTooltip" [innerHTML]="result.description"></span>
                <div class="fail-cause" *ngIf="result.failCause" [libTooltip]="errorTooltip">
                  <span class="dot-icon">
                    <mat-icon class="notranslate" [ngClass]="result.status">fiber_manual_record</mat-icon>
                  </span>
                  <span class="fail-description">{{result.failCause}}</span>
                </div>
                <ng-template #descriptionTooltip>
                  <div [innerHTML]="result.description"></div> 
                </ng-template>
                <ng-template #errorTooltip>
                  <div [innerText]="result.failCause"></div> 
                </ng-template>
              </div>
            </div>
          </div>
          <span class="spinner" *ngIf="loadingRules">
            <mat-progress-spinner diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
          </span>
        </div>

        <div class="md-popup-content rules-content" *ngIf="!loadingRules && ((!workspaceRules || workspaceRules.length === 0) && (!taskRules || taskRules.length === 0))">
          <span class="title">
            No rule applies
          </span>
        </div>
      

      </div>
    </div>
    <div class="footer">
      <div class="how-work" *ngIf="hasContentError">
        <mat-icon class="info-icon" 
          [libTooltip]="sizeTooltip">info</mat-icon>
        <span class="size-work">How is the minimum/maximum size being checked?</span>
        <ng-template #sizeTooltip>
          <span [innerHTML]="'The <i><b>minimun size</b></i> of a text is checked based on the height of\
            the <i><b>smallest letter</b></i> inside the text box which contains the given text.'">
          </span>
          <br>
          <span [innerHTML]="'The <i><b>maximum size</b></i> of a text is checked based on the height of\
            the <i><b>biggest letter</b></i> inside the text box which contains the given text.'">
          </span>
        </ng-template>
      </div>

      <!-- NORMAL SAVE -->
      <ng-container *ngIf="!documentChanged">
        <div class="question" *ngIf="confirm">Do you want to continue?</div>
    
        <div class="md-popup-actions rules-actions">
          <span [matTooltip]="disabledTooltip">
            <button class="md-button" tabindex="-1" *ngIf="confirm" [disabled]="!canSave()" [ngClass]="{'md-button-outstanding': !hasError}" (click)="dialogRef.close(ruleResults)" id="yes-rule-dialog">Yes</button>
          </span>
          <button class="md-button" tabindex="-1" *ngIf="confirm" [ngClass]="{'md-button-outstanding': hasError}" (click)="onNoClick()" id="no-rule-dialog">No</button>
          <button class="md-button" tabindex="-1" *ngIf="!confirm" (click)="onNoClick()" id="close-button-rule-dialog">Close</button>
        </div>
      </ng-container>

      <!-- DOCUMENT CHANGED (RUN RULES AGAIN OR CANCEL) -->
      <ng-container *ngIf="documentChanged">
        <div class="question">Warning! It is possible that the document has changed. Run the rules again?</div>
    
        <div class="md-popup-actions rules-actions">
          <span>
            <button class="md-button md-button-outstanding" tabindex="-1" (click)="rerunRules()">Re-run</button>
          </span>
          <button class="md-button" tabindex="-1" (click)="onNoClick()">Cancel</button>
        </div>
      </ng-container>
    

    </div>
  
  </div>


