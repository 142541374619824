<div class="md-popup" (keyup.enter)="save()">

  <div class="md-popup-header">
    <!-- <mat-icon matTooltip="Close" id="close-dialog-icon" (click)="close()">close</mat-icon> -->
    <div class="title">{{data.title}}</div>
  </div>

  <div class="md-popup-content" [formGroup]="classValueController">
    <mat-form-field>
      <input matInput type="text" placeholder="Name" formControlName="name"  id="input-name" cdkFocusInitial>
      <mat-error *ngIf="name.hasError('required')">
        This field is requiered
      </mat-error>
      <mat-error *ngIf="name.hasError('duplicateValue')">
        Duplicate value
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Code" id="input-code" formControlName="code">
      <mat-error *ngIf="code.hasError('required')">
        This field is requiered
      </mat-error>
      <mat-error *ngIf="code.hasError('duplicateCode')">
        Duplicate code
      </mat-error>
    </mat-form-field>
  </div>

  <div class="md-popup-actions">
    <button class="md-button md-button-outstanding" id="save-button" (click)="save()">Ok</button>
    <button class="md-button" (click)="close()" id="cancel-button">Cancel</button>
  </div>

</div>
