var fs = cep_node.require('fs');
var path = cep_node.require('path');
var os = cep_node.require('os');
var child = cep_node.require('child_process');
var crypto = cep_node.require('crypto');
var stream = cep_node.require('stream');
var util = cep_node.require('util');

var Node = (function () {
    function NodeFilesystem() {
    }

    NodeFilesystem.prototype.execute = function(command) {
        return child.exec(command);
    };

    NodeFilesystem.prototype.executeSync = function(command) {
        return child.execSync(command);
    };
    
    NodeFilesystem.prototype.exists = function(file) {
        return fs.existsSync(file);
    };

    NodeFilesystem.prototype.copyFile = function(src, dest) {
        var buffer = fs.readFileSync(src);
        fs.writeFileSync(dest, buffer);
    };

    NodeFilesystem.prototype.rename = function(src, dest) {
        try 
        {
            fs.renameSync(src, dest)
            console.log("Successfully renamed the file!")
        }
        catch(err)
        {
            throw Error(err)
        }
    };

    NodeFilesystem.prototype.parse = function(src) {
        return path.parse(src);
    };
    
    NodeFilesystem.prototype.normalize = function(p) {
        return path.normalize(p);
    };

    NodeFilesystem.prototype.join = function(dir, file) {
        return path.join(dir, file);
    };

    NodeFilesystem.prototype.createReadStream = function (filename) {
        return fs.createReadStream(filename);
    };

    NodeFilesystem.prototype.readFile = function(filename, options, callback) {
        fs.readFile(filename, options, callback);
    };

    NodeFilesystem.prototype.writeFile = function(filename, data, options, callback) {
        fs.writeFile(filename, data, options, callback);
    };

    NodeFilesystem.prototype.getHomeDirectory = function() {
        return os.homedir();
    };

    NodeFilesystem.prototype.getPlatform = function() {
        return os.platform();
    };

    NodeFilesystem.prototype.getHostname = function() {
        return os.hostname();
    };

    NodeFilesystem.prototype.unlink = function(path, callback) {
        return fs.unlink(path, callback);
    };

    NodeFilesystem.prototype.mkdir = function(path, callback) {
        return fs.mkdir(path, callback);
    };

    NodeFilesystem.prototype.rmdir = function(path, callback) {
        return fs.rmdir(path, callback);
    };

    NodeFilesystem.prototype.lstatSync = function(path) {
        return fs.lstatSync(path);
    };

    NodeFilesystem.prototype.checksum = function(str) {
        return crypto
          .createHash('sha1')
          .update(str)
          .digest('base64')
    };

    NodeFilesystem.prototype.fileChecksum = async function(path) {
        const inputStream = fs.createReadStream(path);
        const hashStream = crypto.createHash("sha1");
        hashStream.setEncoding("base64");
        const pipelineAsync = util.promisify(stream.pipeline);
        await pipelineAsync(inputStream, hashStream);
        let checksum = hashStream.digest('base64');
        console.log("RAW CHECKSUM: " + checksum);
        return checksum;
    };
      
    NodeFilesystem.prototype.getFileSize = function(str)
    {
        let stat = fs.statSync(str);
        return stat.size;
    }

    NodeFilesystem.prototype.readdirSync = function(path)
    {
        return fs.readdirSync(path);
    }
    

    NodeFilesystem.prototype.isDirectory = function(path)
    {
        return fs.lstatSync(path).isDirectory();
    }

    return NodeFilesystem;
}());

exports.Node = Node;