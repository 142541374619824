import { Injectable, EventEmitter, PlatformRef } from '@angular/core';
import { Node, ParsedPath } from '../_node/Node';
import * as _ from 'underscore';

// LEAVE THIS! Helpful for searching in the intellisense help... to implement stuff under ../_node
// import * as fs from "fs";
// import * as path from "path";
// import * as os from "os";
// import * as crypto from "crypto";

export interface PlatformFont
{
  name: string;
  filepath: string;
}

@Injectable()
export class FilesystemService
{

  node: Node;

  constructor()
  {
    this.node = new Node();
    console.log(`Platform: ${this.node.getPlatform()}`);
  }

  getFileSize(str: string): number
  {
    return this.node.getFileSize(str);
  }

  checksum(str: string): string
  {
    return this.node.checksum(str);
  }

  async fileChecksum(path: string)
  {
    return await this.node.fileChecksum(path);
  }

  open(path: string): any
  {
    let openCommand;
    if (this.getPlatform() === 'mac')
    {
      openCommand = 'open -R ' + path;
    }
    else
    {
      openCommand = 'explorer.exe /select, ' + path;
    }
    console.log('Opening file' + openCommand);

    return this.node.execute(openCommand);
  }

  execute(path: string): any
  {
    const executeCommand = path;
    console.log('Executing ' + executeCommand);
    return this.node.execute(executeCommand);
  }

  zip(sourcePath: string, destinationPath: string): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      let zipCommand: string;

      if (this.getPlatform() === 'mac')
      {
        const parseDestination = this.parse(destinationPath);
        const zipName = parseDestination.name + parseDestination.ext;
        const zipPath = this.join('..', zipName);
        zipCommand = 'cd ' + sourcePath + ' &&' + ' zip -r ' + zipPath + ' .';
      }
      else
      {
        sourcePath = this.join(sourcePath, '*');
        zipCommand = '%SYSTEMROOT%\\System32\\WindowsPowerShell\\v1.0\\powershell.exe -Command  Compress-Archive -Update -Path \'' +
                     sourcePath + '\' -DestinationPath \'' + destinationPath + '\'';
      }
      this.node.executeSync(zipCommand);
      resolve();
    });
  }

  unzip(zipPath: string, destinationPath: string): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      let unzipCommand: string;

      if (this.getPlatform() === 'mac')
      {
        unzipCommand = 'unzip -o ' + zipPath + ' -d ' + destinationPath;
      }
      else
      {
        // Untested
        unzipCommand = '%SYSTEMROOT%\\System32\\WindowsPowerShell\\v1.0\\powershell.exe -Command Expand-Archive -LiteralPath ' +
                        zipPath + ' -DestinationPath ' + destinationPath;
      }
      this.node.executeSync(unzipCommand);
      resolve();
    });
  }

  getHomeDirectory(): string
  {
    return this.node.getHomeDirectory();
  }

  getDownloadsDirectory(): string
  {
    const home = this.getHomeDirectory();
    const path = this.join(home, 'Downloads');
    return path;
  }

  getLogfileDirectory(): string
  {
    let dir = this.getHomeDirectory();
    const platform = this.node.getPlatform();
    console.log(`Platform: ${platform}`);
    if (platform === 'darwin')
    {
        // /Users/<USERNAME>/Library/Logs/CSXS
        dir = this.join(dir, 'Library');
        dir = this.join(dir, 'Logs');
        dir = this.join(dir, 'CSXS');
    }
    else
    {
      // C:\Users\<USERNAME>\AppData\Local\Temp
        dir = this.join(dir, 'AppData');
        dir = this.join(dir, 'Local');
        dir = this.join(dir, 'Temp');
    }
    return dir;
  }

  getPlatformFonts(): PlatformFont[]
  {
    const platform = this.node.getPlatform();
    console.log(`Platform: ${platform}`);
    let usersFolder = this.getHomeDirectory();
    let result: PlatformFont[] = [];
    if (platform === 'darwin')
    {
      // Librery/Fonts
      // USER FOLDER/Library/Fonts
      usersFolder = this.join(usersFolder, 'Library');
      usersFolder = this.join(usersFolder, 'Fonts');
      console.log(`User fonts: ${usersFolder}`);

      const globalFolder = '/Library/Fonts';
      console.log(`Global fonts: ${globalFolder}`);

      const systemFolder = '/System/Library/Fonts';

      if (!this.exists(usersFolder) && !this.exists(globalFolder) && !this.exists(systemFolder))
      {
        throw new Error('Fonts folder not found');
      }

      const userFontFiles = this.readdirSync(usersFolder, true);
      const userFonts = _.map(userFontFiles, f => this.mapFontFileToObject(f));
      userFonts.forEach(f => result.push(f));

      const globalFontFiles = this.readdirSync(globalFolder, true);
      const globalFonts = _.map(globalFontFiles, f => this.mapFontFileToObject(f));
      globalFonts.forEach(f => result.push(f));

      const systemFontFiles = this.readdirSync(systemFolder, true);
      const systemFonts = _.map(systemFontFiles, f => this.mapFontFileToObject(f));
      systemFonts.forEach(f => result.push(f));
    }
    else
    {
      const globalFolder = `C:\\Windows\\Fonts`;
      console.log('fonts folder: ' + globalFolder);
      if (!this.exists(globalFolder))
      {
        throw new Error('Fonts folder not found');
      }
      const globalFonts = this.readdirSync(globalFolder, true);
      result = _.map(globalFonts, f => this.mapFontFileToObject(f));

      // C:\Users\<USERNAME>\AppData\Local\Microsoft\Windows\Fonts
      let dir = this.getHomeDirectory();
      dir = this.join(dir, 'AppData');
      dir = this.join(dir, 'Local');
      dir = this.join(dir, 'Microsoft');
      dir = this.join(dir, 'Windows');
      dir = this.join(dir, 'Fonts');
      const userFontsFolder = dir;

      console.log('User fonts folder: ' + userFontsFolder);
      if (this.exists(userFontsFolder))
      {
        const userFonts = this.readdirSync(userFontsFolder, true);
        if (userFonts && userFonts.length > 0)
        {
          result = result.concat(_.map(userFonts, f => this.mapFontFileToObject(f)));
        }
      }
    }
    console.log(`Found ${result.length} fonts`);
    return result;
  }

  private mapFontFileToObject(filepath: string): PlatformFont
  {
    const parsed = this.parse(filepath);
    const plFont: PlatformFont = {
      name: parsed.name + parsed.ext,
      filepath: filepath
    };
    return plFont;
  }

  exists(file: string)
  {
    return this.node.exists(file);
  }


  unlink(path: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      this.node.unlink(path, err =>
      {
        if (err && err.errno)
        {
          console.log(`Error deleting ${path}: ${err.errno}`);
          resolve(false);
        }
        else
        {
          resolve(true);
        }
      });
    });
  }


  mkdir(path: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      this.node.mkdir(path, err =>
      {
        if (err && err.errno)
        {
          console.log(`Error creating directory ${path}: ${err.errno}`);
          resolve(false);
        }
        else
        {
          resolve(true);
        }
      });
    });
  }

  async deleteFolderRecursive(path: string): Promise<boolean>
  {
    if (this.exists(path))
    {
      const files = this.readdirSync(path);
      for (const file of files)
      {
        const curPath = this.join(path, file);
        if (this.node.lstatSync(curPath).isDirectory())
        {
          await this.deleteFolderRecursive(curPath);
          // return;
        }
        else
        {
          await this.unlink(curPath);
        }
      }
      return new Promise<boolean>((resolve, reject) =>
      {
        this.node.rmdir(path, err =>
        {
          if (err && err.errno)
          {
            console.log(`Error deleting directory ${path}: ${err.errno}`);
            resolve(false);
          }
          else
          {
            resolve(true);
          }
        });
      });
    }
  }

  directoryOf(file: string): string
  {
    const x = this.node.parse(file);
    return x.dir;
  }

  filenameOf(file: string): string
  {
    const x = this.node.parse(file);
    return x.base;
  }

  readdirSync(path: string, getFullPaths: boolean = false, type: 'all'|'files'|'directories'= 'all'): string[]
  {
    let files = this.node.readdirSync(path);
    if (type !== 'all')
    {
      files = _.filter(files, f => this.node.isDirectory(this.join(path, f)) ? type === 'directories' : type === 'files');
    }
    if (getFullPaths)
    {
      files = _.map(files, f => this.join(path, f));
    }
    return files;
  }

  parse(path: string): ParsedPath
  {
    return this.node.parse(path);
  }

  join(dir: string, filename: string): string
  {
    return this.node.join(dir, filename);
  }

  readFileAsync(filename: string, options?: { encoding?: string, flag?: string; }): Promise<Buffer | string>
  {
    const size = this.getFileSize(filename);
    if (size > 500000000)
    {
      // NodeJs cant handle string larger than 512MB
      // If we need file larger than that we should use streams: fs.createReadStream(filepath);
      console.log(size);
      throw Error('File is larger than the maximum allowed (Max filesize: 512MB');
    }

    return new Promise<Buffer | string>((resolve, reject) =>
    {
      this.node.readFile(filename, options, (err, data) =>
      {
        if (err)
        {
          reject(err);
        }
        else if (!data)
        {
          const nodeMajorVersion = +process.version?.substr(1).split('.')[0];
          const mbSize = this.node.getFileSize(filename) * 0.000001;

          if (mbSize === 0)
          {
            // Is the case of .t1 or postscript fonts
            reject(new Error('0 KB files are not allowed'));
          }

          if (nodeMajorVersion <= 8 && mbSize > 200)
          {
            reject(new Error('This version of Illustrator does not support files larger than 200 MB'));
          }
        }
        else
        {
          resolve(<string>data);
        }
      });
    });
  }

  readRawFileAsync(filename: string, options?: { encoding?: string, flag?: string; }): Promise<Buffer>
  {
    const readStream = this.node.createReadStream(filename);
    const data = [];
    return new Promise<Buffer>((resolve, reject) =>
    {
      readStream.on('error', (err) => reject(err));
      readStream.on('data', (chunk) => data.push(chunk));
      readStream.on('end', () => resolve(Buffer.concat(data)));
    });
  }

  // options
  //    encoding Default: 'utf8'
  //    mode Default: 0o666
  //    flag Default: 'w'
  //                  'a' - Open file for appending. The file is created if it does not exist.
  //                  'ax' - Like 'a' but fails if the path exists.
  //                  'a+' - Open file for reading and appending. The file is created if it does not exist.
  //                  'ax+' - Like 'a+' but fails if the path exists.
  //                  'as' - Open file for appending in synchronous mode. The file is created if it does not exist.
  //                  'as+' - Open file for reading and appending in synchronous mode. The file is created if it does not exist.
  //                  'r' - Open file for reading. An exception occurs if the file does not exist.
  //                  'r+' - Open file for reading and writing. An exception occurs if the file does not exist.
  //                  'w' - Open file for writing. The file is created (if it does not exist) or truncated (if it exists).
  //                  'wx' - Like 'w' but fails if the path exists.
  //                  'w+' - Open file for reading and writing. The file is created (if it does not exist) or truncated (if it exists).
  //                  'wx+' - Like 'w+' but fails if the path exists.
  writeFileAsync(filename: string, data: string, options: { encoding?: string; mode?: string; flag?: string; }): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.node.writeFile(filename, data, options, err =>
        {
        if (err && err.errno)
        {
          reject(err);
        }
        else
        {
          resolve();
        }
      });
    });
  }

  normalize(file: string): string
  {
    const path = this.node.normalize(file);
    return path;
  }

  copyFile(sourcePath: string, destinationPath: string): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      this.node.copyFile(sourcePath, destinationPath);
      resolve();
    });
  }

  moveFolder(sourcePath: string, destinationPath: string): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      let moveCmd: string;
      if (this.getPlatform() === 'mac')
      {
        moveCmd = `mv "${sourcePath}" "${destinationPath}"`;
      }
      else
      {
        moveCmd = `move "${sourcePath}" "${destinationPath}"`;
      }
      this.node.executeSync(moveCmd);
      resolve();
    });
  }

  renameFile(src: string, dest: string)
  {
    return this.node.rename(src, dest);
  }

  getPlatform(): 'mac' | 'windows'
  {
    const platform = this.node.getPlatform();
    if (platform === 'darwin')
    {
      return 'mac';
    }
    return 'windows';
  }

}
