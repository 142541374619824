<div class="md-popup">
    <div>
        <!-- <mat-icon matTooltip="Close" (click)="close()">close</mat-icon> -->
        <div class="title">Warning!</div>
    </div>

    <div class="md-popup-content warning">
        <div class="message">{{message}}</div>
        <div class="list" *ngIf="list">
            <div *ngFor="let element of list">{{element}}</div>
        </div>
    </div>

    <div class="md-popup-actions">
        <button class="md-button" (click)="close()">Cancel</button>
        <button class="md-button md-button-outstanding" mat-dialog-close="ok">Continue</button>
    </div>
</div>