export class DocumentAccessProvider
{
  constructor()
  {
    console.log('DummyDocumentAccessProvider');
  }

  isDummy = true;

  async find(filePath: string, findText: string, wholeWord: boolean): Promise<number>
  {
    throw new Error('not implemented');
  }

  async getLocalLinks(filePath: string): Promise<string[]>
  {
    throw new Error('not implemented');
  }

  async checkTextOverflow(filePath: string, artboardIndex: number): Promise<number>
  {
    throw new Error('not implemented');
  }

  async getTextSize(filePath: string, text: string, wholeWord: boolean): Promise<number>
  {
    throw new Error('not implemented');
  }

}
