<div class="pushmessages" [ngClass]="notificationPopupClass">
  <div class="header">
    <button class="md-textbutton md-textbutton-highlighted markread" e2e="e2e-read-all-button" (click)="readAll()">
      <mat-icon class="notranslate">bookmark</mat-icon>
      <div>All read</div>
    </button>
    <button class="md-textbutton md-textbutton-highlighted markread" (click)="archiveAll()">
      <mat-icon class="notranslate">folder</mat-icon>
      <div>Archive all</div>
    </button>
    <button class="md-textbutton refresh" (click)="refresh()">
      <mat-icon class="notranslate">refresh</mat-icon>
      <div>Refresh</div>
    </button>
  </div>
  <mat-progress-spinner *ngIf="updating" diameter="13" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
  <div class="messages">
    <div *ngFor="let msg of messages" class="pushmessage" [ngClass]="{'unread':!msg.read}">
      <div class="messagecontent">

        <!-- TaskNotification with SubTaskMessage -->
        <div *ngIf="msg.taskNotification && msg.taskNotification.subTaskMessage" e2e="task-message-with-subtask" class="taskmessage" (click)="taskMessageClicked(msg)">
          <div class="time">{{getSenderName(msg.taskNotification.subTaskMessage)}} {{msg.timestampUtc | date:'short'}}</div>
          <div class="message">
            <div *ngIf="msg.taskNotification.subTaskMessage.subTask && msg.taskNotification.subTaskMessage.subTask.document">
              {{getSubTaskName(msg.taskNotification.subTaskMessage.subTask)}}
            </div>
          </div>
          <div class="date">
            {{msg.taskNotification.subTaskMessage.text}}
          </div>
          <div e2e="date-with-subtask" class="date">
            Task {{msg.taskNotification.task.shareNumber && msg.taskNotification.task.shareNumber > 0 ? msg.taskNotification.task.shareNumber : msg.taskNotification.task.taskNumber}} - {{msg.taskNotification.task.name}}
          </div>
        </div>
          
        <!-- TaskNotification without SubTaskMessage -->
        <div *ngIf="msg.taskNotification && !msg.taskNotification.subTaskMessage" e2e="task-message-without-subtask" class="taskmessage" (click)="taskMessageClicked(msg)">
          <div class="time">{{msg.timestampUtc | date:'short'}}</div>
          <div id="message" class="message">
            {{msg.taskNotification.action}}
          </div>
          <div e2e="date-without-subtask" class="date">
            Task {{msg.taskNotification.task.shareNumber && msg.taskNotification.task.shareNumber > 0 ? msg.taskNotification.task.shareNumber : msg.taskNotification.task.taskNumber}} - {{msg.taskNotification.task.name}}
          </div>
        </div>

        <!-- SupportMessage -->
        <div *ngIf="msg.supportMessage" class="supportmessage" (click)="supportMessageClicked(msg)">
          <div class="time">{{msg.timestampUtc | date:'short'}}</div>
          <div class="message">Message from {{msg.supportMessage.supportName}}</div>
          <div e2e="e2e-date" class="date">{{msg.supportMessage.message}}</div>
        </div>
      </div>

      <div class="contextmenu">
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item *ngIf="msg.read" (click)="markAsUnRead(msg)">Unread</button>
            <button mat-menu-item *ngIf="!msg.read" (click)="markAsRead(msg)">Read</button>
            <button mat-menu-item (click)="archive(msg)">Archive</button>
          </mat-menu>
        <mat-icon class="notranslate" [matMenuTriggerFor]="appMenu">more_vert</mat-icon>
      </div>

    </div>
    <div *ngIf="!messages || messages.length == 0">No messages</div>
  </div>
</div>
