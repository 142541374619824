<div class="work-manager" *ngIf="showComponent()">

  <!-- EDIT -->
  <mat-tab-group *ngIf="mode === 'Edit'" class="tabs" animationDuration="0ms" [(selectedIndex)]="selectedTabIndex">
    <!-- TASKS -->
    <mat-tab *ngIf="getTaskList().length > 0 && this.identity.company.hasCommunicationModule" label="Related tasks ({{getTaskList().length}})">
      <div class="tab-content tasks">
        <div class="list">
          <div *ngFor="let task of getTaskList()" class="taskentry">
            <div class="task" [ngClass]="{'active': _task && task.id == _task.id}">
              <div class="actions">
                <mat-icon class="notranslate" (click)="openTaskPreview(task)" matTooltip="Task details">info</mat-icon>
                <div class="name uppercase">Task {{task.taskNumber}} - {{task.name}}</div>
                <button class="taskstatus" *ngIf="_task" (click)="changeSubTaskStatus()" [disabled]="changingSubTaskStatus">
                  <span *ngIf="subTaskState() !== 'InReview'">Send to Review</span>
                  <span *ngIf="subTaskState() === 'InReview'">Back To Do</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </mat-tab>

    <!-- DOCUMENTS -->
    <mat-tab *ngIf="docsQueue && docsQueue.length > 0" label="Documents ({{docsQueue.length}})" id="document-tab"> 
      <div class="tab-content documents">
        <div class="list">
          <div class="todoentry" *ngFor="let element of docsQueue">
            <div class="document">
              <mat-icon class="check" *ngIf="element.done" matTooltip="Done" matTooltipPosition="above">check_circle</mat-icon>
              <mat-icon class="remove" (click)="removeTodo(element)" matTooltip="Remove" matTooltipPosition="above">remove_circle_outline</mat-icon>
              <div class="filename uppercase" (click)="selectTodo(element)"
                  [id]="'document-name'+element.document.moonNumber"
                  [innerText]="getDocNameForList(element.document.workingCopy)"
                  [matTooltip]="element.document.moonNumber"
                  [ngClass]="{'active' : isElementActive(element) , 'done' : element.done}">
              </div>
              <div class="status">
                <mat-icon [matTooltip]="workManagerService.getDocumentStatus(element, true)">{{workManagerService.getDocumentStatus(element, false)}}</mat-icon>
              </div>
            </div>
            <div class="md-error error" *ngIf="element.error" [matTooltip]="element.error">
              <span>Error</span>
              <mat-icon class="notranslate">close</mat-icon>
            </div>
            <div class="progress" *ngIf="element.progress > 0 && element.progress < 100">
              <mat-progress-bar
                [color]="isElementActive(element) ? 'accent' : 'primary'"
                mode="determinate" 
                [value]="element.progress">
              </mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- IMPORT -->
  <mat-tab-group *ngIf="mode === 'Import'" class="tabs" animationDuration="0ms">
    <!-- TEMPLATES -->
    <mat-tab *ngIf="templatesQueue && templatesQueue.length > 0" label="Templates ({{templatesQueue.length}})" id="document-tab"> 
      <div class="subtask" *ngFor="let element of templatesQueue" (click)="selectTodo(element)">
        <div class="description">
          <div class="subtask-name uppercase italic" [ngClass]="{'active' : isElementActive(element)}" [matTooltip]="element.document.moonNumber">
            {{getDocNameForList(element.document.workingCopy)}}
          </div>

        </div>

        <div class="progress" *ngIf="element.progress > 0 && element.progress < 100">
          <mat-progress-bar
            [color]="isElementActive(element) ? 'accent' : 'primary'"
            mode="determinate" 
            [value]="element.progress">
          </mat-progress-bar>
        </div>

        <div class="md-error error" *ngIf="element.error" [matTooltip]="element.error">
          <span>Error</span>
          <mat-icon class="notranslate">close</mat-icon>
        </div>

        <ng-container *ngIf="!element.error && (element.progress === 0 || element.progress === 100)">
          <div class="moon-number">
            <div class="moon-pill-template">
              {{'T '+element.document.moonNumber}}
            </div>
          </div>
          <div class="edit" [matTooltip]="'Edit'"
            [matTooltipPosition]="'above'">
            <span>EDIT</span>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>