import { Injectable, Inject } from '@angular/core';
import * as _ from 'underscore';
import {
        Rule,
        DocumentAccessProvider,
        MoonDeskDocument,
        DocumentVersion,
        RuleIDs
        } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';

@Injectable({
    providedIn: 'root'
  })
export class OverflowedTextItemRule implements Rule
{
    constructor(@Inject('DocumentAccessProvider') private docAccessProvider: DocumentAccessProvider)
    {}

    id = RuleIDs.OVERFLOWED_TEXTITEM;
    name: string = 'Text Item Overflowed';
    actionName: string = 'Show';

    async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string): Promise<RuleResult>
    {
        const result: RuleResult =
        {
            rule: this,
            filePath: filePath,
            applied: !this.docAccessProvider.isDummy,
            error: false,
            description: `No overflowed items found`
        };
        if (!this.docAccessProvider.isDummy)
        {
          const amount = await this.docAccessProvider.checkTextOverflow(filePath, 0);
          if (amount !== 0)
          {
              result.description = `Found ${amount} text items overflowed` ;
              result.error = true;
              result.data = amount;
          }
        }
        return result;
    }


    action = (result: RuleResult) =>
    {
        if (result.data)
        {
            const amount = <number>result.data;
            alert(`todo select ${amount} text items overflowed in document ${result.filePath}`);
        }
        else
        {
            //
        }
    }
    parseConfig = (result: any) => '';
}
