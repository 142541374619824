import { Injectable, EventEmitter, NgZone } from '@angular/core';
import {
        MoonDeskDocument,
        MoonTask,
        LoggingService,
        TasksService,
        DocumentService,
        Severity,
        TaskView,
        AuthService,
        DocumentVersion,
        FeedbackService,
        DocumentChangedEvent,
        DocumentNameService,
        SubTask,
        EventHubService,
        TaskEvent,
        Identity
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { DocInfo } from '../../../_models/smartmapping';
import { IllustratorService } from '../../../services/illustrator.service';
import * as _ from 'underscore';
import { FilepathService } from '../../../services/filepath.service';
import { FilesystemService } from '../../../services/filesystem.service';
import { SaveDocumentService, SaveDocumentState, SaveDocumentStatus } from '../save-document/save-document.service';
import { LibraryCacheService } from '../../../services/library-cache.service';

/**
 * Object created by DocumentService.
 * Represents a document for a certain docVeresion.
 * If it doesn't exist locally yet, the progress, progressEvent and promise
 * help you to 'wait' for it.
 */
export class DocumentQueueElement
{
  document: MoonDeskDocument;
  isTemplate?: boolean;
  tasks: MoonTask[];
  done?: boolean;
  /**
   * 0 while download didn't start
   * 100 when ready
   * -1 when there was an error
   */
  progress: number = 0;
  progressEvent: EventEmitter<DocumentQueueElement> = new EventEmitter<DocumentQueueElement>();
  promise: Promise<void>;
  error?: any;

  constructor(document: MoonDeskDocument, tasks: MoonTask[])
  {
    this.document = document;
    this.tasks = tasks;
  }
}

export enum WorkManagerState
{
  init,
  loadingqueue,
  loadingelement,
  saving,
  done
}

export interface WorkManagerStatus
{
  status: WorkManagerState;
  details?: string;
  progress?: number;
  saveDocumentStatus?: SaveDocumentStatus;
  // Useful in cases where very large documents are downloaded. If you can't cancel, the extension is stuck until it finishes.
  cancelAction?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class WorkManagerService
{
  /**
   * Should never be accessed from outside! It's public for the unit test
   */
  queue: DocumentQueueElement[] = [];
  queueChange: EventEmitter<DocumentQueueElement[]> = new EventEmitter<DocumentQueueElement[]>();
  activeDocumentChanged: EventEmitter<DocInfo[]> = new EventEmitter<DocInfo[]>();
  /**
   * Should never be accessed from outside! It's public for the unit test
   */
  currentTask: MoonTask;
  currentTaskChange: EventEmitter<MoonTask> = new EventEmitter<MoonTask>();
  statusChange: EventEmitter<WorkManagerStatus> = new EventEmitter<WorkManagerStatus>();

  private _status: WorkManagerStatus = {status: WorkManagerState.done};

  private _selectedQueueElement;

  // private taskIdBuffer: string;

  private identity: Identity;

  getQueue(): DocumentQueueElement[]
  {
    return this.queue;
  }

  get getCurrentTask(): MoonTask
  {
    return this.currentTask;
  }

  constructor(private logger: LoggingService,
              private illService: IllustratorService,
              private taskService: TasksService,
              private filePath: FilepathService,
              private zone: NgZone,
              private fileService: FilesystemService,
              private docService: DocumentService,
              private authService: AuthService,
              private feedbackService: FeedbackService,
              private saveService: SaveDocumentService,
              private libraryCacheService: LibraryCacheService,
              private nameService: DocumentNameService,
              private eventHubService: EventHubService)
  {

    this.identity = this.authService.getCurrentIdentity();
    this.authService.identityChanged.subscribe(id =>
    {
      if (id?.company.id !== this.identity?.company?.id)
      {
        this.logger.trackEvent('@@@@@ Work/identityChanged');
        this.closeTask();
        this.queue = [];
        this.updateQueueStateAndEmitEvent();
      }
    });

    this.eventHubService.goToWorkEdit.subscribe(async (docsOrTask?: MoonDeskDocument[] | TaskView) =>
    {
      await this.navigatedToEdit(docsOrTask);
    });

    this.eventHubService.goToWorkImport.subscribe(async (t?: TaskView) =>
    {
      this.logger.trackEvent('@@@@@ Work/navToImport');
      await this.navigatedToImport(t);
    });

    this.taskService.taskChanged.subscribe((taskEvent: TaskEvent) =>
    {
      if (!this.currentTask)
      {
        return;
      }
      if (taskEvent.event === 'SubTaskChanged')
      {
        this.currentTask.subTasks.forEach(st =>
        {
          if (st.id === taskEvent.subTask.id)
          {
            st.status = taskEvent.subTask.status;
          }
        });
        this.currentTaskChange.emit(this.currentTask);
      }
    });

    this.illService.DocumentChangedEvent.subscribe(() => this.updateQueueStateAndEmitEvent());
    this.docService.documentChanged.subscribe((event: DocumentChangedEvent) => this.documentChangedFromBackend(event));
    this.saveService.statusChange.subscribe(s => this.saveStatusChanged(s));
  }

  async updateQueueStateAndEmitEvent()
  {
    const docInfos = await this.illService.getExtOpenDocInfos();
    docInfos?.forEach(di =>
    {
      const qeWithBrokenInfo = _.find(this.queue, q => di.active && q.document.workingCopy === di.document && q.document.id !== di.docId);
      if (qeWithBrokenInfo)
      {
        console.log('Document with broken info!');
        // Fix for bug #10023 - should not happend after 1.19.19 - now the document are saved with the correct docInfo
        // For already broken docInfos (docs saved before 1.19.19) the currentDocService will detect this and fix the infos
      }
      else
      {
        const qe = _.find(this.queue, q => q.document.id === di.docId);
        if (qe && !qe.isTemplate)
        {
          qe.document.workingCopy = di.document;
        }
      }

      //if(this.isCreationMode())
      // if(this.currentTask.preclassifiedDocument)
      // {
      //   di.docId = this.currentTask.preclassifiedDocument.id;
      //   di.version = this.currentTask.preclassifiedDocument.latestMayorVersionNumber;
      //   this.queue = this.queue.filter(q => q.document.id !== di.docId);
      // }

    });
    this.queueChange.emit(this.queue);
    this.activeDocumentChanged.emit(docInfos);
  }

  private documentChangedFromBackend(event: DocumentChangedEvent)
  {
    if (event.action === 'AddEdit')
    {
      if (event.documents && event.documents.length > 1)
      {
        console.log('Can\'t update current document after bulk changes');
        return;
      }
      if (this.queue)
      {
        _.forEach(this.queue, q =>
        {
          const doc = _.find(event.documents, d => d.id === q.document.id);
          if (doc)
          {
            const newDoc = _.clone(doc);
            newDoc.editingVersion = newDoc.latestVersion;
            newDoc.workingCopy = q.document.workingCopy;
            q.document = newDoc;
          }
        });
      }
    }
    else if (event.action === 'VersionState')
    {
      if (this.queue)
      {
        const queueDocs = _.map(this.queue, q => q.document);
        this.updateVersionStates(queueDocs, event.version);
      }
    }
    this.queueChange.emit(this.queue);
  }

  /**
   * updates the version states if necessary
   * returns true if the editing veresion of one of the given documents was changed
   */
  private updateVersionStates(docs: MoonDeskDocument[], newVersion: DocumentVersion): boolean
  {
    let changed = false;
    docs.forEach(doc =>
    {
      if (doc.editingVersion.id === newVersion.id)
      {
        doc.editingVersion.status = newVersion.status;
        changed = true;
      }
      if (doc.latestVersion.id === newVersion.id)
      {
        doc.latestVersion.status = newVersion.status;
      }
    });
    return changed;
  }

  get status()
  {
    return this._status;
  }

  updateStatus = async (
    state?: WorkManagerState,
    details?: string,
    progress?: number,
    saveDocStatus?: SaveDocumentStatus,
    cancelAction?: () => void) =>
  {
    this._status = {status: state, details: details, progress: progress, saveDocumentStatus: saveDocStatus, cancelAction: cancelAction};
    // console.log('WorkMgrState:');
    // console.log(this._status);
    this.statusChange.emit(this._status);
  };

  private async saveStatusChanged(saveStatus: SaveDocumentStatus)
  {
    if (saveStatus.status === SaveDocumentState.done)
    {
      this.updateStatus(WorkManagerState.loadingelement);
      await this.postSaveHandling();
      this.updateStatus(WorkManagerState.done);
    }
    else if (saveStatus.status === SaveDocumentState.preparingupload || saveStatus.status === SaveDocumentState.uploading)
    {
      this.updateStatus(WorkManagerState.saving, saveStatus.details, 0, saveStatus);
    }
    else if (saveStatus.status === SaveDocumentState.cancelled)
    {
      await this.updateQueueStateAndEmitEvent();
      this.updateStatus(WorkManagerState.done);
    }
    else
    {
      this.updateStatus(WorkManagerState.done);
    }
  }

  /**
   * This contains the logic about what happens after a document was saved
   * - if ther was just one element in the queue, we close the doc and empty the queue
   * - if there are more elements in the queue,
   *    - close the current one
   *    - we pick the next one that is in state 'todo' and select it
   *    - if there's nothing left 'todo' we close the doc and empty the queue
   */
  async postSaveHandling()
  {
    console.log(`@@@@@ WorkManagerService - postSaveHandling`);
    const saveStatus = this.saveService.status;
    if (!saveStatus.document || !saveStatus.document.workingCopy)
    {
      console.log('@@@@@ WorkManagerService - nothing to post handle!');
      return;
    }
    const current = _.find(this.queue, q => q.document.id === saveStatus.document.id);
    if (current)
    {
      current.done = true;
      current.document = saveStatus.document;
      current.document.workingCopy = saveStatus.document.workingCopy;
      this.queueChange.emit(this.queue);

      const currentIndex = this.queue.indexOf(current);
      let next = _.find(this.queue, q => !q.done && !q.isTemplate && this.queue.indexOf(q) > currentIndex);
      if (!next)
      {
        next = _.find(this.queue, q => !q.done && !q.isTemplate);
      }
      if (next)
      {
        this.selectQueueElement(next);
      }
      else // all queueElements are done
      {
        // todo should we empty the queue???
        // this.queue = [];
        // this.queueChange.emit(this.queue);
      }
    }
    else if (saveStatus.document && this.currentTask)
    {
      // A new document has been imported in the context of a task,
      // as the document may or may not be added to the task we need to load
      // the subtasks again to be sure and update the queue
      const updatedTask = await this.taskService.queryTask(
      {
        taskId: this.currentTask.id
      });

      const document = JSON.parse(JSON.stringify(saveStatus.document));
      if (_.any(updatedTask.subTasks, st => st.documentId === document.id) &&
          !_.any(this.queue, element => element.document.id === document.id))
      {
        // New document has been added (imported) to the task and is not in the queue
        document.isTemplate = false;
        const newQueueElement = new DocumentQueueElement(document, []);
        newQueueElement.done = true;
        newQueueElement.progress = 100;
        this.queue.push(newQueueElement);
        this.queueChange.emit(this.queue);
        this.selectQueueElement(newQueueElement);
        this.currentTask.subTasks = updatedTask.subTasks;
        this.currentTaskChange.emit(this.currentTask);
      }
    }
    if (saveStatus.saveAndClose && saveStatus.status === SaveDocumentState.done)
    {
      // don't pass 'true' here - if not the local copy will be manipulated again
      // and can't be reused when the user opens this document through moondesk again
      // (checksum changes!)
      await this.illService.closeDocument(saveStatus.document.workingCopy, false);
    }
  }

  private async navigatedToImport(taskView?: TaskView)
  {
    if (taskView)
    {
      this.currentTask = taskView.task;
      this.currentTaskChange.emit(taskView.task);
      const documents = await this.getTaskDocuments(taskView.task);
      const selectedDocument = _.find(documents, doc => doc.id === taskView.selectedDocumentId);
      // if(!this.isCreationMode())
      // {
        await this.updateQueue(documents, selectedDocument);
      // }
    }
  }

  private async navigatedToEdit(docsOrTask?: MoonDeskDocument[] | TaskView)
  {
    try
    {
      if (!docsOrTask)
      {
        return;
      }

      if (this.status.status !== WorkManagerState.done)
      {
        this.feedbackService.notifyMessage('MoonDesk is busy, can\'t open right now');
        return;
      }
      this.updateStatus(WorkManagerState.init, 'Loading document', 0);

      let docs: MoonDeskDocument[];
      let selectedDocument: MoonDeskDocument;
      let selectFirst: boolean;

      // If the user navigated here with a Task, We update the Queue based on the Subtasks
      if ((<TaskView>docsOrTask).task)
      {
        const taskView = <TaskView>docsOrTask;
        if (taskView.task.subTasks && taskView.task.subTasks.length > 0)
        {
          docs = await this.getTaskDocuments(taskView.task);
          selectedDocument = _.find(docs, doc => doc.id === taskView.selectedDocumentId);
          selectFirst = taskView.selectFirst;

          this.currentTask = taskView.task;
          this.currentTaskChange.emit(taskView.task);
        }
      }
      // Else the user navigated here with a list of documents
      else
      {
        docs = <MoonDeskDocument[]>docsOrTask;
        selectedDocument = docs[0];
      }
      // 2. Update the Queue
      if (docs)
      {
        await this.updateQueue(docs, selectedDocument, selectFirst);
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting Documents/Task', err);
      this.currentTask = undefined;
      this.currentTaskChange.emit(undefined);
      this.updateStatus(WorkManagerState.done);
    }
  }

  private async getTaskDocuments(task: MoonTask): Promise<MoonDeskDocument[]>
  {
    const stDocsVersionIds = _.filter(task.subTasks, subTask =>
      subTask.status !== 'Closed').map(st => st.document.latestVersionId);
    const templateDocsVersionIds = task.taskDocumentTemplates?.map(template => template.document.latestVersionId);
    // if(task.preclassifiedDocument)
    // {
    //   templateDocsVersionIds.push(task.preclassifiedDocument.latestVersionId);
    // }

    const allDocVersionsIds = _.uniq(_.union(stDocsVersionIds, templateDocsVersionIds));
    const allDocs = await this.docService.getDocumentsByVersion(allDocVersionsIds);

    const subTasksDocs = _.filter(allDocs, doc => _.some(stDocsVersionIds, versionId => versionId === doc.latestVersionId));
    const templatesDocs = _.filter(allDocs, doc => _.some(templateDocsVersionIds, versionId => versionId === doc.latestVersionId));

    const docsAsTemplateAndSubTask = _.intersection(subTasksDocs, templatesDocs);
    docsAsTemplateAndSubTask.forEach(doc =>
    {
      const template = _.find(templatesDocs, templateDoc => templateDoc.id === doc.id);
      const index = templatesDocs.indexOf(template);
      templatesDocs[index] = JSON.parse(JSON.stringify(template));
    });

    templatesDocs.forEach(doc => doc.isTemplate = true);
    const result: MoonDeskDocument[] = _.union(subTasksDocs, templatesDocs);

    return result;
  }

  // private isCreationMode(): boolean {
    // return this.currentTask && this.currentTask.preclassifiedDocument;
      // this.currentTask.taskDocumentTemplates?.length === 1 &&
      // this.currentTask.taskDocumentTemplates[0].document?.name === 'Creation' &&
      // this.currentTask.subTasks?.length === 0;
  // }

  closeTask() {
    if (this.currentTask) {
      this.currentTask = undefined;
      this.removeTemplatesFromQueue();
      this.currentTaskChange.emit();
    }
  }

  private removeTemplatesFromQueue()
  {
    this.queue = this.queue.filter(element => !element.isTemplate);
    this.queueChange.emit(this.queue);
  }

  async updateQueue(documents: MoonDeskDocument[], selectedDocument: MoonDeskDocument, selectFirst?: boolean)
  {
    try
    {
      this.queue = [];
      _.forEach(documents, doc =>
      {
        if (!doc.editingVersion)
        {
          doc.editingVersion = doc.latestVersion;
        }
      });

      this.queue = await this.createQueue(documents, selectedDocument);

      if (this.queue && this.queue.length > 0)
      {
        let selectedQueueElement: DocumentQueueElement;
        if (selectedDocument)
        {
          selectedQueueElement = _.find(this.queue, element => element.document.id === selectedDocument.id);
        }
        else if (!this.currentTask || selectFirst)
        {
          selectedQueueElement = this.queue[0];
        }

        if (selectedQueueElement)
        {
          selectedQueueElement.tasks = this.filterTaskList(selectedQueueElement);
          this.selectQueueElement(selectedQueueElement);
        }
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error updating the Queue', err);
    }
    finally
    {
      this.queueChange.emit(this.queue);
    }
  }

  async closeEverything()
  {
    if (this.queue && this.queue.length > 0)
    {
      for (const q of this.queue)
      {
        if (q.document.workingCopy && await this.illService.isDocumentOpen(q.document.workingCopy))
        {
          await this.illService.closeDocument(q.document.workingCopy, true);
        }
      }
      this.queue = [];
    }
  }

  async selectQueueElement(element: DocumentQueueElement)
  {
    console.log(`@@@@@ WorkManagerService - selectQueueElement`);
    console.log(element);
    this.logger.trackTrace(`activate download for ${element.document ? element.document.workingCopy : 'undefined'}`);
    this._selectedQueueElement = element;
    // if it's already downloaded, we directly open it
    if (element.progress === 100 || element.progress === -1)
    {
      console.log('Already downloaded: Open in CurrentDoc Manager');
      this.updateStatus(WorkManagerState.done);
      this.illService.openDocument(element.document.workingCopy);
    }
    else
    {
      // start the download right now, independently of the queue
      const subs = element.progressEvent.subscribe((e: DocumentQueueElement) =>
      {
        if (e === this._selectedQueueElement) // important to make sure it is still the element the user clicked latest
        {
          this.updateStatus(WorkManagerState.loadingelement, 'Downloading document', e.progress);
          if (e.progress === 100)
          {
            this.updateStatus(WorkManagerState.done);
            this.illService.openDocument(element.document.workingCopy);
            subs.unsubscribe();
          }
          if (e.progress === -1)
          {
            const errMsg = e.error ? e.error : 'Error downloading document';
            this.feedbackService.notifyError(errMsg);
            this.updateStatus(WorkManagerState.done);
            subs.unsubscribe();
          }
        }
        else
        {
          subs.unsubscribe();
        }
      });
      await this.startDownload(element, this.queue, false);
    }
  }

  removeQueueElement(element: DocumentQueueElement)
  {
    this.queue.splice(this.queue.indexOf(element), 1);
    this.queueChange.emit(this.queue);
  }

  sortQueue(queue: DocumentQueueElement[]): DocumentQueueElement[]
  {
    if (this.currentTask)
    {
      queue = _.chain(queue)
        .sortBy(element => element.isTemplate)
        .sortBy(element => this.getDocumentStatus(element, true).toLowerCase())
        .value();
      queue.reverse();
    }
    return queue;
  }

  getDocumentStatus(documentDownload: DocumentQueueElement, isTooltip: boolean): string
  {
    if (this.currentTask)
    {
      const subTask = _.find(this.currentTask.subTasks, st => st && st.document.id === documentDownload.document.id);
      if (subTask && subTask.status)
      {
        switch (subTask.status)
        {
          case 'Closed':
            if (isTooltip)
            {
              return 'Closed';
            }
            return 'close';
          case 'Done':
            if (isTooltip)
            {
              return 'Done';
            }
            return 'done';
          case 'InReview':
            if (isTooltip)
            {
              return 'In Review';
            }
            return 'visibility';
          case 'ToDo':
            if (isTooltip)
            {
              return 'To Do';
            }
            return 'panorama_fish_eye';
        }
      }
    }
    return '';
  }

  private filterTaskList(docQueueElement: DocumentQueueElement): MoonTask[]
  {
    let relatedTasks: MoonTask[];
    if (docQueueElement && docQueueElement.tasks && docQueueElement.tasks.length > 0)
    {
      relatedTasks = docQueueElement.tasks;
      if (this.currentTask) // We need to put the current task at the beginning of the array
      {
        const index: number = relatedTasks.indexOf(_.find(relatedTasks, task => task.id === this.currentTask.id));
        relatedTasks.splice(index, 1);
        relatedTasks.unshift(this.currentTask);
      }
    }
    return relatedTasks;
  }

    /**
   * To get the ai documents of the given docs, respecting their 'editingVersion' AND considering
   * open documents in illustrator...
   * 1. IF it's already open in Illustrator, the download will be done immediately
   * 2. ELSE IF a local copy exists, the progress will go to 100% directly after 'startDownload'
   * 3. ELSE the progressEvent informs you about the download
   * Check progress and (if it's not -1 or 100), listen to the progressEvent!
   * @param docs The docs for which you want the documents. Need to assign their 'editingVersion' before!
   * @param startFirst The first document to be opened/downloaded
   * @returns DocumentDownload queue containing progress and an EventEmitter for progress. When
   * finished, the label objects contain the workingCopy. Also, each DocumentDownload object will contain the
   * Review if it exists
   */
  private async createQueue(docs: MoonDeskDocument[], startFirst: MoonDeskDocument): Promise<DocumentQueueElement[]>
  {
    this.logger.trackTrace('createQueue...');
    let queue = [];
    let allTasks: MoonTask[] = [];
    const identity = this.authService.getCurrentIdentity();
    if (docs && docs.length > 0 && identity.company?.subscriptionPlan?.hasTaskModule)
    {
      try
      {
        this.logger.trackTrace(`WorkManagerService/getDocuments/getTasks for ${identity.company.name}`);
        this.updateStatus(WorkManagerState.loadingqueue, 'Looking up related tasks');
        const ids = _.map(docs, l => l.id);
        allTasks = await this.taskService.getTasksForDocuments(ids);
      }
      catch (err)
      {
        this.logger.logException(err);
      }
    }
    // ######## Start Fill download queue
    this.updateStatus(WorkManagerState.loadingqueue, 'Loading documents');
    const docInfos: DocInfo[] = await this.illService.getExtOpenDocInfos();
    console.log('currently open DocInfos...');
    console.log(docInfos);
    for (let i = 0; i < docs.length; i++)
    {
      const doc = docs[i];
      if (!doc.editingVersion)
      {
        throw Error('Need to specify an editing version for each doc!');
      }
      let bundle: MoonTask[] = [];
      if (!doc.isTemplate)
      {
        bundle = _.filter(allTasks, task => _.some(task.subTasks, subTask => subTask.documentId === docs[i].id));
        bundle = _.sortBy(bundle, b => b.taskNumber);
      }
      const openFile = _.find(docInfos, di => di.docId === doc.id && di.version === doc.editingVersion.versionNumber);

      const element: DocumentQueueElement = new DocumentQueueElement(doc, bundle);
      if (openFile)
      {
        element.progress = 100;
        element.document.workingCopy = openFile.document;
      }
      else
      {
        element.progress = 0;
        const filepath = await this.filePath.getFreeWorkingCopyPath(doc, 'InWork', doc.editingVersion.fileType);
        element.document.workingCopy = filepath;
      }
      element.isTemplate = doc.isTemplate;

      if (this.currentTask && this.currentTask.subTasks && this.currentTask.subTasks.length > 0)
      {
        const subTask = _.find(this.currentTask.subTasks, st => st.documentId === doc.id);
        element.done = subTask && (subTask.status === 'Done' || subTask.status === 'InReview');
      }
      queue.push(element);
    }
    // ######## End Fill download queue

    queue = this.sortQueue(queue);
    console.log('CCCCCCCCCCCCREATED QUEUE');
    console.log(queue);

    const toBeOpened: DocumentQueueElement = _.find(queue, dd => dd.document.id === startFirst?.id);
    if (!toBeOpened)
    {
      this.updateStatus(WorkManagerState.done);
    }
    else if (toBeOpened.progress !== 100)
    {
      await this.startDownload(toBeOpened, queue, false);
    }
    else
    {
      this.updateStatus(WorkManagerState.done);
      this.illService.openDocument(toBeOpened.document.workingCopy);
    }
    return queue;
  }

  // eslint-disable-next-line no-undef-init
  async startDownload(docDownload: DocumentQueueElement, queue: DocumentQueueElement[] = undefined, continueOnFinish: boolean = false)
  {
    if (docDownload.progress > 0)
    {
      return;
    }
    docDownload.promise = new Promise<void>(async(resolve, reject) =>
    {
      const docDownloadRef = docDownload;
      const cont = continueOnFinish;
      const queueRef = queue;
      const setProgress = (p: number) =>
      {
        this.zone.run(() =>
        {
          docDownloadRef.progress = p;
          docDownloadRef.progressEvent.emit(docDownloadRef);
        });
      };

      try
      {
        let found: boolean = false;
        setProgress(1);

        // (1) check if one of the cached files is the one we need
        if (docDownloadRef.document.editingVersion.checksum)
        {
          const folder = this.fileService.parse(docDownloadRef.document.workingCopy).dir;
          const existingFiles = this.fileService.readdirSync(folder, true, 'files');
          if (existingFiles)
          {
            console.log(`found ${existingFiles.length} files in the document folder (${folder})`);
            for (let i = 0; i < existingFiles.length; i++)
            {
              console.log(`CHK Checking checksum of file ${existingFiles[i]}`);
              const checksum = await this.fileService.fileChecksum(existingFiles[i]);
              console.log(`CHK Checksum is ${checksum}`);
              console.log(`CHK ..expecting ${docDownloadRef.document.editingVersion.checksum}`);
              if (checksum === docDownloadRef.document.editingVersion.checksum)
              {
                existingFiles[i] = this.updateFilename(docDownloadRef.document, existingFiles[i], folder);
                console.log(`Replacing filename ${docDownloadRef.document.workingCopy} with cached file ${existingFiles[i]}`);
                docDownloadRef.document.workingCopy = existingFiles[i];
                console.log(`Correct cached file found: ${docDownloadRef.document.workingCopy}, no need to download`);
                found = true;
                break;
              }
            }
          }
        }
        // (2) Download from cloud
        if (!found)
        {
          let fileContent = await this.docService.downloadFile(docDownloadRef.document.editingVersion,
            progress =>
            {
              setProgress(progress.percentage * 0.9);
              this.updateStatus(WorkManagerState.loadingelement,
                'Downloading document - ' + progress.loadedKB + ' KB / ' + progress.totalKB + ' KB   (' + progress.speedKBps + ' KB/s)',
                progress.percentage, null, progress.cancelAction);
            });
          // check if it's not open, in that case we use a free filename
          const openDocs = await this.illService.getOpenDocuments();
          if (_.any(openDocs, openDoc => openDoc === docDownloadRef.document.workingCopy))
          {
            docDownloadRef.document.workingCopy = await this.getFreeFilename(docDownloadRef.document, openDocs);
          }
          await this.fileService.writeFileAsync(docDownloadRef.document.workingCopy, fileContent, {encoding: 'base64'});
          // testcode start
          const checksum = await this.fileService.fileChecksum(docDownloadRef.document.workingCopy);
          console.log(`CHK Downloaded checksum is ${checksum}`);
          console.log(`CHK Filesystem checksum is ${checksum}`);
          console.log(`CHK ..expecting ${docDownloadRef.document.editingVersion.checksum}`);
          // testcode end
        }

        // (3) Make sure all linked files are in the subfolder Links
        await this.libraryCacheService.downloadLinkedFiles(
          docDownloadRef.document.editingVersion,
          docDownloadRef.document.workingCopy,
          this.updateStatus,
          true);
        setProgress(100);
        resolve();
      }
      catch (err)
      {
        const errMsg = err && err.error ? err.error : err;
        docDownloadRef.error = errMsg;
        setProgress(-1);
        this.logger.trackTrace(`Error download document for docId ${docDownloadRef.document.id}: ${errMsg}`, Severity.Error);
        reject(err);
      }
      finally
      {
        if (cont)
        {
          await this.startNextDownload(queueRef);
        }
        this.updateStatus(WorkManagerState.done);
      }
    });
    return await docDownload.promise;
  }

  async changeSubTaskStatus(subtask: SubTask, status: 'ToDo' | 'InReview')
  {
    if (subtask)
    {
      try
      {
        await this.taskService.changeStatus(subtask.id, status);
        this.eventHubService.refreshTasks.emit();
        this.feedbackService.notifyMessage('Task status updated');
      }
      catch (err)
      {
        this.feedbackService.notifyError('Error changing status', err);
      }
    }
    else
    {
      this.feedbackService.notifyError('Nonexistent subtask');
    }
  }

  /**
   * If current filename of the cached file is not updated, we replace it with an updated one
   */
  updateFilename(document: MoonDeskDocument, filePath: string, folder: string): string
  {
    console.log('Name check');
    let updatedFilePath: string = filePath;
    try
    {
      if (document && document.latestVersion)
      {
        const updatedName = this.nameService.createDocumentName('InWork', document, document.latestVersion.fileType, null);
        const currentName = this.fileService.filenameOf(filePath);
        if (updatedName !== currentName)
        {
          const destPath = this.fileService.join(folder, updatedName);
          if (this.fileService.exists(destPath) === false)
          {
            console.log('Outdated name found: ' + currentName + ' replacing with: ' + updatedName);
            this.fileService.renameFile(filePath, destPath);
            updatedFilePath = destPath;
          }
          else
          {
            this.logger.trackTrace(`Not updating name of local copy ${currentName} for document ${document.id}`, Severity.Warning);
          }
        }
        else
        {
          console.log('The current name is updated');
        }
      }
    }
    catch (err)
    {
      this.logger.trackTrace(`Unexpected error renaming the file: ${err}`, Severity.Error);
    }
    finally
    {
      return updatedFilePath;
    }
  }

  /**
   * If there's no other download running already, we start the next in the queue.
   */
  private async startNextDownload(queue: DocumentQueueElement[])
  {
    this.logger.trackTrace('DocumentService starting next download');
    const next = _.find(queue, ip => ip.progress === 0);
    if (next)
    {
      await this.startDownload(next, queue, true);
    }
  }

  /**
   * Get a filename that is not open in illustrator!
   */
  // eslint-disable-next-line no-undef-init
  async getFreeFilename(doc: MoonDeskDocument, openFiles: string[]= undefined): Promise<string>
  {
    if (!openFiles)
    {
      openFiles = await this.illService.getOpenDocuments();
    }
    let filepath = await this.filePath.getWorkingCopyPath(doc, undefined, 'InWork', doc.editingVersion.fileType);
    let counter = 0;
    while (_.any(openFiles, of => of === filepath))
    {
      ++counter;
      filepath = await this.filePath.getWorkingCopyPath(doc, `_${counter}`, 'InWork', doc.editingVersion.fileType);
    }
    return filepath;
  }

  async openTaskPreview(taskId: string)
  {
    this.eventHubService.openTaskView.emit({taskId: taskId});
  }
}
