<div class="task-search">


  <div class="top">

    <div class="wrapper">

        <div class="search-input" #searchInput>
            <mat-form-field [formGroup]="filterController" class="search-field" appearance="outline">
                <mat-icon class="notranslate search-icon" (click)="toggleOverlay()" matPrefix>search</mat-icon>
                <input
                (click)="toggleOverlay()"
                matInput 
                type="search" 
                id="searchInput"  
                formControlName="searchText"
                #inputSearchTasks
                placeholder="Search by task name" 
                (keydown.enter)="requestSearch()"
                autocomplete="off">
                <mat-icon matSuffix (click)="toggleOverlay()">filter_alt</mat-icon>
            </mat-form-field>   
        </div>
  </div>

  <div class="tags">

    <div class="tag" *ngIf="searchText.value && searchText.value.length > 0">
      <span class="tag-label">Text:</span>
      <span>{{searchText.value}}</span>
      <mat-icon (click)="clearValue('searchText'); requestSearch()">clear</mat-icon>
    </div>

    <div class="tag" *ngIf="taskNumber.value">
      <span class="tag-label">Number:</span>
      <span>{{taskNumber.value}}</span>
      <mat-icon (click)="clearValue('taskNumber'); requestSearch()">clear</mat-icon>
    </div>


    <ng-container *ngIf="statuses.value">
      <div class="tag" *ngFor="let status of statuses.value">
          <span class="tag-label">Status:</span>
          <span *ngIf="status === 'InWork'">In Design</span>
          <span *ngIf="status === 'InReview'">In Review</span>
          <span *ngIf="status === 'Done'">Done</span>
          <mat-icon (click)="removeStatus(status) ; requestSearch()">clear</mat-icon>
      </div>
    </ng-container>
    
    <div class="tag" *ngIf="minDate.value">
      <span class="tag-label">Min date:</span>
      <span>{{minDate.value | date : 'dd/MM/yyyy'}}</span>
      <mat-icon (click)="clearValue('minDate') ; requestSearch()">clear</mat-icon>
    </div>

    <div class="tag" *ngIf="maxDate.value">
      <span class="tag-label">Max date:</span>
      <span>{{maxDate.value  | date : 'dd/MM/yyyy'}}</span>
      <mat-icon (click)="clearValue('maxDate') ; requestSearch()">clear</mat-icon>
    </div>

    <ng-container *ngIf="assignedUserId?.value?.length > 0">
      <div class="tag" *ngFor="let assignedId of assignedUserId.value">
        <span class="tag-label">Assigned to:</span>
        <span>{{getUsernameById(assignedId)}}</span>
        <mat-icon (click)="removeAssigned(assignedId) ; requestSearch()">clear</mat-icon>
      </div>
    </ng-container>

    <div class="tag clickable" *ngIf="orderBy.value" mat-button [matMenuTriggerFor]="orderByMenu">
      <span class="tag-label">Order by:</span>
      <span *ngIf="orderBy.value === 'Timestamp'">Creation date</span>
      <span *ngIf="orderBy.value === 'ExpirationDate'">Due date</span>
      <span *ngIf="orderBy.value === 'LastUpdate'">Modification date</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #orderByMenu="matMenu">
      <button mat-menu-item *ngIf="orderBy.value !== 'Timestamp'" (click)="setOrderBy('Timestamp')">
        Creation date
      </button>
      <button mat-menu-item *ngIf="orderBy.value !== 'ExpirationDate'" (click)="setOrderBy('ExpirationDate')">
        Due date
      </button>
      <button mat-menu-item *ngIf="orderBy.value !== 'LastUpdate'" (click)="setOrderBy('LastUpdate')">
        Modification date
      </button>
    </mat-menu>

    <div class="tag clickable" *ngIf="filterBy.value" mat-button [matMenuTriggerFor]="filterByMenu">
      <span class="tag-label">Filter by:</span>
      <span *ngIf="filterBy.value === 'MyTasks'">My Tasks</span>
      <span *ngIf="filterBy.value === 'MyPendings'">My Pendings</span>
      <span *ngIf="filterBy.value === 'All'">All</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #filterByMenu="matMenu">
      <button mat-menu-item *ngIf="filterBy.value !== 'MyTasks'" (click)="setFilterBy('MyTasks')">
        My Tasks
      </button>
      <button mat-menu-item *ngIf="filterBy.value !== 'MyPendings'" (click)="setFilterBy('MyPendings')">
        My Pendings
      </button>
      <button mat-menu-item *ngIf="filterBy.value !== 'All'" (click)="setFilterBy('All')">
        All
      </button>
    </mat-menu>

    <div class="tag" *ngIf="archived.value">
      Archived tasks
      <mat-icon (click)="removeArchivedValue() ; requestSearch()">clear</mat-icon>
    </div>

    <div class="tag reset-filter-tag" (click)="resetFilter()" *ngIf="showResetFilter()">
      Reset filter
    </div>

  </div>

  <ng-template #filterTemplate>
    <div class="filter-template">
      <div class="filter-selectors">

        <div class="filter-element" *ngIf="showMyTasksSwitch">
          <mat-form-field class="filter-by" [formGroup]="filterController">
            <mat-select formControlName="filterBy">
              <mat-option [value]="'MyTasks'">My Tasks</mat-option>
              <mat-option [value]="'MyPendings'">My Pendings</mat-option>
              <mat-option [value]="'All'">All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    
        <mat-form-field class="filter-element" [formGroup]="filterController">
          <input matInput type="number" formControlName="taskNumber" placeholder="Task Number">
          <mat-icon (click)="clearValue('taskNumber')" class="notranslate clear" matSuffix>clear</mat-icon>
        </mat-form-field>
    
        <div class="filter-element">
          <mat-form-field [formGroup]="filterController">
            <mat-label>Task status</mat-label>
            <mat-select formControlName="statuses" multiple>
              <mat-option value="InWork">In Design</mat-option>
              <mat-option value="InReview">In Review</mat-option>
              <mat-option value="Done">Done</mat-option>
            </mat-select>
            <mat-icon style="z-index: 2000;"  (click)="clearValue('statuses')" class="notranslate clear" matSuffix>clear</mat-icon>
          </mat-form-field>
        </div>
    
        <div class="filter-element">
          <mat-form-field [formGroup]="filterController">
            <input matInput [matDatepicker]="picker1"
                            [max]="maxDate.value"
                            placeholder="Min Date"
                            formControlName="minDate"
                            (click)="picker1.open()"
                            readonly>
            <mat-icon (click)="clearValue('minDate')" class="notranslate clear" matSuffix>clear</mat-icon>
            <mat-datepicker #picker1 [calendarHeaderComponent]="moonDatePickerHeader"></mat-datepicker>
          </mat-form-field>
        </div>
    
        <div class="filter-element">
          <mat-form-field [formGroup]="filterController">
            <input matInput [matDatepicker]="picker2"
                            [min]="minDate.value"
                            placeholder="Max Date"
                            formControlName="maxDate"
                            (click)="picker2.open()"
                            readonly>
            <mat-icon (click)="clearValue('maxDate')" class="notranslate clear" matSuffix>clear</mat-icon>
            <mat-datepicker #picker2 [calendarHeaderComponent]="moonDatePickerHeader"></mat-datepicker>
          </mat-form-field>
        </div>
    
        <div class="filter-element">
        
          <mat-form-field [formGroup]="filterController" class="assign">
            <mat-label>Assigned To</mat-label>
            <mat-select formControlName="assignedUserId" placeholder="Assigned to..." multiple>
              <mat-option  *ngFor="let u of companyUsers" [value]="u.id">{{u.username ? u.username : u.email}}</mat-option>
            </mat-select>
            <mat-icon (click)="clearValue('assignedUserId')" class="notranslate clear" matSuffix>clear</mat-icon>
          </mat-form-field>
        </div>
        
        <div class="filter-element">
        
          <mat-form-field  [formGroup]="filterController">
            <mat-label>Order by</mat-label>
            <mat-select formControlName="orderBy" >
              <mat-option [value]="'Timestamp'">Creation date</mat-option>
              <mat-option [value]="'ExpirationDate'">Due date</mat-option>
              <mat-option [value]="'LastUpdate'">Modification date</mat-option>
            </mat-select>
            <mat-icon matSuffix (click)="toggleReverseorderBy(); $event.stopPropagation()">{{reverseOrder.value === true ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </mat-form-field>
          </div>

      </div>
    
      <div class="filter-slidebtns">
        <span class="archived-tasks">
          <span class="lable-archived-tasks">Archived Tasks</span>
          <form [formGroup]="filterController">
            <mat-slide-toggle
              formControlName="archived">
            </mat-slide-toggle>
          </form>
        </span>
      </div>

      <div class="task-buttons">
        <div class="button-group">
          <button class="search-button md-button" [disabled]="busy" (click)="requestSearch()">Search</button>
          <button class="reset-button md-textbutton" [disabled]="busy" (click)="resetFilter()">
              <mat-icon class="notranslate">refresh</mat-icon>
              <span>Reset Filters</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  

</div>
