import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import {
  DocumentVersion,
  DocumentService,
  FeedbackService
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'document-status-popup',
  templateUrl: './document-status-popup.component.html',
  styleUrls: ['./document-status-popup.component.scss']
})
export class DocumentStatusPopupComponent {
  currentStatus: 'Preapproved' | 'Draft' | 'Approved';
  docVersion: DocumentVersion;
  busy: boolean;

  constructor(
    public dialogRef: MatDialogRef<DocumentStatusPopupComponent>,
    private docService: DocumentService,
    private feedbackService: FeedbackService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.docVersion = data.docVersion;
    this.currentStatus = this.docVersion.status;
  }

  async changeVersionStatus(newStatus: 'Draft' | 'Approved') {
    if (newStatus !== this.currentStatus) {
      this.busy = true;
      try {
        let _version: DocumentVersion = _.clone(this.docVersion);
        _version.status = newStatus;
        _version = await this.docService.updateDocVersionState(_version);
        this.feedbackService.notifyMessage('Document status updated');
        this.busy = false;
        this.close();
      } catch (err) {
        this.feedbackService.notifyError('Error updating status', err);
      } finally {
        this.busy = false;
      }
    }
  }

  close() {
    if (!this.busy) {
      this.dialogRef.close();
    }
  }
}
