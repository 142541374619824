<div class="md-popup bulk-import">

  <div class="md-popup-header">
    <!-- <mat-icon class="notranslate" matTooltip="Close" [ngClass]="{'disabled':importing}" (click)="close()">close</mat-icon> -->
    <div class="title">Bulk Import</div>
  </div>

  <div class="md-popup-content content">
    <div class="class-selector">
      <class-selector #classSelectorComponent
        (filterChanged)="filtersChanged($event)"
        [disabled]="importing"
        [showAllText]="false"
        [multiClassSelection]="true"
        [multiDocTypeSelection]="false"
        [autoSelect]="true"
        [hideSearch]="true">
      </class-selector>
    </div>
    <div class="files">
      <label for="folder" class="md-textbutton">
          <mat-icon class="notranslate">folder</mat-icon>  
          <div>Browse...</div>
      </label>
      <button id="folder"
        #folderInput
        type="file"
        (click)="selectBulkFolder()"
        webkitDirectory>
        </button>
        <div class="selectionFolder" *ngIf="selection && selection.length>0" >
          <div class="name" >Selected Folder</div> 
          <div class="status">{{selectedFolder}}</div> 
        </div>
        
      <div class="selectiontext" *ngIf="selection && selection.length>0">{{selection.length}} .ai file(s) selected</div>

      <div class="filelist">
        <div class="file" *ngFor="let s of selection">
          <div class="name" [matTooltip]="s.path">{{s.path}}</div>
          <div class="status">
            <div *ngIf="!s.result && this.current != s">Waiting</div>
            <div *ngIf="!s.result && this.current == s">In Work</div>
            <div *ngIf="s.result && s.result.code==1">Done</div>
            <div *ngIf="s.result && s.result.code==-1" [matTooltip]="s.result.errorMessage" class="file-error">Error: {{s.result.errorMessage}}</div>
            <div *ngIf="s.result && s.result.code==0">Cancelled</div>
          </div>
        </div>
      </div>
    </div>
  </div>  

  <div class="md-popup-actions actions">
    <div class="progress">
      <mat-progress-bar *ngIf="importing"
        [color]="'accent'" 
        [mode]="cancelled ? 'indeterminate' : 'determinate'" 
        [value]="progress">
      </mat-progress-bar>
      <div class="status" *ngIf="status" [ngClass]="{'md-error':status && error && !current}">
        <span>{{status}}</span>
        <mat-icon class="notranslate" *ngIf="status && error && !current">close</mat-icon>
      </div>
    </div>
    <div class="rules">
      <mat-checkbox class="logging" [(ngModel)]="checkRules" [disabled]="importing">Check Rules</mat-checkbox>
    </div>
    <div class="rules">
      <mat-checkbox class="logging" [(ngModel)]="checkPath" [disabled]="importing">Include file path as tag</mat-checkbox>
    </div>
    <div class="rules">
      <mat-checkbox class="logging" [(ngModel)]="checkName" [disabled]="importing">Include file name as tag</mat-checkbox>
    </div>

    <div class="status">
      <span>Enter a tag for the files</span>
    </div>
  <div class="rule-name">
    <mat-form-field>
        <input matInput type="text" placeholder="Enter a tag..." color="primary" [disabled]="importing" (change)="changeTag($event.target.value)" >
    </mat-form-field>
</div>

    <button class="md-button md-button-outstanding"
            [disabled]="!canStart()" 
            (click)="start()">
            Start
    </button>
    <button class="md-button"
            *ngIf="importing" 
            (click)="cancel()">
            Cancel
    </button>
    <button class="md-button"
            *ngIf="!importing" 
            (click)="close()">
            Close
    </button>

  </div>

</div>
