<div class="md-popup share">
  
  <div class="md-popup-header">
    <!-- <mat-icon [matTooltip]="busy ? '' : 'Close'" [ngClass]="{'disabled':busy}" (click)="close()">close</mat-icon> -->
    <div class="title" [innerText]="getTitle()"></div>
  </div>

  <div class="spinner" *ngIf="initializing">
    <mat-progress-spinner diameter="32" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="md-popup-content share-content" *ngIf="!initializing">

    <div class="subtitle">Select file formats to share:</div> 

    <div class="file-formats" *ngIf="exportOptions">
      <!-- <mat-checkbox *ngIf="!libraryFontsIsEnabled" [(ngModel)]="exportOptions.zipFile" [ngClass]="{'selected': exportOptions.zipFile == true}">ZIP</mat-checkbox> -->
      <mat-checkbox [(ngModel)]="exportOptions.outlineFile" [ngClass]="{'selected': exportOptions.outlineFile == true}">Ai Outline</mat-checkbox>
      <mat-checkbox [(ngModel)]="exportOptions.pdfFile" [ngClass]="{'selected': exportOptions.pdfFile == true}">PDF</mat-checkbox>
    </div>
  </div>  

  <div class="share-utilities" *ngIf="!initializing">
    <mat-progress-bar class="progressbar"
      *ngIf="busy || downloadProgress > 0"
      color="accent"
      mode="determinate"
      [value]="downloadProgress">
    </mat-progress-bar>
    <div class="status" *ngIf="status" [innerText]="status"></div>

    <div class="md-warning" *ngIf="error">
      <span>{{error}}</span>
      <mat-icon class="notranslate">warning</mat-icon>
    </div>
  </div>

  <div class="md-popup-actions share-feedback" *ngIf="feedbackMsg">
    <div class="text">{{feedbackMsg}}</div>
    <div class="drawing">
      <mat-icon class="notranslate computer">computer</mat-icon>
      <mat-icon class="notranslate file-1">insert_drive_file</mat-icon>
      <mat-icon class="notranslate file-2">insert_drive_file</mat-icon>
    </div>
    <button class="md-button" (click)="close()">Close</button>
  </div>

  <div class="md-popup-actions share-buttons" *ngIf="!initializing">
    <button class="md-button">
      <span *ngIf="!busy" (click)="close()">Cancel</span>
      <span *ngIf="busy" (click)="cancel()">Cancel</span>
    </button>  
    <button class="md-button md-button-outstanding" (click)="shareFiles()" [disabled]="!canShare()">Share</button>
  </div>  

</div>