<div class="identity">

  <mat-progress-spinner *ngIf="loading" class="spinner" diameter="48"  color="accent" mode="indeterminate"></mat-progress-spinner>

  <div class="identity-header" *ngIf="!loading && identity && identity.user">
    <div class="user">
      <mat-icon class="notranslate">person</mat-icon>
       <div id='HelloUser'>Hello {{identity.user.username}}!</div>
       <div class="company-role"> {{getCompanyRoleString()}}</div>
      </div>
  </div>

  <div class="identity-content">

    <!--Anonymous user-->
    <div *ngIf="!loading && (!identity || !identity.user)">
      <div class="user"><mat-icon class="notranslate">person</mat-icon>Login or Sign Up</div>
      <login class="login"></login>
      <mat-expansion-panel class="register" #registerpanel>
        <mat-expansion-panel-header>
            <span class="md-paneltitle" e2e="createAccountPanel">Create account</span>
        </mat-expansion-panel-header>
        <register></register>
      </mat-expansion-panel>
    </div>

    <!--Logged user-->
    <div *ngIf="!loading && identity && identity.user">
      <company-selector
            class="company"
            *ngIf="!loading && identity && identity.user"
            [identity]="identity"
            [showIcons]="showIcons && isSuperUser()">
      </company-selector>
    </div>

  </div>

</div>
