import { Component, OnInit, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'lib-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  /**
   * Tooltip Usage:
   * <div [libTooltip]="templateName">I have a tooltip</div>
   * <ng-template #templateName>
   *    <div>I'm the tooltip content!</div>
   * </ng-template>
   */

  @Input() template: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  constructor()
  {
  }

  async ngOnInit() {
    if (this.template)
    {
      this.container.createEmbeddedView(this.template);
    }
    else
    {
      console.log('Tooltip without content');
    }
  }

}
