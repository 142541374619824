import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { User, EventHubService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy
{
  @Input() userNameEditable: boolean = true;
  @Input() showOldPassword: boolean = true;
  @Input() hidePasswordFields: boolean;
  @Input() existingUser: boolean;
  @Input() user: User;

  @Output() sucessRegister = new EventEmitter<boolean>();
  @Output() userChange = new EventEmitter<User>();
  @Output() enterPressed = new EventEmitter<void>();
  emailControl = new UntypedFormControl('', [Validators.email]);

  constructor(private eventHub: EventHubService)
  {
  }

  private sub;
  @ViewChild('usernamefield', { static: false }) usernamefield: ElementRef;
  ngOnInit()
  {
    this.sub = this.eventHub.toggleSideNav.subscribe(x => {
      if (x === 'Register')
      {
        setTimeout(() => {
          this.usernamefield.nativeElement.focus();
        }, 500);
      }
    });
  }

  ngOnDestroy(): void
  {
    this.sub.unsubscribe();
  }

  emit()
  {
    console.log(`User changed: ${JSON.stringify(this.user)}`);
    this.userChange.emit(this.user);
    this.sucessRegister.emit(false);
  }

  keyup(event: KeyboardEvent) {
    if (event.key === 'Enter')
    {
      this.enterPressed.emit();
    }
  }
}
