import { Component, OnInit, Inject} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { DocumentFilter, MoonDeskDocument, FeedbackService, DocumentService, ClassValue, ClassSelection, AuthService, CompanyConfigurationService, LoggingService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { AskDialogComponent } from '../_shared/ask-dialog/ask-dialog.component';


export interface ClassSelectorDialogResult
{
  filter: DocumentFilter;
  overwriteAll: boolean;
}

@Component({
  selector: 'lib-class-selector-dialog',
  templateUrl: './class-selector-dialog.component.html',
  styleUrls: ['./class-selector-dialog.component.scss']
})
export class ClassSelectorDialogComponent
{
title: string;
private doc: MoonDeskDocument;
docIds: string[];
preselection: DocumentFilter;
loading: boolean = false;

showMode: boolean;
overwriteAll: boolean = false;
disableDocType: boolean;

filter: DocumentFilter;
bulkMode: boolean;

documentChanged : boolean;
busy: boolean = false;
allowDuplicateDocs: boolean;
/**
 * Used for bulk reclassification
 */
disableRequiredValues: boolean;

constructor(public dialogRef: MatDialogRef<ClassSelectorDialogComponent>,
  private dialog: MatDialog,
  private feedbackService: FeedbackService,
  private documentService: DocumentService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private authService: AuthService,
  private configService: CompanyConfigurationService,
  private loggingService: LoggingService)
{
  // For a single doc
  this.doc = data.document;
  // For bulkchange
  this.docIds = data.docIds;
  this.showMode = data.showMode;
  this.disableDocType = data.disableDocType;
  this.title = data.title;
  this.bulkMode = data.bulkMode;
  this.disableRequiredValues = data.disableRequiredValues;
  if (!this.doc)
  {
    throw Error('document object needed for ClassSelectorDialogComponent');
  }
  if (this.bulkMode && !this.docIds)
  {
    throw Error('document Ids needed for ClassSelectorDialogComponent in bulk mode');
  }
  this.init();
}

async init(): Promise<void>
{
  // Just in case
  await this.refreshCompanyClasses();

  let docTypeIds: string[];
  if (this.doc.documentType) {
    docTypeIds = [this.doc.documentType.id];
  }

  this.preselection = {
    companyId: this.doc.companyId,
    docTypeIds: docTypeIds,
    page: -1,
    skipPreviewUrls: true,
    classSelections: this.mapClassSelections(this.doc.classValues)
  };

  this.getAllowDuplicate();
}

private async refreshCompanyClasses(): Promise<void>
{
  try
  {
    this.busy = true;
    const identity = this.authService.getCurrentIdentity();
    if (!identity.company)
    {
      return;
    }
    identity.company.classes = await this.configService.getClasses(false);
  }
  catch(ex)
  {
    this.loggingService.logException(ex);
  }
  finally
  {
    this.busy = false;
  }
}

private mapClassSelections(classValues: ClassValue[])
{
  const classSelections: ClassSelection[] = [];
  for (const cv of classValues)
  {
    const existentClassSelection = _.find(classSelections, classSelection => classSelection.classId == cv.classId);
    if (existentClassSelection)
    {
      existentClassSelection.classValueIds.push(cv.id);
    }
    else
    {
      classSelections.push({classId: cv.classId, classValueIds: [cv.id], blank: false});
    }
  }
  return classSelections;
}

filtersChanged(filter: DocumentFilter)
{
  if (this.loading)
  {
    return;
  }
  this.filter = filter;
}

canSave()
{
  return this.filter && this.filter.areFiltersValid;
}

async getAllowDuplicate () 
{
  try 
  {
    this.busy = true;
    const id = this.authService.getCurrentIdentity();
    this.allowDuplicateDocs = id.company.configuration?.allowDuplicatedDocuments;
  }
  catch (err) 
  {
    this.feedbackService.notifyError('Error loading allow duplicate', err);
  }
  finally 
  {
    this.busy = false;
  }
}

async changeInfos()
{
  this.loading = true;
  const classValuesIds: string[] = [];
  this.filter.classSelections.forEach(cs =>
  {
    cs.classValueIds.forEach(cvId =>
    {
      classValuesIds.push(cvId);
    });
  });

  const duplicates = await this.documentService.getDocumentsNamesByClassification(this.filter.docTypeIds[0], classValuesIds);
  let duplicatesDocs =  "This document is duplicated:\n" + duplicates.join('\n');
  
  let question: string;
  if (!this.allowDuplicateDocs && duplicates.length >0 ) {
    this.feedbackService.notifyMessage('This document is duplicated, it is not allowed in this workspace');
    this.loading = false;
    return
  }
  else if (this.allowDuplicateDocs && duplicates.length > 0)
  {
    const questionDuplicated = this.bulkMode ?
    duplicatesDocs + '\n' + 'Do you really want to add the classifications to all the selected documents?' :
    duplicatesDocs + '\n' + 'Do you really want to change the classifications of this document?';
    question = questionDuplicated;
  }
  else{
    const questionNormal = this.bulkMode ? 'Do you really want to add the classifications to all the selected documents?' : 'Do you really want to change the classifications of this document?';
    question = questionNormal;
  }
  
  const answer: boolean = await this.ask(question);
  if (answer)
  {
    try
    {
      if (this.bulkMode)
      {
        await this.documentService.changeClassValuesBulk(this.docIds, this.filter, false);
      }
      else
      {
        await this.documentService.changeClassValues(this.doc, this.filter);
        this.feedbackService.notifyMessage('Document classifiers updated');
        this.documentChanged = true;
      }
      this.dialogRef.close(true);
    }
    catch (err)
    {
      this.feedbackService.notifyError(`Error changing document infos`, err);
    }
  }
  else
  {
    this.feedbackService.notifyMessage('Cancelled by user');
  }
  this.loading = false;
}

private ask (question: string): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const dialogRef = this.dialog.open(AskDialogComponent, {
      width: '400px',
      minWidth: '260px',
      data: {
        title: 'Please confirm',
        question: question
      }
    });
    dialogRef.afterClosed().subscribe(result => resolve(result && result == 'yes'));
  });
}

async close()
{
  if (!this.loading)
  {
    this.dialogRef.close(this.documentChanged);
  }
}

classValueUpdated()
{
  this.documentChanged = true;
}

}

