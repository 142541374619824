<div class="md-popup login">

  <div *ngIf="busy" class="md-spinner">
    <mat-progress-spinner class="spinner" diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="md-popup-content" *ngIf="!showPasswordRecovery && !waitingForOauth">
      <mat-form-field>
        <input matInput type="text" name="usernameOrEmail" placeholder="Username / Email" [(ngModel)]="username">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password"  (keyup)="keyup($event)" name="passwordFieldLogIn" placeholder="Password" (enter)="signIn()" [(ngModel)]="password">
      </mat-form-field>
  </div>

  <div class="md-popup-actions login-buttons" *ngIf="!showPasswordRecovery && !waitingForOauth">
    <div class="login-forgot">
      <button class="md-button" name="loginButtonForm" [disabled]="busy" (click)="signIn()">Login</button>
      <button class="resetpass" [disabled]="busy" (click)="togglePasswordRecovery()">Forgot password?</button>
    </div>
    <div>
      <img class="oauth-button" 
              [src]="isDark ? './assets/ms_signin_dark_short.png' : './assets/ms_signin_light_short.png'" 
              (click)="signInMicrosoft()"
              matTooltip="Sign In with Microsoft">
      </div>
  </div>

  <div class="md-popup-content oauth" *ngIf="waitingForOauth">
    <div class="text">
      Waiting for Sign In, please check your Browser... 
    </div>
    <div class="actions">
      <mat-progress-spinner class="spinner" diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
      <button class="md-button cancel" name="loginButtonForm" [disabled]="busy" (click)="cancelOauthLogin()">Cancel Login</button>
    </div>
  </div>

  <div class="md-popup-content" *ngIf="showPasswordRecovery && !waitingForOauth">
    <div class="title">Recover your password</div>
    <mat-form-field>
      <input matInput type="email" placeholder="Username / Email" [(ngModel)]="username">
      <mat-hint>Enter the username or the email address associated to your account. We will send you a confirmation email with a link to finish the process.</mat-hint>
    </mat-form-field>
  </div>

  <div class="md-popup-actions recovery-actions" *ngIf="showPasswordRecovery && !waitingForOauth">
      <button class="md-button" (click)="togglePasswordRecovery()">Cancel</button>
      <button class="md-button md-button-outstanding" (click)="forgotPassword()">Send</button>
  </div>

  <div name="errorMessageLogin" class="md-popup-actions--error">{{error}}</div>

</div>
