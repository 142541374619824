import { DocumentTag } from "../../public_api";

export interface LibraryDocForExtensionDto
{
    companyId: string;
    documentId: string;
    versionId: string;
    isArchived?: boolean;
    fileType: string;
    checksum: string;
    documentName: string;
    documentSize: number;
    tags: DocumentTag[];
    type: LibraryTypeEnum;
    previewUrl?: string;
}

export enum LibraryTypeEnum
{
    Image,
    Font
}
