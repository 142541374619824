import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { MoonTask, TasksService, MoonDeskDocument, FeedbackService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'add-doc-to-task-dialog',
  templateUrl: './add-doc-to-task-dialog.component.html',
  styleUrls: ['./add-doc-to-task-dialog.component.scss']
})
export class AddDocToTaskDialogComponent {

  busy: boolean;
  task: MoonTask;
  document: MoonDeskDocument;

  constructor(public dialogRef: MatDialogRef<AddDocToTaskDialogComponent>,
              private taskService: TasksService,
              private feedbackService: FeedbackService,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {
    this.task = data.task;
    this.document = data.document;
  }

  async addDocToTask()
  {
    try
    {
      this.busy = true;
      const updatedTask = await this.taskService.addSubTask(this.task.id, this.document.id, 'ToDo');
      this.task = updatedTask;

      this.feedbackService.notifyMessage('Document added to the current task');
      this.busy = false;
      this.close(true);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error adding document to task', err);
    }
    this.busy = false;
  }

  close(taskAdded: boolean)
  {
    if (!this.busy)
    {
      this.dialogRef.close(taskAdded);
    }
  }

}
