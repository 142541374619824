import { Component, Input } from '@angular/core';
import {
  ContentGroup,
  ContentLibraryRuleConfiguration,
  ContentTextRuleConfiguration,
  DocumentContent,
  DocumentService,
  DocumentVersion,
  FeedbackService,
  MoonDeskDocument,
  RuleConfiguration,
  RuleIDs,
  TasksService,
  TextsFormattingService,
  TranslationService,
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { IllustratorService } from '../../../services/illustrator.service';
import { SaveDocumentService } from '../../work/save-document/save-document.service';
import { RuleHelperService } from '../../../services/rule-helper.service';
import { RulesExecutionService } from '../../../services/rules/rules-execution.service';
import { DocumentContentsService } from '../../../services/document-contents.service';

@Component({
  selector: 'app-document-contents',
  templateUrl: './document-contents.component.html',
  styleUrls: ['./document-contents.component.scss']
})

export class DocumentContentsComponent {


  contentConfigurationReadOnly: Object =
  {
    'toolbar': false,
    'spellcheck': false,
    'askBeforePasteHTML': false,
    'askBeforePasteFromWord': false,
    'readonly': true,
    'showCharsCounter': false,
    'showWordsCounter': false,
    'showXPathInStatusbar': false,
  };

  @Input() contentGroups: ContentGroup[];
  @Input() set workspaceRules(rules: RuleConfiguration[])
  {
    this.mapRulesToDocContent(rules);
  }
  @Input() currentDocument: MoonDeskDocument;

  constructor(private illService: IllustratorService,
              private feedbackService: FeedbackService,
              private saveDocService: SaveDocumentService,
              private rulesExecutionService: RulesExecutionService,
              private ruleHelperService: RuleHelperService,
              private tasksService: TasksService,
              private docService: DocumentService,
              private docContentsService: DocumentContentsService,
              private translationService: TranslationService,
              private textsFormattingService: TextsFormattingService
              )
  { }

  private async mapRulesToDocContent(ruleConfigs: RuleConfiguration[])
  {
    this.contentGroups = this.contentGroups?.filter(g => !g.isWorkspaceRule);
    if (!ruleConfigs || ruleConfigs.length === 0)
    {
      return;
    }
    const contentRulesConfig = ruleConfigs.filter(rule => rule.ruleID === RuleIDs.TEXT_CONTENT || rule.ruleID === RuleIDs.LIBRARY_CONTENT)
    for (const contentRuleConfig of contentRulesConfig)
    {
      if (contentRuleConfig.ruleID === RuleIDs.TEXT_CONTENT)
      {
        const mappedDocContents: DocumentContent[] = [];

        const config: ContentTextRuleConfiguration = JSON.parse(contentRuleConfig.ruleOptions);
        if (config.checkCondition === 'notExist')
        {
          continue;
        }
        const textContents = this.ruleHelperService.parseTextContents(config.richCheckText);
        for (let i = 0; i < textContents.length; i++)
        {
          const tc = textContents[i];
          const mappedDocContent: DocumentContent =
          {
            ruleConfiguration: contentRuleConfig,
            type: 'Text',
            checkSize: config.textSize > 0,
            content: tc.text,
            previousRuleCondition: i > 0 ? (tc.logicalConector === '||' ? 'Or' : 'And') : null,
            wildCard: tc.wildCard,
            description: this.textsFormattingService.removeHtml(contentRuleConfig.description),
            minSize: config.textSize,
            maxSize: config.maximumTextSize
          };
          mappedDocContents.push(mappedDocContent);
        }

        const ruleGroup: ContentGroup =
        {
          name: config.ruleDescription + ' (Workspace Rule)',
          documentContents: mappedDocContents
        };

        this.contentGroups.push(ruleGroup);
      }
      else if (contentRuleConfig.ruleID === RuleIDs.LIBRARY_CONTENT)
      {
        const mappedDocContents: DocumentContent[] = [];
        const config: ContentLibraryRuleConfiguration = JSON.parse(contentRuleConfig.ruleOptions);
        for (let i = 0; i < config.docVersions.length; i++)
        {
          const libDocVersion = config.docVersions[i];
          const docVersion: DocumentVersion =
          {
            documentTags: [],
            fieldValues: [],
            fileType: '',
            metadata: null,
            id: libDocVersion.versionId,
            imageUrl: await this.docService.getSpecificFileUrl(libDocVersion.versionId, 'previewImage.png'),
          };
          const mappedDocContent: DocumentContent =
          {
            ruleConfiguration: contentRuleConfig,
            type: 'LibraryElement',
            description: contentRuleConfig.description,
            documentVersionId: libDocVersion.versionId,
            documentVersion: docVersion,
            libContentLogicalOperatorSize: libDocVersion.logicalOperatorSize,
            libContentWidth: libDocVersion.width,
            libContentHeight: libDocVersion.height,
            previousRuleCondition: i > 0 ? (config.logicalOperator === 'or' ? 'Or' : 'And') : null
          };
          mappedDocContents.push(mappedDocContent);
        }

        const ruleGroup: ContentGroup =
        {
          name: config.ruleDescription + ' (Workspace Rule)',
          documentContents: mappedDocContents
        }

        this.contentGroups.push(ruleGroup);
      }

    }
  }

  async linkTextContent(docContent: DocumentContent)
  {
    if (docContent.type !== 'Text')
    {
      console.log('NO TEXT CONTENT!');
      return;
    }
  }

  async linkContent(docContent: DocumentContent)
  {
    await this.docContentsService.addTextContent(docContent.content, docContent.minSize);
  }

  async linkLibContent(docContent: DocumentContent)
  {
    if (docContent.type !== 'LibraryElement')
    {
      console.log('NO LIBRARY CONTENT!');
    }
    if (docContent.busy)
    {
      return;
    }
    try
    {
      docContent.busy = true;
      await this.docContentsService.addLibContent(docContent.documentVersionId);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error placing content', err);
    }
    finally
    {
      docContent.busy = false;
    }
  }

  getConcatedTags(documentVersion: DocumentVersion)
  {
    let tags = '';
    if (documentVersion?.documentTags?.length > 0)
    {
      for (const tag of documentVersion.documentTags)
      {
        tags = tags.concat('(' + tag.value + ') ');
      }
    }
    return tags;
  }

  getImgSizeControlString(docContent: DocumentContent): string
  {
    if (docContent.type !== 'LibraryElement' || (docContent.libContentHeight === 0 && docContent.libContentWidth === 0))
    {
      return '';
    }

    let sizeLogicalOperator: string;
    switch (docContent.libContentLogicalOperatorSize)
    {
      case '<':
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.smallerThan');
        break;
      case '>':
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.biggerThan');
        break;
      default: // =
        sizeLogicalOperator = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.equalTo');
        break;
    }

    const sizeConditions: string[] = [];
    if (docContent.libContentHeight > 0)
    {
      const height = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.height');
      sizeConditions.push(`- ${height} ${sizeLogicalOperator} ${docContent.libContentHeight}mm`);
    }
    if (docContent.libContentWidth > 0)
    {
      const width = this.translationService.getTranslation('lid.ext.pages._shared.document-contents.width');
      sizeConditions.push(`- ${width} ${sizeLogicalOperator} ${docContent.libContentWidth}mm`);
    }

    return sizeConditions.join('\n');
  }

  async checkRule(docContent: DocumentContent)
  {
    if (docContent.busy)
    {
      return;
    }
    if (!this.currentDocument)
    {
      this.feedbackService.notifyMessage('Please open a document first');
      return;
    }
    if (!this.currentDocument.documentType)
    {
      this.feedbackService.notifyMessage('Please select a document type first');
      return;
    }
    try
    {
      docContent.busy = true;
      const docItems = await this.illService.getVisibleItems(this.currentDocument.workingCopy);
      const version = await this.saveDocService.createDocumentVersion(this.currentDocument, docItems, this.currentDocument.workingCopy);
      version.fullTextCharacters = await this.illService.getAllTextWithBoldReferences(this.currentDocument.workingCopy);
      const ruleConfiguration = docContent.ruleConfiguration ?? this.tasksService.getRulesFromContent([docContent], '')[0];
      const ruleResult = await this.rulesExecutionService.runRule(
        ruleConfiguration,
        this.currentDocument,
        version,
        this.currentDocument.workingCopy
      );
      await this.ruleHelperService.showResultsDialog([ruleResult], false, this.currentDocument, [ruleConfiguration]);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error running rule', err);
    }
    finally
    {
      docContent.busy = false;
    }
  }

  toggleExpandedView(docContent: DocumentContent)
  {
    const expanded = !docContent.expandedView;
    this.contentGroups?.forEach(cg => cg.documentContents?.forEach(dc => dc.expandedView = false));
    docContent.expandedView = expanded;
  }
}
