<div class="tasks-list">

  <div class="task-content" *ngIf="identity && (identity.reviewer || identity.company)">
    <span class="task-head-title">TASKS</span>
    <div class="task-filter" (keyup.enter)="searchTasks()">
      <app-task-search 
        [busy]="busy"
        (search)="searchTasks()"
        [companyUsers]="companyUsers"
        [showMyTasksSwitch]="showMyTasksSwitch"
        [filter]="taskFilter"
        (filterChange)="updateTaskFilter($event)">
      </app-task-search>
    </div>
    
    <div class="result-info" *ngIf="!busy && !dirtyFilter">
      <span class="result-count">{{totalResults + ' ' + (totalResults === 1 ? 'result' : 'results')}}</span>
      <div class="refresh-button" (click)="searchTasks()">
        <div class="content-center">
          <mat-icon class="refresh-icon">refresh</mat-icon>
          <span class="title">Refresh</span>
        </div>
      </div>
    </div>
    <div class="tasks" *ngIf="tasks">

      <div class="notask" *ngIf="noResultText && !busy && tasks.length === 0">{{noResultText}}</div>
      <mat-progress-spinner *ngIf="busy" diameter="24" class="spinner" color="accent" mode="indeterminate">
      </mat-progress-spinner>

      <div class="list-header" *ngIf="tasks.length > 0">
        <!-- <div class="date header-item">
          <span class="title">Creation</span>
        </div> -->
        <div class="taskNumber header-item ">
          <span class="title">Nº</span>
        </div>
        <div class="description header-item">
          <span class="title task-title">Name</span>
        </div>
        <div class="assigned header-item">
          <span class="title">Assigned</span>
        </div>

        <div class="due-date header-item">
          <span class="title">Exp.</span>
        </div>

        <div class="state header-item">
          <span class="title">Status</span>
        </div>

        <div class="options header-item"></div>
      </div>

      <div class="scroll">
        <div class="list-loop line-task" *ngFor="let task of tasks">

          <div class="task">

            <!-- <div class="date" [innerText]="getDate(task)"
              (click)="toggleSubTasks(task)"></div> -->

            <div class="number">
              {{task.taskNumber}}</div>

            <div class="description">
              <mat-icon class="expand-icon" [matTooltip]="!task.showSubTasks ? 'Show task documents and templates..' : 'See less'"
              (click)="toggleSubTasks(task)"
              [ngClass]="{'rotate-up': !task.showSubTasks, 'rotate-down': task.showSubTasks}">
                arrow_drop_down
              </mat-icon>
              <div class="task-name uppercase" [matTooltip]="task.name" [innerText]="task.name"
                (click)="selectTask(task.taskId)"></div>
            </div>

            <div class="assigned">
              <div class="assigned-pill" [matTooltip]="task.assignedName">{{getUsernameInitials(task.assignedName)}}</div>
            </div>

            <div class="due-date"
              (click)="selectTask(task.taskId)">
              <div class="date-pill" [ngClass]="{'expirated-date' : task.isExpired}">
                <span class="pill-text">{{task.expirationTimestampUtc | date:'dd/MM/yy'}}</span>
              </div>
            </div>

            <div class="state"
              [matTooltip]="getFormattedTaskStatus(task.status)">
              <div class="status-pill"
                [ngClass]="{
                  'pill-inDesign': task.status === taskStatusEnum.ToDo,
                  'pill-inReview': task.status === taskStatusEnum.InReview,
                  'pill-done': task.status === taskStatusEnum.Done
                }">
                <span class="pill-text">{{getFormattedTaskStatus(task.status)}}</span>
              </div>
            </div>


            <div class="options">
              <div class="edit" *ngIf="!isReviewMode()" (click)="edit(task.taskId)" [matTooltip]="'Edit'"
                [matTooltipPosition]="'above'">
                <mat-icon class="edit-icon notranslate">create</mat-icon>
                <span>EDIT</span>
              </div>
            </div>

          </div>

          <div *ngIf="task.showSubTasks" [@slideInOut]>

            <ng-container *ngIf="task.subTasks?.length > 0">
              <div class="title-division-bar">Documents</div>
              <div class="subtask" *ngFor="let subTask of task.subTasks">
                <div class="description"
                  (click)="edit(task.taskId, subTask.documentId)">

                  <div class="subtask-name uppercase" [matTooltip]="getSubTaskName(subTask)">
                    {{getSubTaskName(subTask)}}
                  </div>
                </div>
                <div class="moon-number">
                  <div class="moon-pill">
                    {{subTask.documentMoonNumber}}
                  </div>
                </div>
                <div class="status">
                  <div class="status-text"
                    [ngClass]="{'text-inDesign' : (subTask.status === 'ToDo') , 'text-inReview' : (subTask.status === 'InReview') , 'text-done' : subTask.status === 'Done'}">
                    <div class="center">
                      <mat-icon class="state-icon pending" *ngIf="subTask.status === 'ToDo'">pending</mat-icon>
                      <mat-icon class="state-icon review" *ngIf="subTask.status === 'InReview'">preview</mat-icon>
                      <mat-icon class="state-icon verified" *ngIf="subTask.status === 'Done'">verified</mat-icon>
                      <span class="pill-text">{{getFormattedSubTaskStatus(subTask.status)}}</span>
                    </div>
                    
                  </div>
                </div>
                <div class="messages">
                  <div class="messages-status" (click)="selectSubTask(task.taskId, subTask.subTaskId)">
                    <div class="no-msgs" matTooltip="No messages"
                      *ngIf="subTask.latestVersionMessagesCount === 0">
                      <mat-icon class="notranslate">chat_bubble</mat-icon>
                    </div>
                    <div class="readed-msgs" matTooltip="There is not recent messages"
                      *ngIf="subTask.latestVersionMessagesCount > 0 && !subTask.hasRecentMessages">
                      <mat-icon class="notranslate">chat_bubble</mat-icon>
                    </div>
                    <div class="unreaded-msgs" matTooltip="Recent messages" *ngIf="subTask.latestVersionMessagesCount > 0 && subTask.hasRecentMessages">
                      <mat-icon class="notranslate">chat_bubble</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="subtask">
              <div class="description">
                <div class="subtask-name" [matMenuTriggerFor]="menu">
                  <span class="md-textbutton add-button">
                    <mat-icon class="notranslate">add</mat-icon>
                    Add document
                  </span>
                </div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="importToTask(task.taskId, 'new')">New Document</button>
                  <button mat-menu-item (click)="importToTask(task.taskId, 'existing')">Existing Document</button>
                </mat-menu>
              </div>
            </div>



            <ng-container *ngIf="task.templates?.length > 0">
              <div class="title-division-bar">Templates</div>
              <div class="subtask" *ngFor="let taskDocTemplate of task.templates">
                <div class="description" (click)="importToTask(task.taskId, 'new', taskDocTemplate.documentId)">
                  <div class="subtask-name template-name" [matTooltip]="getTemplateName(taskDocTemplate)">
                    <span>
                      {{getTemplateName(taskDocTemplate)}}
                    </span>
                  </div>
                </div>
                <div class="moon-number">
                  <div class="moon-pill-template">
                    {{'T ' + taskDocTemplate.documentMoonNumber}}
                  </div>
                </div>
                <div class="status"></div>
                <div class="messages"></div>
              </div>
            </ng-container>
          </div>
        </div>
        <br>

      </div>
      <button class="show-more-btn" mat-button
        *ngIf="!busy && currentPage < pageCount && tasks.length > 0"
        (click)="searchPageTasks()">
        Show more...
      </button>
    </div>

  </div>

</div>