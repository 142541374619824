<div class="frame">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="over" position="end" fixedInViewport [(opened)]="opened" class="sidenav">
      <sidenav-content 
        (toggleSideNav)="toggleSideNav()" 
        (logoutEvent)="logout()"
        (settingsClicked)="settings()"
        [identity]="identity"
        [identityLoaded]="hasIdentity()"
        [showIcons]="false"
        [showSettings]="true">
      </sidenav-content>
    </mat-sidenav>

   <mat-sidenav-content>

      <div class="appbar">

        <div class="appbar-tabs">
          <ng-container *ngIf="hasPermissionToUploadDocs()">
            <div class="tab" 
                [ngClass]="{'active':selectedIndex==0, 'hover':hover=='mylabels'&&selectedIndex!=0}" 
                (mouseenter)="hover='mylabels'" (mouseleave)="hover=undefined"
                id="search-tab"
                (click)="goToMyLabels()">
                Search
            </div>
            <div class="tab"
                e2e="importTab"
                [ngClass]="{'active':selectedIndex==1, 'hover':hover=='import'&&selectedIndex!=1}" 
                (mouseenter)="hover='import'" (mouseleave)="hover=undefined"
                (click)="goToImport()">
                Import
            </div>
            <div class="tab"
                [ngClass]="{'active':selectedIndex==2, 'hover':hover=='edit'&&selectedIndex!=2}" 
                (mouseenter)="hover='edit'" (mouseleave)="hover=undefined"
                (click)="goToEdit()">
                Edit
            </div>
            <div class="tab" e2e="e2e-tasks-tab" *ngIf="showTask()"
                [ngClass]="{'active':selectedIndex==3, 'hover':hover=='tasks'&&selectedIndex!=3}" 
                (mouseenter)="hover='tasks'" (mouseleave)="hover=undefined"
                (click)="goToTasks()">
                Tasks
            </div>
            <div class="tab"
                [ngClass]="{'active':selectedIndex==4, 'hover':hover=='library'&&selectedIndex!=4}" 
                (mouseenter)="hover='library'" (mouseleave)="hover=undefined"
                (click)="goToLibrary()">
                Resources
            </div>
            <div class="tab"
              [ngClass]="{'active':selectedIndex==5, 'hover':hover=='rules'&&selectedIndex!=5}" 
              (mouseenter)="hover='rules'" (mouseleave)="hover=undefined"
              (click)="goToRules()">
              Rules
            </div>
          </ng-container>
        </div>

        <div class="appbar-icons">
          <!-- <mat-icon 
            class="notranslate"
            matTooltip="Settings"
            (click)="settings()">
            settings
          </mat-icon> -->
          <mat-icon 
            class="notranslate"
            matTooltip="Website"
            (click)="website()">
            home
          </mat-icon>
          <mat-icon
          class="notranslate"
          matTooltip="Support"
          (click)="support()"
          >
          headset_mic
          </mat-icon
          >
          <notification-bell class="bell" e2e="notification-bell-icon" notificationPopupClass="notificationpopup"></notification-bell>
          <mat-icon 
            class="notranslate"
            e2e="menuIcon"
            *ngIf="!loading" 
            [ngClass]="{'active':!identityComplete }"
            [matTooltip]="accountTooltip"
            (click)="toggleSideNav()">
            menu
          </mat-icon>
        </div>

      </div>

      <div *ngIf="loading" class="loading">
        <mat-progress-spinner class="spinner" diameter="48" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
      </div> 

      <div class="content" *ngIf="!connectionError && !loading">

        <div class="subpage" *ngIf="!identity || !identity.user || !identity.company">
          <app-welcome></app-welcome>
        </div>

        <ng-container *ngIf="identity && identity.company">
          <div class="subpage" *ngIf="hasPermissionToUploadDocs()">
            <search [hidden]="loading || !identityComplete || selectedIndex != 0"></search>
            <work id="workImport" workMode="Import" [hidden]="loading || !identityComplete || (selectedIndex != 1)"></work>
            <work id="workEdit" workMode="Edit" [hidden]="loading || !identityComplete || selectedIndex != 2"></work>
            <tasks-list *ngIf="showTask()" [hidden]="loading || !identityComplete || selectedIndex != 3"></tasks-list>
            <app-library [hidden]="loading || !identityComplete || selectedIndex != 4"></app-library>
            <app-rules [hidden]="loading || !identityComplete || selectedIndex != 5"></app-rules>
          </div>
  
          <div class="not-allowed" *ngIf="!hasPermissionToUploadDocs()">
            <div class="not-allowed-title">
              You do not have permissions to edit documents in this workspace:
            </div>
            <div class="not-allowed-info">
              <span class="info">- Ask your administrator to grant you the necessary permissions</span>
              <span class="info">- <span class="link-button" (click)="toggleSideNav()">Select another workspace</span></span>
            </div>
            <div class="not-allowed-refresh">
              If your permissions have changed recently <span class="link-button" (click)="refresh()">click here to refresh</span>.
            </div>
          </div>
        </ng-container>

      </div>

      <div class="connection-error" *ngIf="!loading && connectionError">
        <div class="error-title">
          Error al conectarse con el servidor
        </div>
        <div class="error-info">
          <span class="info">Por favor, compruebe su conexión e <span class="link-button" (click)="ngOnInit()">inténtelo de nuevo</span>.</span>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>
  
</div>