
<div class="welcome">

  <div class="header">
    <img class="logo" [src]="'./assets/logo.png'">

    <div class="greeting" e2e="welcome-head">Welcome to MoonDesk, we are glad to have you on board!</div>
  </div>

  <div class="intro">
    <div class="title" e2e="title-msg">Create & Manage technical labels easily</div>
    <div class="text" e2e="sub-title">MoonDesk is a tool that helps you automate designs, creating and updating labels quickly, considerably improving your productivity on a day-to-day basis.</div>
  </div>

  <div class="start">
    <div class="login">
      <div class="title">Getting Started</div>  
      <div class="text">In order to start please sit back and...</div>
      <a class="link" e2e="login-signup-button" (click)="openSideNav()">Login or Sign Up</a>
    </div>
    <div class="manual"> 
      <div class="text">If you have any doubts check out our Documentation</div>
      <button class="md-textbutton" e2e="tutorial-button" (click)="tutorial()">
        <mat-icon>open_in_new</mat-icon>  
        <div>User Manual</div>
      </button>  
    </div>   
  </div>

</div>
  