<div class="md-popup picviewer">

  <!-- TITLE -->
  <div class="md-popup-header">
    <mat-icon matTooltip="Close" id="close-icon-picture-viewer" (click)="close()">close</mat-icon>
    <div class="title">Document versions</div>
  </div>

  <div class="md-popup-content picviewer-content">

    <!-- IMAGE -->
    <div class="picture" *ngIf="!loading && size==='fit'">
      <image-zoom  *ngIf="currentVersion && currentVersion.imageUrl"  [src]="currentVersion.imageUrl" [srcToCompare]="getPreviousImageURL()"></image-zoom>
    </div>

    <!-- INFORMATION -->
    <div class="info" *ngIf="!loading">
      <!-- Document versions info -->
      <div class="versions">
        <div class="versions-table-header">
          <span class="column edit"></span>
          <span class="column number">V.N.</span>
          <span class="column status">STATUS</span>
          <span class="column user">USER</span>
          <span class="column rules">RULES</span>
          <span class="column tags">TAGS</span>
          <span class="column created">CREATED</span>
        </div>
        <div class="versions-table-list">
          <div class="version-row" *ngFor="let version of versions" [ngClass]="{'selected': version === currentVersion}" (click)="chooseVersion(version)">
            <!-- Edit -->
            <div class="column edit">
              <mat-icon class="notranslate"
                [id]="'version'+version.versionNumber"
                (click)="edit(version)"
                [ngClass]="{'disabled':loading}"
                matTooltip="Edit this version" matTooltipPosition="above">
                edit
              </mat-icon>
            </div>
            <!-- Version Number -->
            <div class="column number">
              <span class="text center">{{version.versionNumber}}.{{version.minorVersionNumber}}</span>
            </div>
            <!-- Status -->
            <div class="column status">
              <span class="text" [matTooltip]="getStatusTooltip(version)" matTooltipPosition="above">
                <span *ngIf="version.status === 'Draft'">Draft</span>
                <span *ngIf="version.status === 'Approved'">Approved</span>
              </span>
            </div>
            <!-- User-->
            <div class="column user">
              <span class="text">{{version.creator}}</span>
            </div>
            <!-- Rules result -->
            <div class="column rules">
              <span>
                <mat-icon class="notranslate" *ngIf="resultStatus(version) === 'allok'"
                          (click)="showRuleResults(version)"
                          [ngClass]="{'disabled':loading}"
                          matTooltip="Show Rule results" matTooltipPosition="above">
                          done_all
                </mat-icon>
                <mat-icon class="notranslate" *ngIf="resultStatus(version) === 'error'"
                          (click)="showRuleResults(version)"
                          [ngClass]="{'disabled':loading}"
                          matTooltip="Show Rule results" matTooltipPosition="above">
                          done
                </mat-icon>
                <mat-icon class="notranslate" *ngIf="resultStatus(version) === 'none'"
                          [ngClass]="{'disabled':loading}"
                          matTooltip="No Rule results available" matTooltipPosition="above">
                          remove
                </mat-icon>
              </span>
            </div>
            <!-- Tags -->
            <div class="column tags">
              <span class="text" [matTooltip]="getTags(version)">
                <mat-icon class="notranslate" (click)="showEditTags(version)">edit</mat-icon>
                {{getTags(version)}}
              </span>
            </div>
            <!-- Timestamp -->
            <div class="column created">
              <span class="text" [innerText]="getTimestamp(version.timestampUtc)" [matTooltip]="getTimestamp(version.timestampUtc)" matTooltipPosition="above"></span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="loading" *ngIf="loading">
      <mat-progress-spinner class="spinner" diameter="32" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>

  </div>
</div>
