import { Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { trigger, transition, query, style, stagger, animate } from '@angular/animations';
import {
  MoonDeskDocument,
  DocumentFilter,
  DocumentService,
  AuthService,
  FeedbackService,
  LoggingService,
  EventHubService,
  CompanyConfigurationService,
  DocumentChangedEvent,
  DocumentForExtensionDto
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { ClassSelectorComponent } from '../class-selector/class-selector.component';
import { PictureViewerComponent } from '../picture-viewer/picture-viewer.component';
import { AskDialogComponent } from '../_shared/ask-dialog/ask-dialog.component';
import { KeyValue } from '@angular/common';


@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0, transform: 'translateY(-100%)' }), {optional: true}),
        query(':enter', stagger('100ms', [
          style({ transform: 'translateY(100%)' }),
          animate('0.65s ease')]), {optional: true}),
      ])
    ])
  ]
})

export class SearchComponent implements OnInit, OnDestroy
{
  @Output() newTaskEvent: EventEmitter<MoonDeskDocument[]> = new EventEmitter<MoonDeskDocument[]>();

  @ViewChild('classSelector', { static: true }) classSelector: ClassSelectorComponent;

  busy: boolean;
  canceled: boolean;

  bulkInProgress = false;
  deleting: boolean;

  filter: DocumentFilter;
  /**
   * (documentName, versionId)
   */
  libraryDocumentFilter: KeyValue<string, string>;
  // response: DocumentsResponse;
  filtersSearched = false;

  documents: DocumentForExtensionDto[];
  totalResults: number = 0;
  currentPage: number = 0;
  totalPages: number = 0;

  filtersSplitSize = 20;
  resultsSplitSize = 80;
  toggleIcon = true;

  // selectedCounter = 0;

  dirtySearch: boolean;

  viewStyle: 'nopreview' | 'simple' | 'detailed' = 'detailed';
  // We need animationDelay to force animation replay
  animationDelay: boolean = true;

  constructor(private docService: DocumentService,
              private dialog: MatDialog,
              private authService: AuthService,
              private feedbackService: FeedbackService,
              private logService: LoggingService,
              private eventHubService: EventHubService,
              private configService: CompanyConfigurationService)
  {
    this.docService.documentChanged.subscribe(async (event: DocumentChangedEvent) =>
    {
      this.logService.trackTrace(`Search - Document changed...`);
      if (this.deleting === true || !this.filter)
      {
        return;
      }
      this.filtersChanged(this.filter);
      if (event.forceSearch)
      {
        this.documents = [];
      }
    });
    this.configService.classValuesChanged.subscribe(c =>
    {
      if (this.classSelector)
      {
        this.classSelector.resetFilter();
        this.dirtySearch = true;
      }
    });
    this.authService.identityChanged.subscribe(id =>
    {
      this.resetFilters();
      this.documents = [];
    });

    this.eventHubService.searchLinkedParentDocuments.subscribe((libraryDocumentPair: KeyValue<string, string>) =>
    {
      this.libraryDocumentFilter = libraryDocumentPair;
      this.resetFilters();
      setTimeout(() =>
      {
        this.search();
      }, 500);
    });
  }

  ngOnInit()
  {
    document.addEventListener('keydown', this.specialFunction);
  }

  ngOnDestroy(): void
  {
    document.removeEventListener('keydown', this.specialFunction);
  }

  private specialFunction = (e: KeyboardEvent) =>
  {
    if (e.ctrlKey && e.altKey && e.key === 'q')
    {
      // this.useNewEndpoint = !this.useNewEndpoint;
      // if(this.useNewEndpoint)
      // {
      //   this.feedbackService.notifyMessage("Using NEW query endpoint");
      // }
      // else
      // {
      //   this.feedbackService.notifyMessage("Using OLD search endpoint");
      // }
    }
  };

  filtersChanged(filters: DocumentFilter)
  {
    this.filter = filters;
    this.filtersSearched = false;
    this.dirtySearch = true;
    if (filters)
    {
      // filters.page = 1;
    }
  }

  resetFilters()
  {
    this.classSelector.resetFilter(true);
  }

  changeView(viewStyle: 'nopreview' | 'simple' | 'detailed')
  {
    this.animationDelay = false;
    setTimeout(() =>
    {
      this.animationDelay = true;
    }, 1);
    this.viewStyle = viewStyle;
  }

  checkChanged(checked: boolean)
  {
    _.forEach(this.documents, l => l.selected = checked);
  }

  showCheckbox(): number
  {
    if (this.documents && this.documents.length > 0)
    {
      const selected = _.filter(this.documents, l => l.selected).length;
      if (selected === this.documents.length)
      {
        return 1; // to show checked
      }
      if (selected > 0)
      {
        return 2; // to show indeterminate
      }
      return 0; // not checked
    }
    return -1; // to hide
  }

  selectedDocumentsLength()
  {
    if (!this.documents)
    {
      return 0;
    }
    return _.filter(this.documents, l => l.selected).length;
  }

  getDocumentAmount(): string
  {
    if (!this.filtersSearched)
    {
      return '';
    }
    if (this.totalResults === 0)
    {
      return '';
    }

    let selected = _.filter(this.documents, l => l.selected).length;
    if (selected === this.documents.length)
    {
      selected = this.totalResults;
    }


    if (this.totalResults === 1)
    {
      return `${selected} of 1 document`;
    }
    return `${selected} of ${this.totalResults} documents`;
  }

  resizeSplit(newSize?: [number, number])
  {
    if (!newSize)
    {
      // In a 350px height window we need at least 70% to see the results, so for other size we use the rule of three inverse
      const minSize = 70 * 350 / window.innerHeight;

      if (minSize > this.resultsSplitSize)
      {
        this.resultsSplitSize = minSize;
        this.filtersSplitSize = 100 - this.resultsSplitSize;
      }
    }
    else
    {
      this.filtersSplitSize = newSize[0];
      this.resultsSplitSize = newSize[1];
    }
  }

  removeLibraryFilter()
  {
    this.libraryDocumentFilter = null;
    this.search();
  }

  async search()
  {
    if (!this.busy)
    {
      this.resizeSplit();
      this.documents = [];
      this.totalResults = 0;
      await this.searchAndAddDocuments(1);
    }
  }

  searchCancel()
  {
    this.canceled = true;
    this.busy = false;
  }

  async nextPage()
  {
    if (this.currentPage >= this.totalPages)
    {
      // this was the last page already
      this.logService.trackTrace('this was the last page already');
      return;
    }
    await this.searchAndAddDocuments(this.currentPage + 1);
  }

  private async searchAndAddDocuments(page: number)
  {
    this.busy = true;
    this.canceled = false;
    try
    {
      this.filter.page = page;

      this.filter.linkedChildVersionId = this.libraryDocumentFilter?.value ?? null;
      const response = await this.docService.searchDocumentsForExtension(this.filter);

      response.result?.forEach(r => r.previewUrl = r.previewUrl ?? '../../../assets/DefaultPreview.png');

      this.totalResults = response.totalResult;
      this.currentPage = page;
      this.totalPages = response.pageCount;

      if (page === 1)
      {
        this.documents = response.result;
      }
      else
      {
        response.result.forEach(d => this.documents.push(d));
      }
      this.filtersSearched = true;
      return;
    }
    catch (err)
    {
      let msg = 'Error loading documents';
      if (err.error)
      {
        msg = err.error;
      }
      this.feedbackService.notifyError(msg, err);
    }
    finally
    {
      this.busy = false;
      this.dirtySearch = false;
    }
  }

  toggleSplit()
  {
    this.toggleIcon = !this.toggleIcon;
    this.filtersSplitSize = this.filtersSplitSize === 20 ? 55 : 20;
    this.resultsSplitSize = this.resultsSplitSize === 80 ? 45 : 80;
  }

  zoom(doc: MoonDeskDocument)
  {
    this.dialog.open(PictureViewerComponent, {
      width: '70%;',
      height: '70%',
      minWidth: '300px',
      minHeight: '300px',
      data: {doc: doc},
      disableClose: false
    });
  }

  async getSelectedDocuments(): Promise<MoonDeskDocument[]>
  {
    try
    {
      if (this.documents && this.documents.length > 0)
      {
        const selectedDocuments = this.documents.filter(d => d.selected);
        if (selectedDocuments.length < this.documents.length ||
          selectedDocuments.length === this.totalResults)
        {
          // so the selection is less than the actual list
          // or the actual list already contains all search result (no missing pages)
          // selectedDocuments.forEach;
          const result: MoonDeskDocument[] = [];
          for (const docDto of selectedDocuments)
          {
            if (docDto.fullDocument)
            {
              result.push(docDto.fullDocument);
            }
            else
            {
              const fullDocument = await this.docService.getDocument(docDto.documentId);
              docDto.fullDocument = fullDocument;
              result.push(fullDocument);
            }
          }
          return result;
        }
        else
        {
          // there are pages missing still, we have to download everything
          const tempFilter = Object.assign({}, this.filter);
          tempFilter.skipPreviewUrls = true;
          const allDocuments = await this.docService.searchDocuments(tempFilter);
          return allDocuments.result;
        }
      }
      else
      {
        return [];
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting all selected documents', err);
      return undefined;
    }
  }

  getGutterSize()
  {
    return 5;
  }

  async bulkDownload()
  {
    if (this.selectedDocumentsLength() === 0)
    {
      return;
    }
    this.logService.trackEvent('Opening download dialog');
    this.bulkInProgress = true;
    const selectedDocuments = await this.getSelectedDocuments();
    this.bulkInProgress = false;
    this.eventHubService.openDownloadDialog.emit(selectedDocuments);
  }

  async bulkEdit()
  {
    if (this.selectedDocumentsLength() === 0)
    {
      return;
    }
    this.bulkInProgress = true;
    const selectedDocuments = await this.getSelectedDocuments();
    this.bulkInProgress = false;
    _.forEach(selectedDocuments, l => l.editingVersion = l.latestVersion);
    // WORKSWITCH
    // this.eventHubService.goToEditDocuments.emit(selectedDocuments);

    // this.eventHubService.goToWork.emit(selectedDocuments);
    this.eventHubService.goToWorkEdit.emit(selectedDocuments);
  }

  async bulkDelete()
  {
    const count = this.selectedDocumentsLength();
    if (count === 0)
    {
      return;
    }
    const question = count === 1 ? 'Do you really want to delete this document' : `Do you really want to delete these ${count} documents?`;
    if (!await this.ask(question))
    {
      return;
    }
    this.bulkInProgress = true;
    const selectedDocuments = await this.getSelectedDocuments();
    try
    {
      await this.docService.removeDocuments(selectedDocuments);
      this.feedbackService.notifyMessage('Documents deleted');
      await this.search();
    }
    catch (err)
    {
      const msg = err.error ? err.error : 'Documents could not be deleted';
      this.feedbackService.notifyError(msg, err);
    }
    this.bulkInProgress = false;
  }

  async changeBulkInfos()
  {
    try
    {
      const selectedDocuments = await this.getSelectedDocuments();
      this.eventHubService.changeBulkInfos.emit(selectedDocuments);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error changing document infos', err);
    }
  }

  private ask(question: string): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'yes'));
    });
  }

}
