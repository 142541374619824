<div class="md-popup">

  <div class="md-popup-header">
    <!-- <mat-icon matTooltip="Close" (click)="onNoClick()">close</mat-icon> -->
    <div class="title">Delete</div> 
  </div>

  <div class="md-popup-content asked"> 
    <div>{{data.question}}</div> 
  </div>

  <div class="md-popup-actions">
    <button class="md-button" mat-dialog-close="yes" id="yes-button">Yes</button>
    <button class="md-button md-button-outstanding" (click)="onNoClick()" id="no-button">No</button>
  </div>

</div>