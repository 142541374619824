import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
        ExtensionService,
        SilentMessage,
        EventHubService,
        TranslationService,
        PlatformInfoProvider,
        FeedbackService
        } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
      import { NewVersionDialogComponent } from './new-version-dialog.component';
import { MoonDeskConfigService } from '../../../services/moon-desk-config.service';

const LATEST_SUPPORTED_ADOBE_VERSION = '25';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService
{

  constructor(
    private dialog: MatDialog,
    private extensionService: ExtensionService,
    private eventHubService: EventHubService,
    private moonDeskConfigService: MoonDeskConfigService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private translationService: TranslationService,
    private feedbackService: FeedbackService)
    { }

  /**
   * Collects the local version and the available version from the server, and calls the
   * function getDifferenceAndReact
   * If an exception occurs, a silent message to support is sent
   */
  async checkVersions()
  {
    try
    {
      const availableVersion = await this.extensionService.getExtensionVersion();
      const localVersion = (await this.moonDeskConfigService.getMoonDeskConfig());
      this.getDifferenceAndReact(localVersion.version, availableVersion.version);
    }
    catch (err)
    {
      const silentMessage: SilentMessage = {error: err, message: 'Error checking extension versions'};
      this.eventHubService.silentSupportMessage.emit(silentMessage);
      return;
    }
  }

  /**
   * Compares the user's version of illustrator and compares it with the latest supported
   * version of adobe (LATEST_SUPPORTED_ADOBE_VERSION), in case it is lower an error is thrown
   * to notify the user and recive a notification in teams.
   */
  async checkIllustratorVersion()
  {
    try
    {
      const frontInfo = await this.platformInfoProvider.getFrontendInfo();
      const illVersion = frontInfo.adobeVersion;
      const illMajorVersion = illVersion.split('.')[0];
      if (+illMajorVersion < +LATEST_SUPPORTED_ADOBE_VERSION)
      {
        const outdatedExtMsg =
          this.translationService.getTranslation('lid.ext.pages._shared.newVersionDialog.versionCheckService.outdatedExt');
        throw new Error(outdatedExtMsg);
      }
    }
    catch (err)
    {
      const warning =
        this.translationService.getTranslation('lid.ext.pages._shared.newVersionDialog.versionCheckService.warning');
      const msg = `${warning} ${err.message}`;
      this.feedbackService.notifyError(msg, err);
    }
  }

  /**
   * Gets the difference between the 2 versions
   * - If there is no difference, or the backend is older, nothing happens
   * - If there's a newer patch version, or a new minor version (just one number ahead),
   *   the 'NewVersionDialogComponent' is shown with the option 'allowLater = true'
   * - If theres a new mayor version, or a new minor version (2 or more numbers ahead),
   *   the 'NewVersionDialogComponent' is shown with the option 'allowLater = false'
   * @param localVersion the local version of the extension
   * @param availableVersion the version of the available extension in the server
   */
  getDifferenceAndReact(localVersion: string, availableVersion: string)
  {
    if (localVersion.split('.')[0] === '2')
    {
      return;
    }

    // To keep compatibility between 1.36 extensions and 2.x (Should be deleted in 2.0.0)
    let useFakeVersion: boolean = false;
    let mustUpdate: boolean = false;
    const splittedAvailableVer = availableVersion.split('.');

    if (localVersion.split('.')[1] === '37' && splittedAvailableVer[0] === '2')
    {
      localVersion = '1.38.0';
      useFakeVersion = true;
    }
    else if (splittedAvailableVer[0] === '2')
    {
      if (+splittedAvailableVer[1] > 0)
      {
        mustUpdate = true;
      }
      useFakeVersion = true;
    }

    const changes = this.getDifference(localVersion, useFakeVersion ? '1.39.0' : availableVersion);
    if (changes[0] > 0 ||
       (changes[0] === 0 && changes[1] > 0) ||
       (changes[0] === 0 && changes[1] === 0 && changes[2] > 0) || mustUpdate)
    {
      const allowLater = changes[0] <= 0 && changes[1] <= 1;
      this.dialog.open(NewVersionDialogComponent,
      {
        position: {top: '20px'},
        data:
        {
          availableVersion: availableVersion,
          localVersion: localVersion,
          allowLater: !mustUpdate && allowLater
        },
        disableClose: true
      });
    }
  }

  /**
   * Analyses the 2 versions, creates the difference and returns an number array with length 3
   * [0,0,1] means the available version has a patch increment of 1 (backend is newer)
   * [0,1,0] means the available version has a minor increment of 1 (backend is newer)
   * [1,0,0] means the available version has a mayor increment of 1 (backend is newer)
   * [-1,0,0] means the available version has a mayor decrement of 1 (backend is older)
   * @param localVersion the local version like 1.1.1 or 1.1.1_rc
   * @param availableVersion the available version from the backend like 1.1.2
   */
  getDifference(localVersion: string, availableVersion: string): number[]
  {
    console.log(`getDifference(${localVersion}, ${availableVersion})`);
    const validVersionRegExp: RegExp = /^[0-9]+.[0-9]+.[0-9]+/;

    try
    {
      localVersion = validVersionRegExp.exec(localVersion)[0];
    }
    catch (err)
    {
      throw new Error(`Error parsing localVersion ${localVersion}`);
    }

    try
    {
      availableVersion = validVersionRegExp.exec(availableVersion)[0];
    }
    catch (err)
    {
      throw new Error(`Error parsing availableVersion ${availableVersion}`);
    }

    const parsedAvailable = availableVersion.replace(/-(.*)/g, '').split('.');
    const parsedLocal = localVersion.replace(/-(.*)/g, '').split('.');

    const available: number[] = [+parsedAvailable[0], +parsedAvailable[1], +parsedAvailable[2]];
    const local: number[] = [+parsedLocal[0], +parsedLocal[1], +parsedLocal[2]];

    return [
      available[0] - local[0],
      available[1] - local[1],
      available[2] - local[2]
    ];
  }
}
