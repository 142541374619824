import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FindAndReplacePair, AuthService } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'find-replace',
  templateUrl: './find-replace.component.html',
  styleUrls: ['./find-replace.component.scss']
})
export class FindReplaceComponent implements OnInit, OnDestroy {

  authServiceSubscription: Subscription;

  // To show the icons next to the find value and replace value
  @Input() showViewIcons: boolean;
  @Input() showResetAll: boolean;
  @Input() findAndReplaceValues: FindAndReplacePair [];

  @Output() findAndReplaceValuesChange: EventEmitter<FindAndReplacePair[]> = new EventEmitter<FindAndReplacePair[]>();

  /**
   * When click in the zoom icon next to the Find value or the replace value
   */
  @Output() viewFindValueClicked: EventEmitter<FindAndReplacePair> = new EventEmitter<FindAndReplacePair>();
  @Output() viewReplaceValueClicked: EventEmitter<FindAndReplacePair> = new EventEmitter<FindAndReplacePair>();

  @ViewChild('findreplace', { static: true }) findreplace: ElementRef;

  constructor(private authService: AuthService)
  {
    this.authServiceSubscription = this.authService.identityChanged.subscribe(() => this.removeValuesFindReplace());
  }

  ngOnInit()
  {
    // Nothing to do
  }

  ngOnDestroy()
  {
    this.authServiceSubscription.unsubscribe();
  }

  addValuefindReplace()
  {
    this.findAndReplaceValues.push({});
    // We need to autoscroll to the bottom for that we also need to wait the UI to drawn the new element
    setTimeout(() =>
    {
      this.findreplace.nativeElement.scrollTop = this.findreplace.nativeElement.scrollHeight;
    }, 10);
    this.emit();
  }

  canAddValue(): boolean
  {
    if (this.findAndReplaceValues && this.findAndReplaceValues[this.findAndReplaceValues.length - 1].findValue)
    {
      return false;
    }
    else
    {
      return true;
    }
  }

  removeValuesFindReplace()
  {
    this.findAndReplaceValues = [{}];
    this.emit();
  }

  removeValueFindReplace(pair: FindAndReplacePair)
  {
    const index = this.findAndReplaceValues.indexOf(pair);
    if (index > -1)
    {
      this.findAndReplaceValues.splice(index, 1);
    }
    if (this.findAndReplaceValues && this.findAndReplaceValues.length <= 0)
    {
      this.findAndReplaceValues = [{}];
    }
    this.emit();
  }

  pastefromExcel(value)
  {
    if (this.findAndReplaceValues[1])
    {
      return;
    }
    const rows: string[] = value.split(String.fromCharCode(13));
    console.log(rows);
    this.findAndReplaceValues = [];

    for (let _i = 0; _i < rows.length; _i++)
    {
      const values: string[] = rows[_i].split(String.fromCharCode(9));
      if (values.length > 0)
      {
        const findValue = values[0].trim();
        const replaceValue = values.length > 1 ? values[1].trim() : '';
        if (findValue)
        {
          console.log(`Adding F&R ${findValue} ==> ${replaceValue}`);
          this.findAndReplaceValues.push({findValue: findValue, replaceValue: replaceValue});
        }
      }
    }
    this.emit();
  }

  viewFindValue(value: FindAndReplacePair)
  {
    this.viewFindValueClicked.emit(value);
  }

  viewReplaceValue(value: FindAndReplacePair)
  {
    this.viewReplaceValueClicked.emit(value);
  }

  emit()
  {
    this.findAndReplaceValuesChange.emit(this.findAndReplaceValues);
  }
}
