import { Injectable, Inject } from '@angular/core';
import * as _ from 'underscore';
import {
        Rule,
        DocumentVersion,
        MoonDeskDocument,
        RuleIDs
        } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { IllustratorService } from '../illustrator.service';

@Injectable({
    providedIn: 'root'
  })
export class DoubleSpaceRule implements Rule
{
    constructor(@Inject('DocumentAccessProvider') private illService: IllustratorService)
    {}

    id = RuleIDs.DOUBLE_SPACES;
    name: string = 'Double Spaces';
    actionName: string = 'Show';

    async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string): Promise<RuleResult>
    {
        const result: RuleResult =
        {
            rule: this,
            filePath: filePath,
            applied: !this.illService.isDummy,
            error: false,
            description: `No double spaces found`
        };
        if (!this.illService.isDummy)
        {
          const amount = await this.illService.findDoubleSpaces(filePath, 0);
          if (amount !== 0)
          {
              result.description = `Found ${amount} double spaces` ;
              result.error = true;
              result.data = amount;
          }
        }
        return result;
    }


    action = (result: RuleResult) =>
    {
        if (result.data)
        {
            const amount = <number>result.data;
            alert(`todo select ${amount} double space(s) in document ${result.filePath}`);
        }
        else
        {
            //
        }
    }
    parseConfig = (result: any) => '';
}
