<div class="image-zoom" [ngClass]="{'full-screen': fullscreen}" (keyup.arrowup)="panImage('UP')" (keyup.arrowdown)="panImage('DOWN')" (keyup.arrowleft)="panImage('LEFT')" (keyup.arrowright)="panImage('RIGHT')">
      
  <!-- CREATE COMMENT AND TOGGLE VISIBILITY CONTROLS -->
  <div class="comment-options" *ngIf="imageLoaded && rotateValue === 0 && enableComments">
    <mat-icon class="add-comment notranslate" [ngClass]="{'disabled' : addComment || !allowEdit}"  
    matTooltip="Add a comment"  (click)="prepareToDraw()">
    comment
    </mat-icon>
    <mat-icon class="toggle-visibility notranslate" [ngClass]="{'disabled' : addComment}" 
    matTooltip="Toggle comments visibility" (click)="hideComments()">
    {{ hidecomments ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </div>

  <!-- CREATE COMMENT INPUT BOX -->
  <div class="comment-input" *ngIf="showTemporaryComment">
    <div class="input-box">
      <mat-icon class="notranslate" [ngClass]="{'disabled' : busy }" (click)="closeComment()">close</mat-icon>
      <textarea matInput id="comment-input-text" placeholder="Write a comment..." [formControl]="commentform"></textarea>
      <mat-progress-spinner *ngIf="busy" diameter="25" color="accent" mode="indeterminate"></mat-progress-spinner>
      <button class="save" *ngIf="!busy" type="button" (click)="saveComment()">Save</button>  
    </div>  
  </div>  

  <div [ngClass]="{'draggable-image': !showControls, 'image': showControls}">
    <div class="spinner" *ngIf="!imageLoaded">
      <mat-progress-spinner diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
    <moon-zoom [config]="panZoomConfig" [ngClass]="{'hidden': !imageLoaded}">
      <!-- COMMENTS PLACEMENT -->
      <div class="draw" *ngIf="rotateValue === 0 && enableComments">
        <!-- COMMENT CLIP -->
        <mat-icon class="comment-clip-placement" id="comment" matTooltip="New Comment to add..">mode_comment</mat-icon>
        <div *ngIf="!hidecomments">
          <mat-icon class="comment-clip-drawed" 
          *ngFor="let comment of filteredComments" 
          [ngClass]="{'editing-comment' : currentComment && currentComment.id === comment.id }" 
          [ngStyle]="{ 'top' : getCoordinates(comment , 'TOP') ,'left':  getCoordinates(comment , 'LEFT') }"
          [matTooltip]="getInfomessage(comment)" matTooltipPosition="above"
          (click)="setCurrentComment(comment)">comment</mat-icon>
        </div>
        <!-- COMMENT CANVAS -->
        <canvas class="comment-canvas" [ngClass]="{'drawing-comment' : addComment , 'disabled' : busy}" id="canvas" (click)="draw($event)"></canvas>  
      </div> 
      <!-- IMAGE -->
      <img #image id="imageSrc" class="originalimg" [ngClass]="{'rotate-90': rotateValue ==1 , 'rotate-180' : rotateValue ==2 , 'rotate-270' : rotateValue ==3}"   [src]="imageSrc"  [hidden]="showComparation" (load)="onLoadImage()">
      <img  class="compareimg"  [ngClass]="{'rotate-90': rotateValue ==1 , 'rotate-180' : rotateValue ==2 , 'rotate-270' : rotateValue ==3}"   *ngIf="comparationResult" [src]="comparationResult" [hidden]="!showComparation" >
    </moon-zoom>
  </div>

  <!-- CONTROLS -->  
  <div class="actions">
    <div class="view" [ngClass]="{'view-fullscreen' : fullscreen}">
      <div class="rotateactions">
          <mat-icon (click)="rotateImages()">rotate_right</mat-icon>
        </div>
    </div>
    <div class="zoom">
      <div class="zoom-box">
        <mat-icon class="notranslate" (click)="zoomOut()">remove_circle</mat-icon>
        <input type="text" class="percent" [formControl]="zoomPercentage" disabled>
        <mat-icon class="notranslate" (click)="zoomIn()">add_circle</mat-icon>
      </div>
    </div>
    <div class="view" [ngClass]="{'viewZoom-fullscreen' : fullscreen}">
      <mat-icon class="notranslate fit" (click)="resetView()" matTooltip="Fit in window" matTooltipPosition="above">
        <span>swap_vert</span>
      </mat-icon>
    </div>
  </div>
  
  <div class="keyboards" *ngIf="showControls">
    <div class="arrows-wrapper">
      <div class="vertical">
        <button (click)="panImage('UP')"><mat-icon class="notranslate">keyboard_arrow_up</mat-icon></button>
        <button (click)="panImage('DOWN')"><mat-icon class="notranslate">keyboard_arrow_down</mat-icon></button>
      </div>
      <div class="horizontal">
        <button (click)="panImage('LEFT')"><mat-icon class="notranslate">keyboard_arrow_left</mat-icon></button>
        <button (click)="panImage('RIGHT')"><mat-icon class="notranslate">keyboard_arrow_right</mat-icon></button>
      </div>
    </div>
  </div>

</div>
