<div class="md-popup">

  <div class="md-popup-header">
    <!-- <mat-icon matTooltip="Close" [ngClass]="{'closedisabled':!allowLater}" (click)="close()">close</mat-icon> -->
    <div class="title">New version available</div>
  </div>

  <div class="md-popup-content asked">
    <div *ngIf="allowLater">A new version {{availableVersion}} of MoonDesk is available. Please download and install. </div>
    <div *ngIf="!allowLater">Your version {{localVersion}} is deprecated. You have to download and install the new version {{availableVersion}} to continue. </div>
    <BR>
    <div class="status" *ngIf="status" [innerText]="status"></div>
    <mat-progress-bar class="progressbar"
      *ngIf="busy || (progress > 0 && progress < 100)"
      color="accent"
      mode="determinate"
      [value]="progress">
    </mat-progress-bar>
  </div>

  <div *ngIf="!downloaded && !busy" class="md-popup-actions">
    <button class="md-button md-button-highlighted" (click)="download()">Download</button>
    <button class="md-button" (click)="close()" *ngIf="allowLater">Later</button>
    <BR>
  </div>

  <div class="md-popup-content asked">
    <div *ngIf="downloaded">
      <div>The last version was downloaded.</div>
      <div>Please <strong>EXIT</strong> Illustrator and run the installer.</div> 
      <div>For more information go to the <strong class="action" (click)="goToManual()">manual</strong>.</div>
      <BR>
      <div class="md-popup-actions">
        <button class="md-button md-button-highlighted" (click)="openInstallerFolder()">  Open Installer Folder  </button>
        <button class="md-button" (click)="close()" *ngIf="allowLater">Later</button>
      </div>
    </div>  
  </div>

</div>
