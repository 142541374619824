
<div class="fieldlist">

  <!-- <div class="refresh" *ngIf="dataArray && dataArray.length > 0">
    <mat-progress-spinner *ngIf="busy" diameter="16" mode="indeterminate" color="accent" [matTooltip]="status" matTooltipPosition="above"></mat-progress-spinner>
    <mat-icon *ngIf="!busy" matTooltip="Update content" matTooltipPosition="above" (click)="refresh.emit()" matTooltip="Refresh" matTooltipPosition="above">refresh</mat-icon>
  </div> -->

  <div class="field" [ngClass]="{'selected': date.isSelected}" *ngFor="let date of dataArray" (click)="select(date)">
    <div class="utilities">
      <mat-icon class="zoom" *ngIf="date.fieldType.type != 'Barcode' && date.status === 'ok'" [ngClass]="{'disabled':busy}" (click)="zoom(date)" matTooltip="Zoom" matTooltipPosition="above">zoom_in</mat-icon>
      <mat-icon class="warn" *ngIf="date.status !== 'ok'" (click)="select(date)" [matTooltip]="getTooltipText(date)" matTooltipPosition="above">priority_high</mat-icon>
    </div>
    <mat-icon class="link" *ngIf="date.fieldType.type != 'Barcode'" [ngClass]="{'disabled':busy}" (click)="link(date)" matTooltip="Assign to selection" matTooltipPosition="above">link</mat-icon>
    <div class="skipOutline" *ngIf="date.fieldType.skipOutlineConversion" [matTooltip]="'This field will keep as editable at Outline file'"><mat-icon class="warning-icon">warning_amber</mat-icon></div>
    <div class="name">{{date.fieldType.name}}</div>
    <div class="value" [innerText]="getValue(date)"></div>
  </div>

  <div *ngIf="!dataArray || dataArray.length === 0">
    No fields for this document type
  </div>

  <div class="footer" *ngIf="status || progress > 0">
    <mat-progress-bar [mode]="progress > 0 ? 'determinate' : 'indeterminate'" [value]="progress" color="accent"></mat-progress-bar>
    <div class="status" *ngIf="status">{{status}}</div>
  </div>

</div>