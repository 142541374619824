import { Injectable } from '@angular/core';
import {
  DocumentVersion,
  MoonDeskDocument,
  Rule,
  RuleIDs
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { IllustratorService } from '../illustrator.service';

@Injectable({
  providedIn: 'root'
})
export class MissingFontsRule implements Rule {

  constructor(
    private illService: IllustratorService,
  ) { }

  id = RuleIDs.MISSING_FONTS;
  name: string = 'Missing fonts Check';

  actionName: string = 'Show';

  async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string): Promise<RuleResult>
  {
    const result: RuleResult =
    {
      rule: this,
      filePath: filePath,
      applied: true ,
      error: false,
      description: 'No missing fonts',
    };
    const docMissingFonts = await this.illService.getDocMissingFonts(filePath);
    if(docMissingFonts.length !== 0)
    {
      result.description = `One or more fonts in this document are missing`;
      result.error = true;
      result.data = docMissingFonts;
    }
    return result;
  }

  action = (result: RuleResult) =>
  {
    alert(`todo check missing fonts in document ${result.filePath}`);
  }

  parseConfig = (result: any) => '';
}
