import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

import * as _ from 'underscore';
import {
  Identity,
  MoonTask,
  User,
  TaskFilter,
  TasksService,
  EventHubService,
  FeedbackService,
  TaskView,
  AuthService,
  TaskNotification,
  MoonTaskType,
  DocumentService,
  DocumentChangedEvent,
  PermissionsService,
  UserAction,
  TaskForExtensionDto,
  TaskDescriptionPopupData,
  ExtensionTaskSubTaskDto,
  ExtensionTaskTemplateDto,
  TaskStatus
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { NavigationService } from '../../../services/navigation.service';
import { Subscription } from 'rxjs';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tasks-list',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('300ms ease-in-out')
      ]),
      transition(':leave', [
        animate('300ms ease-in-out', style({ opacity: 0, height: 0 }))
      ]),
      state('*', style({ opacity: 1 })),
    ])
  ],
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnInit, OnDestroy {
  taskStatusEnum = TaskStatus;

  identity: Identity;
  companyUsers: User[];
  busy: boolean;
  taskFilter: TaskFilter;
  sortAscending: boolean = false;
  todayDate = new Date();
  dirtyFilter = false;
  noResultText: string = 'Please press Search';
  showMyTasksSwitch: boolean;

  tasks: TaskForExtensionDto[];
  totalResults: number;
  pageCount: number;
  currentPage: number;


  private subs: Subscription[] = [];
  constructor(
    private taskService: TasksService,
    private eventHub: EventHubService,
    private feedbackService: FeedbackService,
    private authService: AuthService,
    private navigationService: NavigationService,
    private docService: DocumentService,
    private permissionsService: PermissionsService
  )
  {
    this.subs.push(this.navigationService.navToMyTasks.subscribe(()=>
    {
      this.searchTasks();
    }));
  }

  async ngOnInit()
  {
    this.taskFilter =
      {
        companyId: '',
        filterBy: 'MyTasks',
        orderBy: 'Timestamp',
        reverseOrder: false,
        page: 1
      };
    this.subs.push(this.eventHub.refreshTasks.subscribe(() => this.resetData()));
    this.docService.documentChanged.subscribe(async (event: DocumentChangedEvent) =>
    {
      if (event.action === 'AddEdit' && event.forceSearch)
      {
        this.resetData();
      }
    });
    this.subs.push(this.eventHub.taskMessageClicked.subscribe((msg: TaskNotification) =>
    {
      const taskId = msg.task.id;
      const subTaskId = msg.subTaskMessage ? msg.subTaskMessage.subTaskId : undefined;
      this.viewTask(taskId, subTaskId);
    }));
    this.subs.push(this.authService.identityChanged.subscribe(identity =>
      {
        this.identity = identity;
        this.resetData();
        this.loadCompanyUsers();
        this.setShowMyTasksSwitch();
      }));
    this.identity = this.authService.getCurrentIdentity();
    // this.resetData();
    this.loadCompanyUsers();
    this.setShowMyTasksSwitch();
  }

  ngOnDestroy(): void
  {
    this.subs.forEach(s => s.unsubscribe());
  }

  private setShowMyTasksSwitch()
  {
    this.showMyTasksSwitch = this.permissionsService.currentUserHasPermissionTo(UserAction.viewAllTasks);
  }

  getTaskName(task: MoonTask): string
  {
    return task.name;
  }

  async loadCompanyUsers()
  {
    try
    {
      if (this.identity.user)
      {
        this.companyUsers = await this.taskService.getCompanyUsers(this.identity.company.id);
      }
      else
      {
        this.companyUsers = [];
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error loading company Users', err);
    }
  }

  private async resetData()
  {
      this.tasks = [];
      this.taskFilter.orderBy = undefined;
  }

  isReviewMode()
  {
    return this.identity && this.identity.reviewer !== undefined;
  }

  refresh()
  {
    this.resetData();
  }

  async searchPageTasks()
  {
    try
    {
      this.busy = true;
      if (!this.taskFilter.page)
      {
        this.taskFilter.page = 1;
      }

      if (this.pageCount >= (this.taskFilter.page + 1))
      {
        this.taskFilter.page = this.taskFilter.page + 1;
        this.taskFilter.taskTypes = [MoonTaskType.DocumentChange, MoonTaskType.DocumentReview];
        const response = await this.taskService.searchExtensionTasks(this.taskFilter);
        this.currentPage = this.taskFilter.page;
        this.busy = false;
        for (const task of response.result)
        {
          this.tasks.push(task);
        }
      }
      this.dirtyFilter = false;
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error searching page tasks', err);
    }
    finally
    {
      this.busy = false;
    }
  }

  async searchTasks()
  {
    try
    {
      this.taskFilter.page = 1;
      this.busy = true;
      this.noResultText = undefined;
      this.tasks = [];
      this.taskFilter.taskTypes = [MoonTaskType.DocumentChange, MoonTaskType.DocumentReview];
      const response = await this.taskService.searchExtensionTasks(this.taskFilter);
      this.tasks = response.result;
      this.pageCount = response.pageCount;
      this.totalResults = response.totalResult;
      this.currentPage = 1;
      if (this.tasks && this.tasks.length === 0)
      {
        this.noResultText = 'Your search returned no results You can try other terms or with advanced search';
      }
      this.dirtyFilter = false;
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error searching tasks', err);
    }
    this.busy = false;
  }

  resetFilter()
  {
    if (this.busy)
    {
      return;
    }
    this.tasks = [];
    this.currentPage = 1;
    this.pageCount = 0;
    this.totalResults = 0;
    this.noResultText = 'Please press Search';
    this.taskFilter =
    {
      companyId: this.identity.company.id,
      filterBy: 'MyTasks',
      page: 1
    };

    this.searchTasks();
  }

  updateTaskFilter(taskFilter: TaskFilter, forceSearch?: boolean)
  {
    this.dirtyFilter = true;
    this.taskFilter = taskFilter;
    this.tasks = [];
    this.noResultText = 'Please press Search';
    if (this.taskFilter && forceSearch)
    {
      this.searchTasks();
    }
  }

  getSubTaskName(subTask: ExtensionTaskSubTaskDto): string
  {
    return `${subTask.documentTypeName} - ${subTask.documentName}`;
  }

  getTemplateName(template: ExtensionTaskTemplateDto): string
  {
    return `${template.documentTypeName} - ${template.documentName}`;
  }

  getUsernameInitials(username: string)
  {
    let result: string = '';
    const names = username?.split(' ');
    names?.forEach(n => result += n.substring(0, 1));
    return result ? result.toUpperCase() : '-';
  }

  async edit(taskId: string, docId?: string)
  {
    try
    {
      this.busy = true;
      const task = await this.taskService.queryTask(
        {
          taskId: taskId,
          includeDocumentContents: true,
          includeReviewAssignments: true
        }
      );

      if (task)
      {
        if (task.subTasks && task.subTasks.length > 0 && _.any(task.subTasks, st => st.status !== 'Closed'))
        {
          const taskView: TaskView = {task: task, selectedDocumentId: docId, selectFirst: !docId};
          this.eventHub.goToWorkEdit.emit(taskView);
        }
        else
        {
          // go to import
          if (task.taskDocumentTemplates && task.taskDocumentTemplates.length > 0)
          {
            docId = task.taskDocumentTemplates[0].documentId;
          }
          const taskView: TaskView = {task: task, selectedDocumentId: docId};
          this.eventHub.goToWorkImport.emit(taskView);
        }
      }
      else
      {
        this.feedbackService.notifyMessage('Task not found');
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Task could not be loaded', err);
    }
    this.busy = false;
  }


  async importToTask(taskId: string, mode: 'new' | 'existing', documentId?: string)
  {
    this.busy = true;
    try
    {
      const task = await this.taskService.queryTask(
        {
          taskId: taskId,
          includeDocumentContents: true,
          includeReviewAssignments: true
        });
      this.eventHub.goToWorkImport.emit({task: task, selectedDocumentId: documentId});
      if (mode === 'existing')
      {
        this.navigationService.navToMyLabels.emit();
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Task could not be loaded', err);
    }
    this.busy = false;
  }

  selectTask(taskId: string)
  {
    const taskView: TaskDescriptionPopupData = { taskId: taskId };
    this.eventHub.openTaskView.emit(taskView);
  }

  selectSubTask(taskId: string, subTaskId: string)
  {
    const taskView: TaskDescriptionPopupData = { taskId: taskId, subTaskId: subTaskId };
    this.eventHub.openTaskView.emit(taskView);
  }

  toggleSubTasks(task: TaskForExtensionDto)
  {
    task.showSubTasks = !task.showSubTasks;
  }

  private async viewTask(taskId: string, subTaskId?: string)
  {
    if (taskId)
    {
      const taskViewData: TaskDescriptionPopupData =
      {
        taskId: taskId,
        subTaskId: subTaskId
      };
      this.eventHub.openTaskView.emit(taskViewData);
    }
    else
    {
      this.feedbackService.notifyError('Task could not be loaded', new Error(taskId));
    }
  }

  getFormattedTaskStatus(taskStatus: TaskStatus): 'In Work' | 'In Review' | 'Done'
  {
    switch (taskStatus)
    {
      case TaskStatus.ToDo:
        return 'In Work';
      case TaskStatus.InReview:
        return 'In Review';
      case TaskStatus.Done:
        return 'Done';
    }
  }

  getFormattedSubTaskStatus(subTaskStatus: 'ToDo' | 'InReview' | 'Done' | 'Closed'): string
  {
    switch (subTaskStatus)
    {
      case 'ToDo':
        return 'To Do';
      case 'InReview':
        return 'In Review';
      default:
        return subTaskStatus;
    }
  }
}
