<div class="document-contents" *ngIf="contentGroups && contentGroups.length > 0">
    <div class="how-work">
        <mat-icon class="info-icon" [libTooltip]="contentExplanation">info</mat-icon>
        <span class="size-work" [libTooltip]="contentExplanation">How content minimun size is checked?</span>
    </div>
    <div class="content-groups" *ngFor="let contentGroup of contentGroups">
        <span class="group-name">{{contentGroup.name}}</span>
        <div class="doc-content" *ngFor="let docContent of contentGroup.documentContents">
            <div class="and-or-splitter" *ngIf="docContent.previousRuleCondition">
                <span>{{docContent.previousRuleCondition}}</span>
            </div>
            <!-- TEXT CONTENT -->
            <div class="rows" *ngIf="docContent.type === 'Text'">
                <div class="actions" *ngIf="!docContent.busy">
                    <mat-icon class="action" matTooltip="Paste content" (click)="linkContent(docContent)" [inline]="true">content_copy</mat-icon>
                    <mat-icon class="action" matTooltip="Check rule" (click)="checkRule(docContent)" [inline]="true">done_all</mat-icon>
                    <mat-icon class="action" *ngIf="!docContent.expandedView" matTooltip="Expand content" (click)="toggleExpandedView(docContent)" [inline]="true">keyboard_arrow_down</mat-icon>
                    <mat-icon class="action" *ngIf="docContent.expandedView" matTooltip="Shrink content" (click)="toggleExpandedView(docContent)" [inline]="true">keyboard_arrow_up</mat-icon>
                </div>
                <div class="actions" *ngIf="docContent.busy">
                    <mat-progress-spinner class="spinner" diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div class="content-info" [ngClass]="{'expanded': docContent.expandedView}">
                    <div class="content">
                        <span class="content-html-container" [innerHTML]="docContent.content">
                        </span> 
                    </div>
                    <div class="description">
                        <ng-container *ngIf="docContent.checkSize">
                            <span *ngIf="docContent.minSize > 0">- Minimun text height: {{docContent.minSize}}mm.</span>
                            <span *ngIf="docContent.maxSize > 0">- Maximum text height: {{docContent.maxSize}}mm.</span>
                        </ng-container>
                        <ng-container *ngIf="docContent.wildCard">
                            <span *ngIf="docContent.wildCard === 'contains'">- Content must be part of a phrase</span>
                            <span *ngIf="docContent.wildCard === 'startsWith'">- Content must start a phrase</span>
                            <span *ngIf="docContent.wildCard === 'endsWith'">- Content must end a phrase</span>
                            <span *ngIf="docContent.wildCard === 'matchesWith'">- Content must exist</span>
                        </ng-container>
                        <span *ngIf="docContent.description">- {{docContent.description}}</span>
                    </div>
                </div>
            </div>
            <!-- LIB CONTENT -->
            <div class="rows" *ngIf="docContent.type === 'LibraryElement'">
                <div class="actions" *ngIf="!docContent.busy">
                    <mat-icon class="action" matTooltip="Paste content" (click)="linkLibContent(docContent)" [inline]="true">content_copy</mat-icon>
                    <mat-icon class="action" matTooltip="Check rule" (click)="checkRule(docContent)" [inline]="true">done_all</mat-icon>
                    <mat-icon class="action" *ngIf="!docContent.expandedView" matTooltip="Expand content" (click)="toggleExpandedView(docContent)" [inline]="true">keyboard_arrow_down</mat-icon>
                    <mat-icon class="action" *ngIf="docContent.expandedView" matTooltip="Shrink content" (click)="toggleExpandedView(docContent)" [inline]="true">keyboard_arrow_up</mat-icon>
                </div>
                <div class="actions" *ngIf="docContent.busy">
                    <mat-progress-spinner class="spinner" diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                </div>
                <div class="content-info" [ngClass]="{'expanded': docContent.expandedView}">
                    <div class="content content-image">
                        <div class="img-box" [matTooltip]="getConcatedTags(docContent.documentVersion)">
                            <img *ngIf="!docContent.busy" [src]="docContent.documentVersion.imageUrl">
                            <mat-progress-spinner *ngIf="docContent.busy" diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
                        </div>
                    </div>
                    <div class="description">
                        <span class="description-text" *ngIf="docContent.description?.length > 0">{{docContent.description}}</span>
                        <span class="description-tags" *ngIf="!docContent.description || docContent.description?.length === 0">{{getConcatedTags(docContent.documentVersion)}}</span>
                        <span class="description-size-info" *ngIf="docContent.libContentHeight !== 0 || docContent.libContentWidth !== 0">{{getImgSizeControlString(docContent)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #contentExplanation>
        <div class="text">
            <div>
                <span> The minimum/maximum size of content is checked based on the height of the </span> <strong class="smallest-letter">smallest letter</strong> 
            </div>
            <div>
                <span> found in the text box in which the content was pasted.</span>
            </div>
        </div>
    </ng-template>
</div>

