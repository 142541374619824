import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { IllustratorService } from './services/illustrator.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  constructor(public overlayContainer: OverlayContainer,
    private illustratorService: IllustratorService)
  {
  }

  async ngOnInit(): Promise<void>
  {
    this.illustratorService.ThemeChangedEvent.subscribe(this.themeChanged);
    this.illustratorService.hideStartScreen();
    this.illustratorService.showMissingFontDialog();

    this.sendMessageToContainerExtension();
  }

  private sendMessageToContainerExtension()
  {
    const message = 'MoonDesk extension loaded successfully';
    window.parent.postMessage(message, '*');
  }

  themeChanged = (isDark: boolean) =>
  {
    console.log(`applying dark theme: ${isDark}`);
    let themeClass: string = 'dark-theme';
    let bgColor: string = '';
    if (isDark)
    {
      themeClass = 'dark-theme';
      bgColor = '#2e2e2e';
    }
    else
    {
      themeClass = 'light-theme';
      bgColor = '#e6e6e6';
    }
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = bgColor;
    const app = document.getElementsByClassName('app')[0];
    app.className = `${themeClass} app`;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) =>
      item.includes('-theme')
    );
    // @ts-ignore
    classList.remove(...toRemove);
    classList.add(themeClass);
  }
}
