import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  FeedbackService,
  MoonDeskDocument,
  MoonTask,
  RuleConfiguration,
  RuleIDs,
  RulesService,
  RuleTag,
  TasksService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RuleCreateComponent } from './rule-create/rule-create.component';
import { CurrentDocumentChangedEvent, CurrentDocumentService } from '../work/current-document-manager/current-document.service';
import { WorkManagerService } from '../work/work-manager/work-manager.service';
import { RuleHelperService } from '../../services/rule-helper.service';
import { IllustratorService } from '../../services/illustrator.service';
import { SaveDocumentService } from '../work/save-document/save-document.service';
import { RulesExecutionService } from '../../services/rules/rules-execution.service';
import { BACKEND_INFO, IllustratorConfigService } from 'src/app/services/illustrator-config.service';

interface ContentRule
{
  ruleConfiguration: RuleConfiguration;
  contentRuleConfig: any; // ContentTextRuleConfiguration || ContentLibraryRuleConfiguration
  ruleId: RuleIDs;
  // UI
  toggled?: boolean;
  busy?: boolean;
  canEdit?: boolean;
}

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('100ms ease-in-out')
      ]),
      transition(':leave', [
        animate('100ms ease-in-out', style({ opacity: 0, height: 0 }))
      ]),
      state('*', style({ opacity: 1 })),
    ])
  ],
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, OnDestroy {

  workspaceRules: ContentRule[] = [];
  workspaceRulesToggled: boolean;
  workspaceRulesBusy: boolean;

  taskRules: ContentRule[] = [];
  taskRulesToggled: boolean;
  taskRulesBusy: boolean;

  currentDocument: MoonDeskDocument;
  currentTask: MoonTask;

  oldResults: boolean;

  subscriptions: Subscription[] = [];

  constructor(
    private feedbackService: FeedbackService,
    private dialog: MatDialog,
    private workMngService: WorkManagerService,
    private currentDocService: CurrentDocumentService,
    private tasksService: TasksService,
    private ruleHelperService: RuleHelperService,
    private illService: IllustratorService,
    private illConfigService: IllustratorConfigService,
    private saveDocService: SaveDocumentService,
    private rulesService: RulesService,
    private rulesExecutionService: RulesExecutionService,
    private authService: AuthService)
  {}

  ngOnInit(): void
  {
    this.subscriptions.push(
      this.workMngService.currentTaskChange.subscribe((task: MoonTask) => this.currentTaskChange(task))
    );
    this.subscriptions.push(
      this.currentDocService.documentChange.subscribe((e: CurrentDocumentChangedEvent) => this.currentDocChange(e))
    );
  }

  ngOnDestroy(): void
  {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  currentDocChange(e: CurrentDocumentChangedEvent)
  {
    if (e.status === 'done')
    {
      const updatedDoc = e.editDocument ? e.editDocument : e.importDocument;
      const oldId = this.currentDocument ? this.currentDocument.id : '';
      this.currentDocument = updatedDoc;
      if (!this.currentDocument || this.currentDocument.id !== oldId)
      {
        // To prevent excessive loadWorkspaceRules() calls, we only automatically update on docId
        // In other cases we ofer the user to update manually with oldResults = true
        this.loadWorkspaceRules();
      }
      else if (this.currentDocument && this.currentDocument.documentType)
      {
        this.oldResults = true;
      }
    }
  }

  currentTaskChange(task: MoonTask)
  {
    this.currentTask = task;
    this.loadTaskRules();
  }

  async loadWorkspaceRules()
  {
    if (this.workspaceRulesBusy)
    {
      return;
    }
    if (!this.currentDocument || !this.currentDocument.documentType)
    {
      this.workspaceRules = [];
      this.oldResults = false;
      return;
    }
    try
    {
      this.workspaceRulesBusy = true;
      this.workspaceRules = [];
      const rulesConfiguration = await this.rulesService.getRulesConfigs(this.currentDocument);
      if (!this.currentDocument)
      {
        // Bug fix for 11482 - maybe the user closed the document (currentDocument = null)
        // before the "queryRuleConfigs(this.currentDocument)" was completed leading to an error
        // in the following calls (getVisibleItems(), createDocumentVersion()).
        return;
      }
      const contentRulesConfig = _.filter(rulesConfiguration, rule => rule.ruleID === RuleIDs.TEXT_CONTENT ||
                                                                      rule.ruleID === RuleIDs.LIBRARY_CONTENT);
      // const docItems = await this.illService.getVisibleItems(this.currentDocument.workingCopy);
      // const version = await this.saveDocService.createDocumentVersion(this.currentDocument, docItems, this.currentDocument.workingCopy);
      for (const contentRuleConfig of contentRulesConfig)
      {
        let addRule: boolean = true;
        const newContetRule: ContentRule =
        {
          ruleConfiguration: contentRuleConfig,
          contentRuleConfig: JSON.parse(contentRuleConfig.ruleOptions),
          ruleId: RuleIDs.TEXT_CONTENT
        };
        // If the rule has findText we must run it to know if it applies to the current document
        // OBSOLETE - UI does not allow this kind of rules anymore
        // if (newContetRule.contentRuleConfig.findText)
        // {
        //   const ruleResult = await this.rulesExecutionService.runRule(
        //     newContetRule.ruleConfiguration,
        //     this.currentDocument,
        //     version,
        //     this.currentDocument.workingCopy
        //   );
        //   if (!ruleResult.applied)
        //   {
        //     addRule = false;
        //   }
        // }
        if (addRule)
        {
          newContetRule.canEdit = this.canEdit(newContetRule);
          this.workspaceRules.push(newContetRule);
        }
      }
      this.workspaceRules = _.sortBy(this.workspaceRules, rule => rule.contentRuleConfig.ruleDescription.toLowerCase());
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error getting workspace rules', err);
    }
    finally
    {
      this.oldResults = false;
      this.workspaceRulesBusy = false;
    }
  }

  loadTaskRules()
  {
    this.taskRules = [];
    if (this.currentTask && this.currentTask.documentContents && this.currentTask.documentContents.length > 0)
    {
      const taskContentRules = this.tasksService.getRulesFromContent(this.currentTask.documentContents, '');
      const taskRules: ContentRule[] = [];
      taskContentRules?.forEach(contentRuleConfig =>
        {
          taskRules.push({
            ruleConfiguration: contentRuleConfig,
            contentRuleConfig: JSON.parse(contentRuleConfig.ruleOptions),
            ruleId: contentRuleConfig.ruleID
          });
        }
      );
      this.taskRules = taskRules;
    }
  }

  showWorkspaceRules(): boolean
  {
    return !this.workspaceRulesBusy &&
            this.workspaceRulesToggled &&
            this.workspaceRules &&
            this.workspaceRules.length > 0;
  }

  showTaskRules(): boolean
  {
    return !this.taskRulesBusy &&
            this.taskRulesToggled &&
            this.taskRules &&
            this.taskRules.length > 0;
  }

  /**
   * Only if:
   * 1 - Rule is not from task
   * 2 - Rule is TEXT_CONTENT
   * 3 - Rule does not have findText (Only classValues/DocTypes)
   * 4 - Rule checkCondition === 'exist'
   */
  private canEdit(contentRule: ContentRule): boolean
  {
    return !contentRule.ruleConfiguration.isFromTask &&
           contentRule.ruleId === RuleIDs.TEXT_CONTENT &&
           !contentRule.contentRuleConfig.findText &&
            contentRule.contentRuleConfig.checkCondition === 'exist';
  }

  canRunRule(): boolean
  {
    return this.currentDocument ? true : false;
  }

  contentRuleConfigToString(contentRule: ContentRule): string
  {
    let result: string;
    if (contentRule.ruleId === RuleIDs.TEXT_CONTENT)
    {
      const config = contentRule.contentRuleConfig;
      const checkText = config.richCheckText ? config.richCheckText : config.checkText;
      result = `${config.checkCondition === 'exist' ? 'Should exist' : 'Should not exist'}: ${checkText}`;
    }
    else
    {
      result = 'No aditional info';
    }
    return result;
  }

  async createRule()
  {
    await this.openEditRuleDialog();
    await this.loadWorkspaceRules();
  }

  async editRule(contentRule: ContentRule)
  {
    if (!this.canEdit(contentRule))
    {
      const msg = contentRule.ruleConfiguration.isFromTask ?
        'Task rules cannot be edited' :
        'This rule can only be edited from the web';
      this.feedbackService.notifyMessage(msg);
      return;
    }
    await this.openEditRuleDialog(contentRule.ruleConfiguration);
    await this.loadWorkspaceRules();
  }

  async runRule(contentRule: ContentRule)
  {
    if (!this.canRunRule())
    {
      this.feedbackService.notifyMessage('The rule does not apply to the current document');
      return;
    }
    if (!this.currentDocument.documentType)
    {
      this.feedbackService.notifyMessage('Please select a document type first');
      return;
    }
    try
    {
      contentRule.busy = true;
      const docItems = await this.illService.getVisibleItems(this.currentDocument.workingCopy, 0);
      const version = await this.saveDocService.createDocumentVersion(this.currentDocument, docItems, this.currentDocument.workingCopy);

      version.fullTextCharacters = await this.illService.getAllTextWithBoldReferences(this.currentDocument.workingCopy);

      const ruleResult = await this.rulesExecutionService.runRule(
        contentRule.ruleConfiguration,
        this.currentDocument,
        version,
        this.currentDocument.workingCopy
      );
      await this.ruleHelperService.showResultsDialog([ruleResult], false, this.currentDocument, [contentRule.ruleConfiguration]);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error running rule', err);
    }
    finally
    {
      contentRule.busy = false;
    }
  }

  ruleTagsToString(ruleTags: RuleTag[]): string
  {
    if (ruleTags && ruleTags.length > 0)
    {
      return ruleTags.map(rt => `#${rt.value}`).join('\n');
    }
    return "No tags"
  }

  goToWebRule(contentRule: ContentRule)
  {
    try
    {
      const id = this.authService.getCurrentIdentity();
      if (!id || !id.company)
      {
        throw new Error('No company');
      }
      const url = `${BACKEND_INFO.ipAddress}/rules/edit/${contentRule.ruleConfiguration.id}`;

      this.illService.openUrl(url);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error opening web url', err);
    }
  }

  private openEditRuleDialog(ruleConfiguration?: RuleConfiguration)
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(RuleCreateComponent, {
        width: '80%',
        height : '80%',
        data: {
          rule: ruleConfiguration,
          document: this.currentDocument
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => resolve());
    });
  }
}
