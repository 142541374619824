<div class="md-popup">

  <div class="md-popup-header">
    <!-- <mat-icon matTooltip="Close" (click)="close(false)" [ngClass]="{'not-allowed': busy}">close</mat-icon> -->
    <div class="title">Add document to task</div>
  </div>

  <div class="md-popup-content asked"> 
    <div>Do you want to add this document to the current task?</div> 
  </div>

  <div class="md-popup-actions" *ngIf="!busy">
    <button class="md-button" (click)="addDocToTask()">Yes</button>
    <button class="md-button md-button-outstanding" (click)="close(false)">No</button>
  </div>

  <mat-progress-spinner *ngIf="busy" diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>

</div>