<div class="md-popup">

    <div class="md-popup-header">
        <mat-icon matTooltip="Close" mat-dialog-close>close</mat-icon>
    </div>

    <div class="md-popup-content asked"> 
        <div>{{message}}</div> 
    </div>

    <div class="md-popup-actions center">
        <button class="md-button" mat-dialog-close>Ok</button>
    </div>

</div>