<div class="md-popup">

  <div *ngIf="busy" class="md-spinner">
    <mat-progress-spinner class="spinner" diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="success-message" *ngIf="successfullRegister">
    <mat-icon class="notranslate close" (click)="successfullRegister = false">close</mat-icon>
    <div class="hint"> 
      <span>Well done! Now you are officially a MoonDesk user</span>
      <mat-icon class="notranslate">done_all</mat-icon>
    </div>
    <div class="text">We've sent you an email to <strong>{{user.email}}</strong>. To get started please check your inbox and confirm your email.</div>
  </div>  

  <div class="md-popup-content">
    <user-info
      [(user)]="user"
      [userNameEditable]="true"
      [showOldPassword]="false"
      [hidePasswordFields]="true"
      (sucessRegister) = "setRegister($event)"
      (enterPressed)="register()">
    </user-info>
  </div>

  <div class="md-popup-actions">
    <button class="md-button md-button-outstanding" name="SignUpButton" (click)="register()" [disabled]="busy">Sign Up</button>
    <button class="md-button" [disabled]="busy" (click)="resetForm()">Reset</button>
    <div class="md-popup-actions--error" name="errorMessageRegister">{{error}}</div>
  </div>

</div>
