<div class="md-popup">

  <div *ngIf="profileBusy" class="md-spinner">
    <mat-progress-spinner class="spinner" diameter="48"  color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="md-popup-content">
    <h3>Profile</h3>
    <user-info
      [(user)]="_user"
      [userNameEditable]="false"
      [showOldPassword]="true"
      [existingUser]="true"
      (enterPressed)="save()">
    </user-info>
  </div>

  <div class="md-popup-actions">
    <button class="md-button md-button-outstanding" (click)="save()" [disabled]="profileBusy">Save</button>
    <div class="md-popup-actions--error">{{profileMessage}}</div>
  </div>

</div>
