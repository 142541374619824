<div class="md-popup fonts-handler-dialog">
    <div class="md-popup-header">
        <span class="title" translate>
            lid.ext.pages._shared.fonts-handler-dialog.fontsCannotUpload
        </span>
    </div>
    <div class="md-popup-content fonts-handler-body">
        <div class="subtitle" [innerHTML]="'lid.ext.pages._shared.fonts-handler-dialog.notFoundFontsInfo' | translate"></div>
        <div class="help-button">
            <mat-icon class="notranslate material-icons-outlined" (click)="goToManual()" [matTooltip]="'lid.ext.pages._shared.fonts-handler-dialog.manualLink' | translate">
                info
            </mat-icon>
        </div>

        <div class="missing-fonts-list">
            <label class="missing-fonts-title">
                <span translate>lid.ext.pages._shared.fonts-handler-dialog.notFoundFonts</span>
            </label>
            <div class="font" *ngFor="let font of linkedFonts">

                <!-- FIXABLE FONT -->
                <ng-container *ngIf="!font.formatNotSupported">
                    <!-- Unfixed Font -->
                    <ng-container *ngIf="!font.documentVersion && !font.path">
                        <div class="font-name">
                            <div class="name">
                                <span class="icon">
                                    <mat-icon class="notranslate warning">warning</mat-icon>
                                </span>
                                <span class="name" [matTooltip]="font.name">{{font.name}}</span>
                            </div>
                        </div>
                        <button class="action-button md-button md-button-outstanding" (click)="fileInput.click()">
                            <span translate>lid.ext.pages._shared.fonts-handler-dialog.searchFont</span>
                        </button>
                        <input hidden="true"
                            #fileInput
                            type="file"
                            (change)="inputFile(font, fileInput.files)">
                    </ng-container>
                    <!-- Fixed Font -->
                    <ng-container *ngIf="font.documentVersion || font.path">
                        <div class="font-name">
                            <div class="name" [matTooltip]="font.path">
                                <span class="icon">
                                    <mat-icon class="notranslate ok">check_circle</mat-icon>
                                </span>
                                <span class="text">{{font.filename}}</span>
                            </div>
                            <div class="name">
                                <span class="text not-found" [matTooltip]="font.name">
                                    {{getFoundNotFoundString(font.name)}}
                                </span>
                            </div>
                        </div>
                        <button class="action-button md-button md-button-highlighted" (click)="font.path = null; font.documentVersion = null">
                            <span translate>lid.ext.pages._shared.fonts-handler-dialog.remove</span>
                        </button>
                    </ng-container>
                </ng-container>

                <!-- NON-FIXABLE FONT -->
                <ng-container *ngIf="font.formatNotSupported">
                    <div class="font-name">
                        <div class="name">
                            <span class="icon">
                                <mat-icon class="notranslate error">highlight_off</mat-icon>
                            </span>
                            <span class="text line-through" [matTooltip]="font.name">{{font.name}}</span>
                            <span class="icon" [matTooltip]="'lid.ext.pages._shared.fonts-handler-dialog.formatNotAllowed' | translate">
                                <mat-icon class="notranslate">info</mat-icon>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="actions">
            <button class="md-button" [ngClass]="{'md-button-outstanding': allFontsReplaced()}" (click)="ok()">
                <span translate>lid.ext.pages._shared.fonts-handler-dialog.continue</span>
            </button>
            <button class="md-button" (click)="cancel()">
                <span translate>lid.ext.pages._shared.fonts-handler-dialog.cancel</span>
            </button>
        </div>
    </div>
</div>