<div class="moon-datepicker-header">
  <button mat-icon-button class="double-arrow" (click)="previousClicked('year')">
    <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
    <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    <mat-icon class="notranslate">keyboard_arrow_left</mat-icon>
  </button>
  <span class="header-label">{{periodLabel}}</span>
  <button mat-icon-button (click)="nextClicked('month')">
    <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
  </button>
  <button mat-icon-button class="double-arrow" (click)="nextClicked('year')">
    <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
    <mat-icon class="notranslate">keyboard_arrow_right</mat-icon>
  </button>
</div>