<div class="md-popup">
  <div class="md-popup-header">
    <div class="title">Resource name</div>
  </div>

  <div class="md-popup-content">
    <mat-form-field>
      <input matInput [(ngModel)]="resourceName">
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="md-popup-actions">
    <button class="md-button" (click)="cancel()">Cancel</button>
    <button class="md-button md-button-outstanding" [disabled]="!isValidFilename()" (click)="confirm()">Save</button>
  </div>
</div>