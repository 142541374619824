import { Injectable } from '@angular/core';
import {
        TasksService,
        MoonTask,
        MoonTaskAttachment,
        LoggingService,
        Severity
        } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FilepathService } from './filepath.service';
import { IllustratorService } from './illustrator.service';
import { FilesystemService } from './filesystem.service';

import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class TaskHelperService {

  constructor(private taskService: TasksService, private filePathService: FilepathService,
    private illService: IllustratorService, private logger: LoggingService,
    private fileService: FilesystemService)
    { }

  async downloadAttachment(task: MoonTask, attachment: MoonTaskAttachment)
  {
    if (!attachment.progress || attachment.progress === 100 )
    {
      try
      {
        attachment.downloadError = null;
        attachment.filePath = await this.filePathService.getFreeTaskAttachmentFilePath(task, attachment.name);
        const fileContent = await this.taskService.downloadAttachment(task, attachment, (p) =>
        {
          attachment.progress = p.percentage;
        });
        await this.fileService.writeFileAsync(attachment.filePath, fileContent, {encoding: 'base64'});
        attachment.progress = 100;
        this.fileService.open(attachment.filePath);
      }
      catch (err)
      {
        this.logger.trackTrace('Taskhelper/downloadAttachment error, re-throwing it', Severity.Error);
        this.logger.logException(err);
        attachment.downloadError = 'Error';
        attachment.progress = null;
        throw err;
      }
    }
    else
    {
      throw new Error('Download in progress');
    }
  }
}
