import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import {
        Rule,
        DocumentService,
        RuleIDs,
        MoonDeskDocument,
        DocumentVersion,
        DocumentFilter,
        ClassValue,
        ClassSelection
        } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';

@Injectable({
    providedIn: 'root'
  })
export class DuplicateLabelRule implements Rule
{
    constructor(private documentService: DocumentService)
    {
    }
    id = RuleIDs.DUPLICATE_DOCUMENTS;
    name: string = 'Duplicate documents';
    actionName: string = 'Open';

    async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string, config: any): Promise<RuleResult>
    {
        const result: RuleResult =
        {
            rule: this,
            filePath: filePath,
            applied: true,
            error: false,
            description: 'No duplicates found for this document',
            config: config
        };

        const similarDocumentsNames = await this.documentService.getDocumentsNamesByClassification(document.documentType.id, document.classValues?.map(cv => cv.id));

        if (similarDocumentsNames.length > 0)
        {
            result.description = `Found ${similarDocumentsNames.length} duplicate(s) for this document`;
            result.error = true;
            result.data = similarDocumentsNames;
        }

        return result;
    }

    action = (result: RuleResult) =>
    {
        const duplicates = result.data ? <MoonDeskDocument[]>result.data : undefined;
        if (duplicates)
        {
            alert(`todo open ${duplicates.length} label(s) ${result.filePath}`);
        }
        else
        {
            alert('There weren\'t any duplicates to open');
        }
    }
    parseConfig = (result: any) => '';
}
