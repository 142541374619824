import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import {
  MoonTask,
  MoonTaskAttachment
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/tasks/MoonTask';
import {
  FeedbackService,
  TasksService,
  SubTask,
  EventHubService,
  LoggingService,
  Severity,
  TaskDocumentTemplate,
  MoonDeskDocument,
  TaskDescriptionPopupData
} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { TaskHelperService } from '../../../services/task-helper.service';
import { AskDialogComponent } from '../../_shared/ask-dialog/ask-dialog.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'description-popup',
  templateUrl: './task-description-popup.component.html',
  styleUrls: ['./task-description-popup.component.scss']
})
export class TaskDescriptionPopupComponent
{

  /* Task editor view config */
  editorConfigurations: Object =
  {
    'toolbar': false,
    'readonly': true,
    'showCharsCounter': false,
    'buttons': [],
    'showWordsCounter': false,
    'showXPathInStatusbar': false,
  };


  taskId: string;
  task: MoonTask;
  selectedSubTask: SubTask;
  // comment: string;

  busy: boolean = true;
  // saving: boolean;
  subTaskBusy: boolean = false;
  tabIndex = 0;
  todayDate = new Date();
  // loadingSubTask: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TaskDescriptionPopupData,
              private dialogRef: MatDialogRef<TaskDescriptionPopupComponent>,
              private feedbackService: FeedbackService,
              private taskHelperService: TaskHelperService,
              public taskService: TasksService,
              private eventHubService: EventHubService,
              private dialog: MatDialog,
              private logger: LoggingService,
              private eventHub: EventHubService
              )
  {
    this.taskId = data.taskId;
    this.updateTask(data.subTaskId);
    this.tabIndex = data.subTaskId ? 1 : 0;
  }

  goToWebsite()
  {
    this.eventHubService.goToTaskWebsite.emit({task: this.task, subTask: this.selectedSubTask});
  }

  editTask()
  {
    let selectedDocId = this.selectedSubTask ? this.selectedSubTask.documentId : this.task.subTasks[0]?.documentId;
    if (selectedDocId)
    {
      this.eventHubService.goToWorkEdit.emit({task: this.task, selectedDocumentId: selectedDocId});
      this.dialogRef.close();
    }
    else
    {
      selectedDocId = this.task.taskDocumentTemplates && this.task.taskDocumentTemplates.length > 0 ?
        this.task.taskDocumentTemplates[0].documentId : undefined;
      this.eventHub.goToWorkImport.emit({ task: this.task, selectedDocumentId: selectedDocId });
      this.dialogRef.close();
    }
  }

  private async updateTask(subTaskId?: string)
  {
    try
    {
      this.busy = true;
      this.task = await this.taskService.queryTask(
        {
          taskId: this.taskId,
          includeAttachments: true,
          includeReviewAssignments: true,
          includeDocumentContents : true,
          includeDocumentDetails: true
        });
      // if (this.task)
      // {
      //   await this.loadSubTasks();
      // }
      if (this.selectedSubTask || subTaskId)
      {
        await this.selectSubTask(subTaskId ? subTaskId : this.selectedSubTask?.id);
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error loading task', err);
    }
    finally
    {
      this.busy = false;
    }
  }

 getSelectValue ()
  {
    if (this.selectedSubTask)
    {
      if (this.selectedSubTask.subTaskInfos.classValueString)
      {
        return this.selectedSubTask.subTaskInfos.classValueString;
      }
      else
      {
        return this.selectedSubTask.document.moonNumber;
      }
    }
    else
    {
      return 'Task Messages';
    }
  }

  // private async loadSubTasks()
  // {
  //   this.loadingSubTask = true;
  //   const fullSubTasks: SubTask[] = [];
  //   for (const st of this.task.subTasks)
  //   {
  //     if (st.status !== 'Closed')
  //     {
  //       const stLoaded = await this.taskService.querySubTask({subTaskId: st.id,
  //                                                           includeStatesAndReviewers: true,
  //                                                           includeDocumentVersions: true
  //                                                         });
  //       fullSubTasks.push(stLoaded);
  //     }
  //   }
  //   this.task.subTasks = fullSubTasks;
  //   this.loadingSubTask = false;
  // }

  async selectSubTask(subTaskId: string)
  {
    this.tabIndex = 1;
    if (!subTaskId)
    {
      this.selectedSubTask = undefined;
    }
    else
    {
      try
      {
        this.subTaskBusy = true;
        const freshSubTask = await this.taskService.querySubTask
        ({
          subTaskId: subTaskId,
          includeDocumentDetails: false,
          // includeMessages: true,
          includeDocumentVersions: true,
          includeStatesAndReviewers: false
        });
        this.selectedSubTask = freshSubTask;
      }
      catch (e)
      {
        this.feedbackService.notifyError('Error loading subtask', e);
      }
      this.subTaskBusy = false;
    }
  }

  closeSubTask()
  {
    this.selectedSubTask = undefined;
    this.subTaskBusy = false;
  }

  async downloadAttachment(attachment: MoonTaskAttachment)
  {
    if (!attachment.progress || attachment.progress === 100 )
    {
      try
      {
        await this.taskHelperService.downloadAttachment(this.task, attachment);
        this.feedbackService.notifyMessage(`${attachment.name} was downloaded`);
      }
      catch (err)
      {
        this.feedbackService.notifyError('Error downloading attachment', err);
        attachment.downloadError = 'Error';
        attachment.progress = null;
      }
    }
    else
    {
      console.log('download already in progress');
    }
  }

  getAssignedUsername(): string
  {
    if (this.task.assigned)
    {
      return this.task.assigned.username;
    }
    else
    {
      return 'N/A';
    }
  }

  getSubTaskString(subTask: SubTask): string
  {
    if (!subTask)
    {
      return undefined;
    }
    return subTask.subTaskInfos && subTask.subTaskInfos.classValueString ?
           subTask.subTaskInfos.classValueString : subTask.document.moonNumber;
  }

  async archiveTask()
  {
    if (this.task.archived)
    {
      const answer = await this.askQuestion('Do you want to recover this task?');
      if (answer === 'yes')
      {
        try
        {
          this.busy = true;
          await this.taskService.recoverTask(this.task.id);
          this.busy = false;
          this.feedbackService.notifyMessage('Task recovered');
        }
        catch (err)
        {
          this.logger.trackTrace(`Unexpected error recovering task: ${err}`, Severity.Error);
          this.feedbackService.notifyError('Unexpected error recovering task');
        }
      }
    }
    else
    {
      const answer = await this.askQuestion('Do you really want to archive this task?');
      if (answer === 'yes')
      {
        try
        {
          this.busy = true;
          await this.taskService.archiveTask(this.task.id);
          this.busy = false;
          this.feedbackService.notifyMessage('Task archived');
        }
        catch (err)
        {
          this.logger.trackTrace(`Unexpected error archiving task: ${err}`, Severity.Error);
          this.feedbackService.notifyError('Unexpected error archiving task');
        }
      }
    }
  }

  private async askQuestion(question: string): Promise<'yes'|'no'>
  {
    return new Promise<'yes'|'no'>((res, rej) =>
    {
      this.dialog.open(AskDialogComponent, {data: {question: question}}).afterClosed().subscribe(async (answer) =>
      {
        res(answer === 'yes' ? 'yes' : 'no');
      });
    });
  }

  async toReview()
  {
    try
    {
      await this.taskService.changeStatus(this.selectedSubTask.id, 'InReview');
      this.eventHubService.refreshTasks.emit();
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error changing subtask status', err);
    }
    this.selectedSubTask.status = 'InReview';
  }

  close()
  {
    this.dialogRef.close();
  }

  getTaskStatus(task: MoonTask)
  {
    let status = <any>this.taskService.getTasksStatus(task);
    if (status === 'In Work')
    {
      status = 'In Design';
    }
    return status;
  }

  getOpenSubTasks(task: MoonTask): SubTask[]
  {
    return _.filter(task.subTasks, subTask => subTask.status !== 'Closed');
  }

  getSubTaskName(subTask: SubTask)
  {
    return subTask.subTaskInfos && subTask.subTaskInfos.classValueString ?
            subTask.subTaskInfos.classValueString :
            subTask.document.moonNumber;
  }

  getSubTaskStatus(st: SubTask)
  {
    let status = st.status;
    switch (status)
    {
      case 'ToDo':
        status = <any>'To Do';
        break;
      case 'InReview':
        status = <any>'In Review';
    }
    return status;
  }

  async editSubTask(st: SubTask)
  {
    await this.selectSubTask(st.id);
    this.editTask();
  }

  async editTemplate(taskDocTemplate: TaskDocumentTemplate)
  {
    await this.importToTask(this.task , taskDocTemplate.document);
    this.close();
  }

  async importToTask(task: MoonTask, template?: MoonDeskDocument)
  {
    this.busy = true;
    try
    {
      task = await this.taskService.queryTask(
        {
          taskId: task.id,
          includeDocumentContents: true,
          includeReviewAssignments: true,
        });
      this.eventHub.goToWorkImport.emit({ task: task, selectedDocumentId: template?.id });
    }
    catch (err)
    {
      this.feedbackService.notifyError('Task could not be loaded', err);
    }
    this.busy = false;
  }
}
