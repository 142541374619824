<div class="search">

  <!-- <mat-icon mat-button class="toggle-btn" *ngIf="lastFilter" (click)="toggleSplit()" [ngClass]="{'rotate-180': !toggleIcon, 'rotate-back': toggleIcon}">keyboard_arrow_down</mat-icon> -->

  <as-split direction="vertical" [useTransition]="true" [disabled]="false" [gutterSize]="getGutterSize()" (dragEnd)="resizeSplit($event.sizes)">

    <as-split-area class="filters" [size]="filtersSplitSize" [minSize]="15" [maxSize]="80">

      <div class="selectors">
        <class-selector
          id="class-selector-search"
          #classSelector
          (filterChanged)="filtersChanged($event)"
          (keyup.enter)="search()"
          [disabled]="bulkInProgress || busy"
          [showAllText]="true"
          [multiClassSelection]="true"
          [multiDocTypeSelection]="true"
          [autoSelect]="false"
          [showStatusFilter]="true"
          [preselection]="undefined"
          [ignoreSingleValueConfig]="true">
        </class-selector>
      </div>

    </as-split-area>

    <as-split-area [size]="resultsSplitSize" [minSize]="20" [maxSize]="85" >

      <div class="actions">
        <div class="search-actions">
          <button *ngIf="!busy" [disabled]="!dirtySearch" id="searchDocumentsButton" class="md-button md-button-highlighted" (click)="search()">Search</button>
          <button *ngIf="busy" class="md-button" (click)="searchCancel()">Cancel</button>
          <button *ngIf="!busy" class="md-textbutton reset-filters" e2e="resetFilter" (click)="resetFilters()">
            <mat-icon class="notranslate">refresh</mat-icon>
            <span>Reset Filters</span>
          </button>
        </div>
      </div>

      <div class="search-tags" *ngIf="libraryDocumentFilter">
        <div class="tag">
          <span class="filter-by-text">
            Documents with:
          </span>
          "<span class="tag-text" [matTooltip]="libraryDocumentFilter?.key">
             {{libraryDocumentFilter?.key}}
          </span>"
          <span class="remove-btn" (click)="removeLibraryFilter()">
            <mat-icon>clear</mat-icon>
          </span>
        </div>
      </div>

      <div class="results-options" *ngIf="filter">        
        <mat-checkbox *ngIf="!busy && this.filtersSearched" [disabled]="bulkInProgress" [indeterminate]="showCheckbox() == 2" [checked]="showCheckbox() == 1" (change)="checkChanged($event.checked)"></mat-checkbox>
        <div class="text" *ngIf="!busy && this.filtersSearched" [innerText]="getDocumentAmount()"></div>
        <mat-progress-spinner id="searchLoadSpinner" class="spinner" *ngIf="busy || deleting" diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>

        <div class="bulkactions" *ngIf="!busy && this.filtersSearched">
          <mat-icon (click)="bulkDownload()" [ngClass]="{'disabled' : selectedDocumentsLength()<=0 || bulkInProgress}" matTooltip="Share" matTooltipPosition="above">share</mat-icon>
          <mat-icon (click)="bulkEdit()" [ngClass]="{'disabled' : selectedDocumentsLength()<=0 || bulkInProgress}" matTooltip="Edit" matTooltipPosition="above" id="bulk-edit">edit</mat-icon>
          <mat-icon (click)="bulkDelete()" [ngClass]="{'disabled' : selectedDocumentsLength()<=0 || bulkInProgress}" matTooltip="Delete selection" matTooltipPosition="above" id="bulk-delete">delete</mat-icon>
          <mat-icon (click)="changeBulkInfos()" [ngClass]="{'disabled' : selectedDocumentsLength()<=0 || bulkInProgress}" matTooltip="Add classification" matTooltipPosition="above">filter_list</mat-icon>
          <mat-progress-spinner  id="loadSpinner" class="spinner" *ngIf="bulkInProgress" diameter="16" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>

        <div class="changeview" *ngIf="!busy && this.filtersSearched && filter">
          <button mat-icon-button [disabled]="viewStyle=='nopreview'" matTooltip="List view" matTooltipPosition="above" (click)="changeView('nopreview')">
            <mat-icon class="notranslate">view_list</mat-icon>
          </button>
          <button mat-icon-button [disabled]="viewStyle=='simple'" matTooltip="Simple view" matTooltipPosition="above" (click)="changeView('simple')">
            <mat-icon class="notranslate">view_module</mat-icon>
          </button>
          <button mat-icon-button [disabled]="viewStyle=='detailed'" matTooltip="Detail view" matTooltipPosition="above" (click)="changeView('detailed')">
            <mat-icon class="notranslate">view_stream</mat-icon>
          </button>
        </div>
      </div>

      <div class="results" *ngIf="documents && animationDelay && filter" [hidden]="deleting">
        <div [@listAnimation]="documents.length" [ngClass]="{'simple-view': viewStyle=='simple'}">
          <div *ngFor="let document of documents">
            <document-card
              [document]="document"
              [disabled]="busy || bulkInProgress"
              [simpleView]="viewStyle=='simple'"
              [nopreview]="viewStyle=='nopreview'"
              (documentChanged)="search()"
              (deleting)="deleting = $event">
            </document-card>
          </div>
        </div>

        <button id="showMoreDocumentsButton"
          mat-button
          *ngIf="!busy && filtersSearched && currentPage < totalPages"
          (click)="nextPage()">
          Show more...
        </button>

        <div id="noDocumentsFound" *ngIf="!busy && filtersSearched && documents && documents.length === 0">No documents found</div>
      </div>

    </as-split-area>

  </as-split>

</div>


