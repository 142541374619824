<div class="md-popup change-status">

    <div class="md-popup-header status-header">
      <mat-icon matTooltip="Close" [ngClass]="{'not-allowed': busy}" (click)="close()">close</mat-icon>
      <div class="title">Document status</div>
    </div>

    <div class="md-popup-actions status-actions" *ngIf="!busy">
      <button class="md-button" (click)="changeVersionStatus('Draft')" [disabled]="currentStatus === 'Draft'">Draft</button>
      <button class="md-button" (click)="changeVersionStatus('Approved')" [disabled]="currentStatus === 'Approved'">Approve</button>
    </div>

    <mat-progress-spinner *ngIf="busy" diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>

</div>
