import { Injectable } from '@angular/core';
import { RuleResult } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RuleDialogData, RulesDialogComponent } from '../pages/rules/rules-dialog/rules-dialog.component';
import {
  DocumentVersion,
  MoonDeskDocument,
  MoonTask,
  RuleConfiguration,
  TextsFormattingService
} from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

export interface TextContent
{
  text: string;
  wildCard: 'contains' | 'startsWith' | 'endsWith' | 'matchesWith';
  dirty?: boolean;

  // only in case of textContents[current - 1] exist
  logicalConector: '||' | '&&';
}

@Injectable({
  providedIn: 'root'
})
export class RuleHelperService {

  constructor(
    private dialog: MatDialog,
    private textsFormattingService: TextsFormattingService)
  {
  }

  runRulesAndShowResultsDialog(
    document: MoonDeskDocument,
    docVersion: DocumentVersion,
    filePath: string,
    suppressDuplicateCheck: boolean,
    suppressFontsCheck: boolean,
    confirm: boolean,
    task?: MoonTask): Promise<RuleResult[]>
  {
    return new Promise<RuleResult[]>((resolve, reject) =>
    {
      const dialogData: RuleDialogData =
      {
        docVersion: docVersion,
        document: document,
        filepath: filePath,
        task: task,
        confirm: confirm,
        suppressDuplicateCheck: suppressDuplicateCheck,
        suppressFontsCheck: suppressFontsCheck
      };
      if (!dialogData)
      {
        reject('No data');
      }
      else
      {
        const dialogRef = this.dialog.open(RulesDialogComponent, {
          width: '400px',
          minWidth: '260px',
          disableClose: true,
          data: dialogData
        });
        dialogRef.afterClosed().subscribe(result => resolve (result));
      }
    });
  }

  /**
   * @param document should be defined to be able to re-run rule
   * @param ruleConfigurations  should be defined to be able to re-run rule
   */
  showResultsDialog(ruleResults: RuleResult[], confirm: boolean, document?: MoonDeskDocument, rulesConfiguration?: RuleConfiguration[]): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      if (!ruleResults || ruleResults.length === 0)
      {
        resolve(true);
      }
      else
      {
        const dialogRef = this.dialog.open(RulesDialogComponent, {
          width: '400px',
          minWidth: '260px',
          disableClose: true,
          data: <RuleDialogData>
          {
            ruleResults: ruleResults,
            filepath: document?.workingCopy,
            document: document,
            rulesConfiguration: rulesConfiguration,
            confirm: confirm
          }
        });
        dialogRef.afterClosed().subscribe(result => resolve(result && result.length > 0));
      }
    });
  }

  getReadableCheckTextString(checkText: string): string
  {
    // Format check text
    const checkTextsStrings: string[] = [];
    const cleanCheckText = this.textsFormattingService.cleanHtml(checkText, false, true, false);
    const textContents = this.parseTextContents(cleanCheckText);

    textContents.forEach(tc =>
    {
      let redeableWildCard = '';
      switch (tc.wildCard)
      {
        case 'matchesWith':
          redeableWildCard = 'A text that matches with';
          break;
        case 'contains':
          redeableWildCard = 'A text that contains';
          break;
        case 'startsWith':
          redeableWildCard = 'A text that starts with';
          break;
        case 'endsWith':
          redeableWildCard = 'A text that ends with';
          break;
      }

      let redeableCondition = `${redeableWildCard} "${tc.text}"`; // ${checkCondition === 'exist' ? should : shouldNot} 

      if (tc.logicalConector)
      {
        const redeableLogicalConector = tc.logicalConector === '&&' ? 'and' : 'or';
        redeableCondition = `${redeableLogicalConector} ${redeableCondition}`;
      }
      checkTextsStrings.push(redeableCondition);
    });
    const textContentsString = checkTextsStrings.join(' ');

    // Final formatting
    let result = `${textContentsString}`;
    result = result.trim();
    result = result.charAt(0).toUpperCase() + result.slice(1);
    // result = this.textsFormattingService.cleanHtml(result, false, true, false);

    return result;
  }

  parseTextContents(checkText: string): TextContent[]
  {
    const result: TextContent[] = [];
    const splitedCheckText = checkText.split(/(\|\|)|(&&)/g).filter(Boolean); // .filter(Boolean) remove empty strings
    let logicalConector: '||' | '&&';
    splitedCheckText.forEach(checkConditionOrConector =>
    {
      const isLogicalConector = (/\|\||&&/g).test(checkConditionOrConector);
      if (isLogicalConector)
      {
        logicalConector = <'||' | '&&'>checkConditionOrConector;
      }
      else
      {
        result.push(
        {
          text: this.removeWildCards(checkConditionOrConector),
          wildCard: this.getWildcard(checkConditionOrConector),
          dirty: false,
          logicalConector: logicalConector
        });
      }
    });
    return result;
  }

  private getWildcard(text: string): 'contains' | 'startsWith' | 'endsWith' | 'matchesWith'
  {
    const regExpPartialWord = /wContains:/g;
    const regExpStartsWithWord = /wStarts:/g;
    const regExpEndsWithWord = /wEnds:/g;
    if (regExpPartialWord.test(text))
    {
      return 'contains';
    }
    else if (regExpStartsWithWord.test(text))
    {
      return 'startsWith';
    }
    else if (regExpEndsWithWord.test(text))
    {
      return 'endsWith';
    }
    else
    {
      return 'matchesWith';
    }
  }

  private removeWildCards(dirtyText: string): string
  {
    let cleanText = dirtyText.replace(/wContains:/g, '');
    cleanText = cleanText.replace(/wStarts:/g, '');
    cleanText = cleanText.replace(/wEnds:/g, '');
    return cleanText.trim();
  }
}
