<div class="md-popup class-selector-dialog classSelectorDialog-ext">
  <ng-container *ngIf="!busy ">
    <div class="md-popup-header">
      <!-- <mat-icon matTooltip="Close" (click)="close()" id="close-class-selector-dialog" [ngClass]="{'not-allowed': loading}">close</mat-icon> -->
      <div *ngIf="!loading" class="title" >Change document clasification</div>
    </div>

    <div *ngIf="loading">
      <mat-progress-spinner class="spinner" diameter="24" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>


    <div class="md-popup-content">
      <class-selector
        [hidden]="loading"
        (filterChanged)="filtersChanged($event)"
        [disabled]="loading"
        [showCRUD]="true"
        [multiClassSelection]="true"
        [multiDocTypeSelection]="false"
        [preselection]="preselection"
        [autoSelect]="false"
        [disableDocumentType]="disableDocType"
        [hideSearch]="true"
        [controlRequiredValues]="!disableRequiredValues"
        (classValueUpdated)="classValueUpdated()">
      </class-selector>
    </div>

    <div class="class-selector-mode" *ngIf="showMode">
      <div>Add</div>
      <mat-slide-toggle class="slide"
        placeholder="Add your selection to the documents, or overwrite the whole classification for each document"
        [(ngModel)]="overwriteAll"></mat-slide-toggle>
      <div>Overwrite</div>
    </div>

    <div class="md-popup-actions" *ngIf="!loading">
      <button class="md-button" #cancel (click)="close()" id="cancel-class-selector-dialog">Cancel</button>
      <button class="md-button md-button-outstanding" [disabled]="!canSave()" (click)="changeInfos()"
        id="ok-class-selector-dialog">Ok</button>
    </div>
  </ng-container>

  <div *ngIf="busy">
    <mat-progress-spinner class="spinner" *ngIf="busy " diameter="24" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>