import { Injectable, Inject } from '@angular/core';
import * as _ from 'underscore';
import {
        Rule,
        AuthService,
        DocumentAccessProvider,
        RuleIDs,
        MoonDeskDocument,
        DocumentVersion
        } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { RuleResult } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/rules/RuleResult';

@Injectable({
    providedIn: 'root'
  })
export class LocalLinksRule implements Rule
{
  constructor(@Inject('DocumentAccessProvider') private docAccessProvider: DocumentAccessProvider,
              private authService: AuthService)
              {}

  id = RuleIDs.LOCAL_LINKS;
  name = 'Local Links';
  actionName: string = 'Show';

  async runRule(document: MoonDeskDocument, docVersion: DocumentVersion, filePath: string): Promise<RuleResult>
  {
    const result: RuleResult =
    {
        rule: this,
        filePath: filePath,
        applied: !this.docAccessProvider.isDummy,
        error: false,
        description: `Check for local links`
    };
    if (!this.docAccessProvider.isDummy)
    {
      const localLinks: string[] = await this.docAccessProvider.getLocalLinks(filePath);
      const emptyLinks: string[] = _.filter(localLinks, localLink => localLink === 'unknown');
      if (emptyLinks && emptyLinks.length > 0)
      {
        result.description = `Found ${emptyLinks.length} missing link(s), please check your filename doesn't contain / signs`;
        result.error = true;
      }
    }
    return result;
  }


  action = (result: RuleResult) =>
  {
    if (result.data)
    {
        const amount = <number>result.data;
        alert(`todo show local links in document ${result.filePath}`);
    }
    else
    {
      //
    }
  }
  parseConfig = (result: any) => '';
}
