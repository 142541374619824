import { Injectable } from '@angular/core';
import { FieldMapRequest, FieldMapInput, FieldMapResult } from '../_models/smartmapping';
import { AuthService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Injectable({
  providedIn: 'root'
})
export class BackendSmartMappingService {

  constructor(private authService: AuthService) { }

  async searchMappings(inputs: FieldMapInput[], companyId: string): Promise<FieldMapResult[]> {
    const request: FieldMapRequest = {
      companyId: companyId,
      fieldMapInputs: inputs
    };
    const result = await this.authService.authPost<FieldMapResult[]>('/api/smartmapping/searchmappings', request);
    return result;
  }
}
