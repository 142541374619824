import { Injectable } from '@angular/core';
import { FilesystemService, PlatformFont } from './filesystem.service';
import { IllustratorService } from './illustrator.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as _ from 'underscore';
import { DownloadDocumentsComponent } from '../pages/_shared/download-documents/download-documents.component';
import { ExportOptions } from '../_models/export-options';
import { IllustratorConfigService } from './illustrator-config.service';
import {
        MoonDeskDocument,
        LoggingService,
        FeedbackService,
        DocumentNameService,
        EventHubService,
        ExportFile,
        ExportFilesIdentifiers,
        DocumentService,
        Progress,
        AuthService,
        DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE
        } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { Stopwatch } from 'ts-stopwatch';
import { LibraryCacheService, LibraryElement } from './library-cache.service';
import { ShareWarningDialogComponent } from '../pages/_shared/share-warning-dialog/share-warning-dialog.component';
import { FilepathService } from './filepath.service';


export interface ZipResult {
  zipPath: string;
  missingFonts: string[];
}

export interface ShareBasket {
  exportOptions: ExportOptions;
  exportFiles: ExportFile[];
  tempFolder: string;
  localDownloadFolder: string;
}

export interface ShareUploadProgress
{
  documents: MoonDeskDocument[];
  currentDocument: MoonDeskDocument;
  overallProgress: number;
  details: string;

  currentProgress: Progress;
}

interface DownloadAiAndCreateExportResult
{
  exportFiles: ExportFile[];
  /**
   * For documents that have missing fonts or links
   */
  cantUploadErrMsgs: string[];
}

@Injectable({
  providedIn: 'root'
})
export class DocumentSharingService {

  constructor(private logService: LoggingService,
              private fileService: FilesystemService,
              private filepathService: FilepathService,
              private illustratorService: IllustratorService,
              private nameService: DocumentNameService, private dialog: MatDialog,
              private configService: IllustratorConfigService,
              private feedbackService: FeedbackService,
              private libCacheService: LibraryCacheService,
              private docService: DocumentService,
              private eventHubService: EventHubService,
              private authService: AuthService)
  {
    this.eventHubService.openDownloadDialog.subscribe(documents => this.openDownloadDialog(documents));
  }

  openDownloadDialog(documents: MoonDeskDocument[]): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(DownloadDocumentsComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          documents: documents,
          service: this
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => resolve());
    });
  }

  async createShareObjects(basket: ShareBasket, document: MoonDeskDocument): Promise<ShareBasket>
  {
    const _basket = _.clone(basket);
    const home = this.fileService.getHomeDirectory();
    const download = this.fileService.join(home, 'Downloads');
    const tempFolder = await this.filepathService.getTempFolderPath();
    let folder: string;

    const now = new Date();
    const subFolder = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
    folder = this.fileService.join(tempFolder, subFolder);
    const downloadFolder = this.fileService.join(download, subFolder);

    await this.fileService.mkdir(folder);
    this.createUniqueDocNames([document]);

    const ex: ExportFile[] = await this.createExport(document, folder, _basket.exportOptions);
    _basket.exportFiles = ex;
    _basket.tempFolder = folder;
    _basket.localDownloadFolder = downloadFolder;
    return _basket;
  }

  /**
   * Download or Upload files according to basket.exportFiles[*].forUpload/forDownload attribute
   */
  async handleShareObjects(basket: ShareBasket, document: MoonDeskDocument,
    progressCallback: (progress: ShareUploadProgress) => void)
  {
    const exportsForUpload = basket.exportFiles.filter(ef => ef.forUpload);
    const exportsForDownload = basket.exportFiles.filter(ef => ef.forDownload);

    // Upload the export files that are not in the cloud yet
    if (basket.exportOptions.uploadFiles && exportsForUpload.length > 0)
    {
      document.newExports = exportsForUpload;
      await this.uploadExports([document], progressCallback);
    }

    // Download (move to download folder) export files and open folder if create local copies was selected in share popup
    if (basket.exportOptions.downloadFiles && exportsForDownload.length > 0)
    {
      await this.fileService.mkdir(basket.localDownloadFolder);

      exportsForDownload.forEach(async (ef) =>
      {
        const filename = this.fileService.filenameOf(ef.filePath);
        const destinationPath = this.fileService.join(basket.localDownloadFolder, filename);
        await this.fileService.copyFile(ef.filePath, destinationPath);
      });
      this.fileService.open(basket.localDownloadFolder);
    }

    if (this.fileService.exists(basket.tempFolder))
    {
      await this.fileService.deleteFolderRecursive(basket.tempFolder);
    }
  }

  async getExportOptions(): Promise<ExportOptions>
  {
    const config = await this.configService.getConfiguration();
    return config.exportOptions;
  }

  async setExportOptions(options: ExportOptions)
  {
    await this.configService.setExportOptions(options);
  }

  /**
   * Export of documents for the given documents
   * 1. Creates the folder (depending if it's one or more documents)
   * 2. Process one after the other (download + open + create files)
   * 3. Opens folder
   */
  async export(documents: MoonDeskDocument[], exportOptions: ExportOptions,
    progressCallback: (progress: ShareUploadProgress) => void): Promise<string>
  {
    let error: string;
    const trackEvent = 'DownloadDocumentsService/export';
    const stopwatch = new Stopwatch();
    stopwatch.start();
    try
    {
      this.logService.startTrackEvent(trackEvent);

      exportOptions.aiFile = true;
      // exportOptions.jpgFile = false;
      exportOptions.pngFile = false;

      await this.setExportOptions(exportOptions);

      const t0 = performance.now();

      this.logService.trackTrace('Starting export(s)...');
      this.illustratorService.suppressEvents = true;

      if (!documents || documents.length === 0)
      {
        error = 'No documents selected';
      }
      else
      {

        // Uncomment if want to warn about reuploading of already uploaded pdf/ai/zip
        // Also see comments in downloadAiAndCreateExport()

        // const alreadySharedWarnings: string[] = [];

        // documents.forEach(doc =>
        // {
        //   if (exportOptions.pdfFile && doc.latestVersion.exportFiles.includes(ExportFilesIdentifiers.Export_OutlinePdf))
        //   {
        //     alreadySharedWarnings.push(`#${doc.moonNumber} - PDF`);
        //   }
        //   if (exportOptions.outlineFile && doc.latestVersion.exportFiles.includes(ExportFilesIdentifiers.Export_OutlineAi))
        //   {
        //     alreadySharedWarnings.push(`#${doc.moonNumber} - Ai (Outline)`);
        //   }
        //   if (exportOptions.zipFile && doc.latestVersion.exportFiles.includes(ExportFilesIdentifiers.Export_PackageZip))
        //   {
        //     alreadySharedWarnings.push(`#${doc.moonNumber} - Zip`);
        //   }
        // });

        // if (alreadySharedWarnings.length > 0 && exportOptions.uploadFiles)
        // {
        //   const msg = 'The following document formats will not be shared as they were already shared for the current version:';
        //   const answer = await this.warning(msg, alreadySharedWarnings);
        //   if (!answer)
        //   {
        //     return 'Cancelled by user';
        //   }
        // }

        const tempFolder = await this.filepathService.getTempFolderPath();
        let folder: string;

        const now = new Date();
        let subFolder: string;

        this.createUniqueDocNames(documents);

        subFolder = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
        folder = this.fileService.join(tempFolder, subFolder);

        await this.fileService.mkdir(folder);

        // let's download and export each file
        let failedUploads: string[] = [];
        for (let i = 0 ; i < documents.length ; i++)
        {
          progressCallback({documents: documents, currentDocument: documents[i], overallProgress: 5, currentProgress: undefined,
                            details: `Preparing document ${i + 1}/${documents.length} for sharing`});
          const document = documents[i];
          if (document.cancelExport)
          {
            await this.fileService.deleteFolderRecursive(folder);
            return 'Cancelled by user';
          }
          const response = await this.downloadAiAndCreateExport(documents, document, folder, i, documents.length,
                                                          exportOptions, progressCallback);

          const ex = response.exportFiles;
          if (response.cantUploadErrMsgs)
          {
            const msg = `EXT - DocUploadExport - Failed - docId: ${document.id} - version: ${document.latestMayorVersionNumber}`;
            this.logService.trackEvent(msg, null, {'Error': response.cantUploadErrMsgs.join('; ')});
            failedUploads = _.union(failedUploads, response.cantUploadErrMsgs);
          }

          // const identity = this.authService.getCurrentIdentity();
          // const libraryFontsIsEnabled = identity.company.configuration?.libraryFontsEnabled;
          // if (exportOptions.zipFile && !document.cancelExport && !libraryFontsIsEnabled)
          // {
          //   const zipResult: ZipResult = await this.createZip(folder, document.uniqueExportName, '', true);
          //   ex.push({
          //     name: ExportFilesIdentifiers.Export_PackageZip,
          //     content: null,
          //     filePath: zipResult.zipPath,
          //     forUpload: true,
          //     forDownload: false
          //   });
          //   if (zipResult.missingFonts && zipResult.missingFonts.length > 0 && !document.cancelExport)
          //   {
          //     const msg = `The Zip of document #${document.number} will not have the following fonts as they could not be found:`;
          //     const answerContinue = await this.warning(msg, zipResult.missingFonts);
          //     if (!answerContinue)
          //     {
          //       await this.fileService.deleteFolderRecursive(folder);
          //       return 'Cancelled by user';
          //     }
          //   }
          // }
          document.newExports = ex;
        }

        if (failedUploads && failedUploads.length > 0)
        {
          const msg = 'The following document formats will not be shared because there are missing fonts and/or links (If they were recently installed, please restart Illustrator):';
          const answer = await this.warning(msg, failedUploads);
          if (!answer)
          {
            await this.fileService.deleteFolderRecursive(folder);
            return 'Cancelled by user';
          }
        }

        const t1 = performance.now();
        const seconds = Math.round((t1 - t0) / 1000);
        this.logService.trackTrace(`Share of ${documents.length} documents took ${seconds} seconds`);

        if (exportOptions.uploadFiles)
        {
          await this.uploadExports(documents, progressCallback);
        }

        // let's delete the package folder used to create the zip
        if (exportOptions.zipFile)
        {
          await this.deletePackageFolder(folder);
        }

        // Delete all the .ai files if the ai option is not selected
        if (!exportOptions.aiFile)
        {
          documents.forEach(async doc =>
          {
            const aiPath = this.fileService.join(folder, doc.uniqueExportName + '.ai');
            await this.fileService.unlink(aiPath);
          });
        }

        progressCallback({documents: documents, currentDocument: undefined, currentProgress: undefined,
                          details: 'Done', overallProgress: 100});

        if (exportOptions.downloadFiles)
        {
          const home = this.fileService.getHomeDirectory();
          const download = this.fileService.join(home, 'Downloads');
          const downloadFolder = this.fileService.join(download, subFolder);
          await this.fileService.moveFolder(folder, downloadFolder);
          this.fileService.open(downloadFolder);
        }
        else
        {
          this.fileService.deleteFolderRecursive(folder);
        }
      }
    }
    catch (err)
    {
      const errMsg = err.message ? err.message : 'Error sharing documents';
      error = errMsg;
      this.feedbackService.notifyError(errMsg, err);
    }
    finally
    {
      this.illustratorService.suppressEvents = false;
      stopwatch.stop();
      this.logService.stopTrackEvent(trackEvent, {['time']: stopwatch.getTime(), ['documentCount']: documents.length});
    }
    return error;
  }

  private async uploadExports(documents: MoonDeskDocument[],
    progressCallback: (progress: ShareUploadProgress) => void)
  {
    try
    {
      const relevantDocs = _.filter(documents, doc => doc.newExports && doc.newExports.length > 0);
      console.log('relevantDocs');
      console.log(relevantDocs);
      for (const doc of relevantDocs)
      {
        const i = documents.indexOf(doc) + 1;
        for (const newExp of doc.newExports)
        {
          if (doc.cancelExport)
          {
            throw new Error('Cancelled by user');
          }
          if (this.fileService.getFileSize(newExp.filePath) > DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE)
          {
            throw new Error(
              `Error uploading ${newExp.name},` +
              `the file exceeds the maximum allowed size of ${DOCUMENT_RAW_MAXIMUM_ALLOWED_SIZE / 1000000} MB`);
          }
          newExp.content = <Buffer> await this.fileService.readRawFileAsync(newExp.filePath);
        }
        console.log('uploading files for ' + doc.number);
        await this.docService.saveRawExportFilesForm(doc.latestVersion.id, doc.newExports, progress =>
        {
          let absPercent = (progress.percentage / documents.length * (i)) - 1;
          absPercent = absPercent > 1 ? absPercent : 0;
          progressCallback({documents: documents, currentDocument: doc, currentProgress: progress,
                            details: `Uploading document ${i}`, overallProgress: absPercent});
        });
        const msg = `EXT - DocUploadExport - Successful - docId: ${doc.id} - version: ${doc.latestMayorVersionNumber}`;
        const exportsNames = _.pluck(doc.newExports, 'name');
        this.logService.trackEvent(msg, null, {'ExportFiles': exportsNames.join('; ')});
      }
      for (const doc of relevantDocs)
      {
        doc.latestVersion.exportFiles = _.map(doc.newExports, newExport => newExport.name);
        doc.newExports = undefined;
      }
    }
    catch (err)
    {
      console.log('Error uploading export files');
      console.log(err);
      throw err;
    }

  }

  private createUniqueDocNames(documents: MoonDeskDocument[])
  {
    documents.forEach(l => l.uniqueExportName = '');

    for (let i = 0 ; i < documents.length ; i++)
    {
      let counter = 0;
      const rootName = this.nameService.createDocumentName('Export', documents[i], null, null);
      let name = rootName;
      while (_.any(documents, l => l.uniqueExportName && l.uniqueExportName.endsWith(name)))
      {
        ++counter;
        name = `${rootName}_${counter}`;
      }
      documents[i].uniqueExportName = name;
    }
  }

  private async downloadAiAndCreateExport(docs: MoonDeskDocument[], document: MoonDeskDocument, folder: string,
                                          index: number, total: number,
    exportOptions: ExportOptions, progressCallback: (progress: ShareUploadProgress) => void): Promise<DownloadAiAndCreateExportResult>
  {
    if (!exportOptions.downloadFiles)
    {
      exportOptions.downloadAiFile = false,
      exportOptions.downloadOutlineFile = false,
      exportOptions.downloadLightPdfFile = false,
      exportOptions.downloadPdfFile = false
    }

    const generateHighPdf = exportOptions.pdfFile || exportOptions.downloadPdfFile;
    const generateOutline = exportOptions.outlineFile || exportOptions.downloadOutlineFile;

    this.logService.trackTrace(`Share document no ${index}...`);
    const exportFiles: ExportFile[] = [];
    const cantUploadErrMsgs: string[] = [];
    const baseFileName = this.fileService.join(folder, document.uniqueExportName);
    const weightOfOneDocument = (100 / total) / 100; // the factor to reduce the percentage of one document
    const pastProgress = index * 100 * weightOfOneDocument;

    const dd = await this.libCacheService.getLibraryElement(document, document.latestVersion);
    let relativeProgress: number;
    if (dd.progress && dd.progress.percentage !== 100)
    {
      dd.progressEvent.subscribe((d: LibraryElement) =>
      {
        const downloadProgress = d.progress.percentage * 0.7; // 100% download is just 70% of this document
        relativeProgress = downloadProgress * weightOfOneDocument;
        progressCallback({documents: docs, currentDocument: document, currentProgress: undefined,
                          details: `Downloading library element...`, overallProgress: pastProgress + relativeProgress});
      });
      await this.libCacheService.downloadDocument(dd);
      // Make sure all linked files are in the subfolder Links
      await this.libCacheService.downloadLinkedFiles(document.latestVersion, baseFileName);
    }
    if (dd.progress && (dd.progress.percentage !== 100 || !dd.cachedFilePath || !this.fileService.exists(dd.cachedFilePath)))
    {
      throw Error(`Error retreiving file ${dd ? dd.cachedFilePath : 'unknown'}`);
    }

    const ext = document.latestVersion.fileType;

    const aiFilePath = `${baseFileName}${ext}`;
    const outlineFilePath = `${baseFileName}_curvas${ext}`;
    const pdfFilePath = `${baseFileName}.pdf`;
    const jpgFilePath = `${baseFileName}.jpg`;

    this.logService.trackTrace('Writing new file: ' + aiFilePath);
    relativeProgress = 80 * weightOfOneDocument;
    progressCallback({documents: docs, currentDocument: document, currentProgress: undefined,
                      details: `Saving ${index + 1} of ${total} files...`, overallProgress: pastProgress + relativeProgress});

    await this.fileService.copyFile(dd.cachedFilePath, aiFilePath);
    await this.illustratorService.openDocument(aiFilePath);

    const compatibilityEnabled = this.authService.getCurrentIdentity().company.configuration.saveAiWithCompatibility;
    await this.illustratorService.saveDocument(aiFilePath, compatibilityEnabled);


    // Check missing links
    let areMissingLinks = false;
    try
    {
      // await this.checkCorrectDocumentLinks(aiFilePath); Uncomment when can report the broken links
      await this.illustratorService.packageFile(aiFilePath, aiFilePath);
    }
    catch (err)
    {
      areMissingLinks = true;
    }

    // Check missing fonts
    let areMissingFonts = false;
    let missingFonts = [];
    if (this.authService.getCurrentIdentity().company.configuration.fontsControl)
    {
      missingFonts = await this.illustratorService.getDocMissingFonts(aiFilePath);
      areMissingFonts = missingFonts && missingFonts.length > 0;
    }

    relativeProgress = 95 * weightOfOneDocument;
    progressCallback({documents: docs, currentDocument: document, currentProgress: undefined,
                      details: `Creating outline ${index + 1} of ${total}...`, overallProgress: pastProgress + relativeProgress});

    if (generateOutline || generateHighPdf)
    {
      this.logService.trackTrace('Save as outlines:' + `${aiFilePath}` );
      await this.illustratorService.openDocument(aiFilePath);
      await this.illustratorService.exportOutline(aiFilePath, outlineFilePath);
      if (!generateHighPdf)
      {
        await this.illustratorService.closeDocument(outlineFilePath, false);
      }

      // Uncomment if want to prevent reuploading of already uploaded ai
      if (generateOutline) // && !document.latestVersion.exportFiles.includes(ExportFilesIdentifiers.Export_OutlineAi)
      {
        let error: boolean;
        if (areMissingFonts)
        {
          error = true;
          const errMsg = `#${document.moonNumber} - Ai (Outline) - Missing fonts: ${missingFonts.join('; ')}`;
          cantUploadErrMsgs.push(errMsg);
        }
        if (areMissingLinks)
        {
          error = true;
          const errMsg = `#${document.moonNumber} - Ai (Outline) - Missing link/s`;
          cantUploadErrMsgs.push(errMsg);
        }

        if (!error)
        {
          if (exportOptions.outlineFile)
          {
            exportFiles.push({
              name: ExportFilesIdentifiers.Export_OutlineAi,
              content: null,
              filePath: outlineFilePath,
              forUpload: true,
              forDownload: false
            });
          }
          if (exportOptions.downloadOutlineFile)
          {
            exportFiles.push({
              name: ExportFilesIdentifiers.Export_OutlineZip,
              content: null,
              filePath: outlineFilePath,
              forUpload: false,
              forDownload: true
            });
          }

        }
      }
    }

    // Uncomment if want to prevent reuploading of already uploaded pdf
    if (generateHighPdf) //  && !document.latestVersion.exportFiles.includes(ExportFilesIdentifiers.Export_OutlinePdf)
    {
      let error: boolean;
      if (areMissingFonts)
      {
        error = true;
        const errMsg = `#${document.moonNumber} - PDF (Outline) - Missing fonts: ${missingFonts.join('; ')}`;
        cantUploadErrMsgs.push(errMsg);
      }
      if (areMissingLinks)
      {
        error = true;
        const errMsg = `#${document.moonNumber} - PDF (Outline) - Missing link/s`;
        cantUploadErrMsgs.push(errMsg);
      }

      if (!error)
      {
        this.logService.trackTrace('Save as pdf:' + `${outlineFilePath}` );
        relativeProgress = 90 * weightOfOneDocument;
        progressCallback({documents: docs, currentDocument: document, currentProgress: undefined,
                          details: `Creating pdf ${index + 1} of ${total}...`, overallProgress: pastProgress + relativeProgress});
        await this.illustratorService.saveAsPdf2(outlineFilePath, pdfFilePath);
        await this.illustratorService.closeDocument(pdfFilePath, false);
        exportFiles.push({
            name: ExportFilesIdentifiers.Export_OutlinePdf,
            content: null,
            filePath: pdfFilePath,
            forUpload: exportOptions.pdfFile,
            forDownload: exportOptions.downloadPdfFile
          });
        
        if (!generateOutline)
        {
          await this.fileService.unlink(outlineFilePath);
        }
        if (this.illustratorService.isDocumentOpen(outlineFilePath))
        {
          await this.illustratorService.closeDocument(outlineFilePath, false);
        }
      }
    }


    if (this.illustratorService.isDocumentOpen(aiFilePath))
    {
      await this.illustratorService.closeDocument(aiFilePath, false);
    }

    if (this.illustratorService.isDocumentOpen(outlineFilePath))
    {
      await this.illustratorService.closeDocument(outlineFilePath, false);
    }


    relativeProgress = 100 * weightOfOneDocument;

    this.logService.trackTrace(`Share document no ${index} done`);

    const result: DownloadAiAndCreateExportResult =
    {
      exportFiles: exportFiles,
      cantUploadErrMsgs: cantUploadErrMsgs
    };
    return result;
  }

  async createExport(document: MoonDeskDocument, folder: string, exportOptions: ExportOptions): Promise<ExportFile[]>
  {
    const result: ExportFile[] = [];

    if (!exportOptions.downloadFiles)
    {
      exportOptions.downloadAiFile = false,
      exportOptions.downloadOutlineFile = false,
      exportOptions.downloadLightPdfFile = false,
      exportOptions.downloadPdfFile = false
    }

    const generateHighPdf = exportOptions.pdfFile || exportOptions.downloadPdfFile;
    const generateOutline = exportOptions.outlineFile || exportOptions.downloadOutlineFile;

    const ext = document.latestVersion.fileType;
    const baseFileName = this.fileService.join(folder, document.uniqueExportName);
    
    const aiFilePath = `${baseFileName}${ext}`;
    const outlineFilePath = `${baseFileName}_curvas${ext}`;
    const pdfFilePath = `${baseFileName}.pdf`;
    const lightPdfFilePath = `${baseFileName}_light.pdf`;

    this.logService.trackTrace('Writing new file: ' + aiFilePath);
    await this.fileService.copyFile(document.workingCopy, aiFilePath);
    await this.illustratorService.openDocument(aiFilePath);

    try
    {
      await this.illustratorService.packageFile(aiFilePath, aiFilePath);
    }
    catch (err)
    {
      // Suppressing error, we already told the user in the rule results earlier, and he/she continued anyways...
      // throw Error('Link Error: Could not find some linked files. Link images before export.');
    }

    if (exportOptions.downloadLightPdfFile)
    {
      await this.illustratorService.saveAsPdf2(aiFilePath, lightPdfFilePath, true);
      await this.illustratorService.closeDocument(lightPdfFilePath, false);
      result.push({
        name: ExportFilesIdentifiers.PreviewPdf,
        content: null,
        filePath: lightPdfFilePath,
        forUpload: false,
        forDownload: true
      });
    }

    if (generateHighPdf || generateOutline)
    {
      this.logService.trackTrace('Save as outlines:' + `${aiFilePath}` );
      await this.illustratorService.openDocument(aiFilePath);
      await this.illustratorService.exportOutline(aiFilePath, outlineFilePath);
      if (!generateHighPdf)
      {
        await this.illustratorService.closeDocument(outlineFilePath, false);
      }

      if (exportOptions.outlineFile)
      {
        result.push({
          name: ExportFilesIdentifiers.Export_OutlineAi,
          content: null,
          filePath: outlineFilePath,
          forUpload: true,
          forDownload: false
        });
      }

      if (exportOptions.downloadOutlineFile)
      {
        const zipResult: ZipResult = await this.createZip(folder, document.uniqueExportName, '_curvas', false);

        result.push({
          name: ExportFilesIdentifiers.Export_OutlineZip,
          content: null,
          filePath: zipResult.zipPath,
          forDownload: true,
          forUpload: false
        });
      }
    }

    if (generateHighPdf)
    {
      this.logService.trackTrace('Save as pdf:' + `${outlineFilePath}` );
      await this.illustratorService.saveAsPdf2(outlineFilePath, pdfFilePath);
      await this.illustratorService.closeDocument(pdfFilePath, false);

      result.push({
        name: ExportFilesIdentifiers.Export_OutlinePdf,
        content: null,
        filePath: pdfFilePath,
        forUpload: exportOptions.pdfFile,
        forDownload: exportOptions.downloadPdfFile
      });

      if (!generateOutline)
      {
        await this.fileService.unlink(outlineFilePath);
      }
      if (this.illustratorService.isDocumentOpen(outlineFilePath))
      {
        await this.illustratorService.closeDocument(outlineFilePath, false);
      }
    }

    const identity = this.authService.getCurrentIdentity();
    // const libraryFontsIsEnabled = identity.company.configuration?.libraryFontsEnabled;

    // if ((exportOptions.zipFile && !libraryFontsIsEnabled) || exportOptions.downloadAiFile)
    // {
    //   const zipResult: ZipResult = await this.createZip(folder, document.uniqueExportName, '', true);
    //   result.push({
    //     name: ExportFilesIdentifiers.Export_PackageZip,
    //     content: null,
    //     filePath: zipResult.zipPath,
    //     forUpload: exportOptions.zipFile && !libraryFontsIsEnabled,
    //     forDownload: exportOptions.downloadAiFile
    //   });
    // }

    if (this.illustratorService.isDocumentOpen(aiFilePath))
    {
      await this.illustratorService.closeDocument(aiFilePath, false);
    }
    return result;
  }

  /**
   * Locally generate a ZIP package similar to the one generated in the backend for .AI and .AI Outline files.
   * @param rootFolder Path to the folder containing the ai file and the links
   * @param uniqueExportName Moon export name configured for each company for the current document
   * @param filenameSufix (Optional) Necessary if you want to generate the zip file for the outline (_curvas)
   * @param includeFonts (Optional) If you want to include the fonts in the result zip
   * @returns the path to the zip file
   */
  private async createZip(rootFolder: string, uniqueExportName: string, filenameSufix?: string, includeFonts?: boolean): Promise<ZipResult>
  {
    const packageFolderName = 'package';
    const packageFolder = this.fileService.join(rootFolder, packageFolderName);
    await this.fileService.mkdir(packageFolder);

    const aiSourceFilePath = this.fileService.join(rootFolder, `${uniqueExportName}${filenameSufix}.ai`);
    const aiDestinationFilePath = this.fileService.join(packageFolder, `${uniqueExportName}${filenameSufix}.ai`);

    let missingFonts: string[] = []
    if (includeFonts)
    {
      missingFonts = await this.copyFonts(packageFolder, aiSourceFilePath);
    }

    await this.fileService.copyFile(aiSourceFilePath, aiDestinationFilePath);

    const linksFolderPath = this.fileService.join(rootFolder, uniqueExportName + 'Links');
    if (this.fileService.exists(linksFolderPath))
    {
      const packageLinksFolderPath = this.fileService.join(packageFolder, 'Links');
      await this.fileService.mkdir(packageLinksFolderPath);

      const links: string[] = this.fileService.readdirSync(linksFolderPath, false);
      for (const link of links)
      {
        const linkSourcePath = this.fileService.join(linksFolderPath, link);
        const linkDestinationPath = this.fileService.join(packageLinksFolderPath, link);
        await this.fileService.copyFile(linkSourcePath, linkDestinationPath);
      }
    }
    const zipFileName = `${uniqueExportName}${filenameSufix}.zip`;
    const zipFilePath = this.fileService.join(rootFolder, zipFileName);
    await this.fileService.zip(packageFolder, zipFilePath);
    if (!this.fileService.exists(zipFilePath))
    {
      this.feedbackService.notifyError('Error whean creating zip');
    }
    await this.deletePackageFolder(rootFolder);

    return {missingFonts: missingFonts, zipPath: zipFilePath};
  }

  private async deletePackageFolder(folder: string)
  {
    const packageFolderName = 'package';
    const packageFolder = this.fileService.join(folder, packageFolderName);
    if (this.fileService.exists(packageFolder))
    {
      await this.fileService.deleteFolderRecursive(packageFolder);
    }
  }

  private async copyFonts(packageFolder: string, documentUrl: string): Promise<string[]>
  {
    const plataformFonts: PlatformFont[] = await this.fileService.getPlatformFonts();
    const docFonts = await this.illustratorService.getFonts(documentUrl);
    const docFontsNames = docFonts.map(docFonts => docFonts.diskFontName);
    const filteredFonts = _.filter(plataformFonts, plataformFont => _.some(docFontsNames, docFont => docFont === plataformFont.name));
    let missingFonts: string[];

    if (filteredFonts && filteredFonts.length > 0)
    {
      const fontsFolder = this.fileService.join(packageFolder, 'Fonts');
      await this.fileService.mkdir(fontsFolder);

      for (const plataformForm of filteredFonts)
      {
        const fontFilePath = this.fileService.join(fontsFolder, plataformForm.name);
        await this.fileService.copyFile(plataformForm.filepath, fontFilePath);
      }

      missingFonts = _.difference(docFontsNames, _.pluck(filteredFonts, 'name'));
    }
    else if (docFontsNames)
    {
      missingFonts = docFontsNames;
    }
    if (missingFonts && missingFonts.length > 0)
    {
      return missingFonts;
    }
    return [];
  }

  private warning(message: string, list?: string[]): Promise<boolean>
  {
    return new Promise<boolean>((resolve, reject) =>
    {
      const dialogRef = this.dialog.open(ShareWarningDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data: {
          message: message,
          list: list
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result && result === 'ok'));
    });
  }
}
