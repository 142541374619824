<div class="findreplace">


  <div class="content" #findreplace>

    <div class="values" *ngFor="let findAndReplaceValue of findAndReplaceValues">

      <mat-form-field>
        <input matInput 
        e2e="findField"
        name="findValue" 
        placeholder="Find value"
        [(ngModel)]="findAndReplaceValue.findValue"
        (paste)="pastefromExcel($event.clipboardData.getData('text/plain'),this)"
        (change)="emit()">
        <button mat-icon-button matSuffix (click)="viewFindValue(findAndReplaceValue)" *ngIf="showViewIcons">
          <mat-icon class="notranslate">search</mat-icon>
        </button>
      </mat-form-field>
  
      <mat-form-field>
        <input matInput
        e2e="replaceField" 
        placeholder="Replace value"
        [(ngModel)]="findAndReplaceValue.replaceValue"
        (change)="emit()">
        <button mat-icon-button matSuffix (click)="viewReplaceValue(findAndReplaceValue)" *ngIf="showViewIcons">
          <mat-icon class="notranslate">search</mat-icon>
        </button>
      </mat-form-field>

      <div class="occurrences">{{findAndReplaceValue.result}}</div>

      <input class="check" e2e="wholeWordCheck" type="checkbox" matTooltip="Search whole word" matTooltipPosition="above" [(ngModel)]="findAndReplaceValue.wholeWord" (change)="emit()">

      <mat-icon class="remove" e2e="remove" matTooltip="Remove value" matTooltipPosition="above" (click)="removeValueFindReplace(findAndReplaceValue)">remove_circle_outline</mat-icon>

    </div>

    <div class="actions" >

      <button e2e="addNewFieldButton" class="md-textbutton add" (click)="addValuefindReplace()" [disabled]="canAddValue()">
        <mat-icon class="notranslate">add</mat-icon>
        <div>Add new field</div>
      </button>
    
      <button e2e="resetAllButton" class="md-textbutton refresh" (click)="removeValuesFindReplace()" *ngIf="showResetAll">
        <mat-icon class="notranslate">refresh</mat-icon>
        <div>Reset All</div>
      </button>

    </div>

  </div>

</div>
