<div class="document-card docCard-ext" [ngClass]="{'separation': !nopreview}">
  
  <!-- VIEWS WITH PREVIEW IMAGE -->

  <div class="checkbox" *ngIf="!nopreview" [ngClass]="{'select': !simpleView}">
    <mat-checkbox [(ngModel)]="document.selected" e2e="checkDocument" [id]="'check'+document.moonNumber" (change)="checkedChanged()"></mat-checkbox>
  </div>

  <div class="preview" *ngIf="!nopreview">
    <div class="version-status" [matTooltip]="getStatusTooltip()" matTooltipPosition="above">
      <div class="draft" id="documentDraft" *ngIf="document.latestVersion.versionStatus === 'Draft'">Draft</div>
      <div class="draft" id="documentDraft" *ngIf="document.latestVersion.versionStatus === 'Preapproved'">Preapproved</div>
      <div class="approved" id="documentApproved" *ngIf="document.latestVersion.versionStatus === 'Approved'">Approved</div>
    </div>
    <div class="overlay">
    <div class="pendingTasks" [libTooltip]="pendingTask" *ngIf="document.pendingTasksNames && document.pendingTasksNames.length > 0" >
        <span class="count-pending">{{document.pendingTasksNames.length}}</span>
      </div>
      <div class="openviewer" id="openviewer">
        <mat-icon class="notranslate" id="openviewer-icon" (click)="showBig()">zoom_in</mat-icon>
      </div>
    </div>
    <img [src]="document.previewUrl">
  </div>

  <div class="data" *ngIf="!simpleView && !nopreview">
    
    <div class="title">
      <div class="classtext" id="titleDocument" [innerText]="document.classValuesString" [matTooltip]="document.classValuesString" matTooltipPosition="above"></div>
      <div class="doctype"  [innerText]="document.docTypeName" matTooltip="Document Type"></div> <mat-icon *ngIf="canReclassify && !busy" (click)="changeInfos()">edit</mat-icon>
    </div>

    <div class="info">
      <div class="names">
        <div class="name" *ngFor="let field of getFieldValues()" [matTooltip]="field.fieldType.name" matTooltipPosition="above">{{field.fieldType.name}}</div>
        <div class="name">Latest</div>
      </div>
      <div class="values">
        <div class="value" *ngFor="let field of getFieldValues()" [matTooltip]="field.value" matTooltipPosition="above">{{field.value}}</div>
        <div class="value" [innerText]="document.latestVersionString" [matTooltip]="document.latestVersionString" matTooltipPosition="above"></div>
      </div>  
    </div>

    <div class="moon-number" matTooltip="Document Number" (click)="copyMoonNumber()">{{document.moonNumber}}</div>

    <div class="tags" [id]="'tags'+document.moonNumber">
      <div *ngFor="let tag of document.latestVersion.tagsValues">
        <div [matTooltip]="tag" matTooltipPosition="above" [id]="'tag'+tag">{{tag}}</div>
      </div>
    </div>

  </div>

  <div class="actions" *ngIf="!simpleView && !nopreview && !busy">
    <mat-icon class="notranslate" (click)="showBig()" [ngClass]="{'disabled' : disabled}" matTooltip="Show versions" matTooltipPosition="above">pageview</mat-icon>
    <mat-icon class="notranslate" (click)="edit()" [ngClass]="{'disabled' : disabled}" matTooltip="Edit" matTooltipPosition="above" [id]="'edit'+document.moonNumber">edit</mat-icon>
    <mat-icon class="notranslate" (click)="deleteDocument()" [ngClass]="{'disabled' : disabled}" matTooltip="Delete" matTooltipPosition="above" [id]="'delete'+document.moonNumber">delete</mat-icon>
    <mat-icon class="notranslate" (click)="openDownloadDialog()" [ngClass]="{'disabled' : disabled}" matTooltip="Share" matTooltipPosition="above">share</mat-icon>
    <app-download-dropdown 
      [versionId]="document.latestVersion.versionId"
      [exportFiles]="document.latestVersion.exportFiles">
    </app-download-dropdown>
  </div>

  <div class="actions" *ngIf="busy">
    <mat-progress-spinner class="spinner" diameter="18" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>



  <!-- LIST VIEW -->

  <div class="list-checkbox" *ngIf="nopreview">
    <mat-checkbox [(ngModel)]="document.selected" e2e="checkDocument"  [id]="'check'+document.moonNumber" (change)="checkedChanged()"></mat-checkbox>
  </div>

  <div class="list-view" *ngIf="nopreview">
    <div class="content">
      <div class="info"> 
        <div class="version-status" [matTooltip]="getStatusTooltip()" matTooltipPosition="above">
          <div class="draft" id="documentDraft" *ngIf="document.latestVersion.versionStatus === 'Draft'">Draft</div>
          <div class="draft" id="documentPreapproved" *ngIf="document.latestVersion.versionStatus === 'Preapproved'">Pre</div>
          <div class="approved" id="documentApproved" *ngIf="document.latestVersion.versionStatus === 'Approved'">Appr</div>
        </div>
        <div class="pendingTasks" [ngClass]="{ 'no-pendings' : document && document.pendingTasksNames && document.pendingTasksNames.length === 0 }">
          <span [libTooltip]="pendingTask" *ngIf="document.pendingTasksNames && document.pendingTasksNames.length > 0"  class="pending-count">{{document.pendingTasksNames.length}}</span>
        </div>
        <mat-icon class="notranslate edit-docinfos" *ngIf="canReclassify && !busy" (click)="changeInfos()">edit</mat-icon>
        <div class="doctype" matTooltip="Document Type"> &nbsp; {{document.docTypeName}} </div>
        <div class="classtext" id="titleDocument" [innerText]="document.classValuesString" [matTooltip]="document.classValuesString" matTooltipPosition="above"></div>  
      </div>
      <div class="utilities">
        <mat-icon class="notranslate zoom" *ngIf="nopreview" (click)="showBig()">zoom_in</mat-icon> 
        <div class="info" [libTooltip]="docInfo">i</div>
      </div> 
    </div>    
  </div>

  <!--Moondesk Tooltip Content-->
  <ng-template #docInfo>
    <div class="md-tooltipTitle">{{document.docTypeName}}</div>
    <div class="md-tooltipText"><strong>Latest:</strong> {{document.latestVersionString}}</div> 
    <div class="md-tooltipText"><strong>Id:</strong> {{document.moonNumber}}</div>  
    <div class="md-tooltipText"><strong>Tags:</strong> {{getTagsString()}}</div>  
  </ng-template>

  <ng-template #pendingTask>
    <div class="md-tooltipTitle">Pending tasks {{document.pendingTasksNames.length}}:</div>
    <div class="md-tooltipText" *ngFor="let prt of document.pendingTasksNames"># {{prt}}</div>
  </ng-template>

</div>
