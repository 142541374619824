import { Component, OnInit, Input } from '@angular/core';
import { CurrentDocumentService } from '../current-document-manager/current-document.service';
import { SaveDocumentService, SaveDocumentOptions, SaveDocumentStatus, SaveDocumentState } from './save-document.service';
import { WorkManagerService } from '../work-manager/work-manager.service';
import * as _ from 'underscore';
import { MoonTask } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';

@Component({
  selector: 'save-document',
  templateUrl: './save-document.component.html',
  styleUrls: ['./save-document.component.scss']
})
export class SaveDocumentComponent implements OnInit
{
  @Input() mode: 'Import' | 'Edit';
  @Input() hidden: boolean;
  @Input() keepTags: boolean;

  saveAndClose: boolean = true;

  constructor(private currentDocService: CurrentDocumentService,
    private docSaveService: SaveDocumentService,
    private workManager: WorkManagerService)
  {
  }

  ngOnInit() {
  }

  canSaveNewDocument(): boolean
  {
    return this.docSaveService.canSaveNewDocument(this.currentDocService.importDocument);
  }

  canSaveNewVersion(): boolean
  {
    return this.docSaveService.canSaveNewVersion(this.currentDocService.editDocument);
  }

  saveNewDocument()
  {
    const options: SaveDocumentOptions =
    {
      mode: 'NewDoc',
      saveAndClose: this.saveAndClose,
      suppressCompleteClassSelection: true
    };
    return this.docSaveService.saveDocument(this.currentDocService.importDocument, this.workManager.getCurrentTask, options);
  }

  saveNewVersion()
  {
    const options: SaveDocumentOptions =
    {
      suppressDuplicateRule: true,
      suppressRuleConfirmation: false,
      suppressShareDialog: false,
      suppressVersionCheck: false,
      mode: 'NewVersion',
      saveAndClose: this.saveAndClose,
      suppressCompleteClassSelection: true
    };
    if (!this.keepTags)
    {
      this.currentDocService.editDocument.editingVersion.documentTags = [];
    }
    const relatedTasks = this.getRelatedTasks();
    return this.docSaveService.saveDocument(this.currentDocService.editDocument, this.workManager.getCurrentTask, options, relatedTasks);
  }

  private getRelatedTasks(): MoonTask[]
  {
    const currentDoc = this.currentDocService.editDocument;
    const queue = this.workManager.getQueue();

    if (!currentDoc || !queue || queue.length === 0)
    {
      return [];
    }
    const activeElement = _.find(queue, q => q.document.id === currentDoc.id);
    if (!activeElement || !activeElement.tasks)
    {
      return [];
    }
    return activeElement.tasks;
  }

  showUpload(): boolean
  {
    if (!this.saveDocStatus) { return false; }
    return this.saveDocStatus.status == SaveDocumentState.preparingupload || this.saveDocStatus.status == SaveDocumentState.uploading;
  }

  showDetailedUpload(): boolean
  {
    return this.saveDocStatus != undefined && this.saveDocStatus.currentUploadProgress != undefined;
  }

  detailedProgressPercentage(): number
  {
    const progress = this.saveDocStatus.currentUploadProgress;
    if (progress)
    {
      return progress.percentage;
    }
    return 0;
  }

  detailedProgressText(): string
  {
    const progress = this.saveDocStatus.currentUploadProgress;
    if (progress)
    {
      const speed = progress.speedKBps < 1024
                    ? `${progress.speedKBps} KB/s`
                    : `${progress.speedMBps} MB/s`;
      const totalProgress = progress.totalKB < 1024
                    ? `${progress.loadedKB}/${progress.totalKB} KB`
                    : `${progress.loadedMB}/${progress.totalMB} MB`;

      return `${totalProgress} (${speed})`;
    }
    return undefined;
  }

  get saveDocStatus(): SaveDocumentStatus
  {
    return this.docSaveService.status;
  }
}

