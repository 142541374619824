<div class="acceptlegals">
    <div class="header">
        <img [src]="'./assets/logo.png'" alt="Moondesk logo">
    </div>
    <fieldset class="legal-fields">
        <legend class="label">Please accept</legend>
        <div class="checkboxes">
            <!-- PRIVACY POLICY -->
            <mat-checkbox class="checkbox" e2e="checkboxPrivacy" [(ngModel)]="acceptedPrivacy">
                <span class="text">I accept the </span>
                <span class="reference" matTooltipPosition="above" matTooltip="Click to open in browser ..." (click)="goToPrivacy()">Privacy Policy</span>
            </mat-checkbox>
            <!-- TERMS AND CONDITIONS-->
            <mat-checkbox class="checkbox" e2e="checkboxTerms" [(ngModel)]="acceptedTerms">
                <span class="text">I accept the</span>
                <a class="reference" matTooltipPosition="above" matTooltip="Click to open in browser ..." (click)="goToTermsAndConditions()">Terms and Conditions</a>
            </mat-checkbox>
        </div>
    </fieldset>
    
    <div class="actions">
        <button class="okButton" e2e="acceptButton" [disabled]="!allChecked()" (click)="close()">Ok</button>
    </div>
</div>