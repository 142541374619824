<div class="md-popup">

    <div class="md-popup-header">
      <!-- <mat-icon matTooltip="Close" (click)="close()">close</mat-icon> -->
      <div class="title">Company</div>
    </div>
  
    <div class="md-popup-content">
      <div *ngIf="title">{{title}}</div>
      <mat-form-field>
        <input matInput placeholder="Name" [(ngModel)]="companyName">
      </mat-form-field>  
      <mat-checkbox matInput [(ngModel)]="commModule">Communication Module</mat-checkbox>
    </div>
  
    <div mat-dialog-actions class="md-popup-actions">
      <button class="md-button md-button-outstanding" [disabled]="!companyName" (click)="ok()">Ok</button>
      <button class="md-button" (click)="close()">Cancel</button>
    </div>
  
</div>