import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import * as _ from 'underscore';

import { NewMessageComponent } from '../new-message/new-message.component';
import { NotificationConnectionStates, NotificationHubService, LoggingService, EventHubService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnInit
{
  state: NotificationConnectionStates;
  hasNewMessages: boolean;
  overlayRef: OverlayRef;

  @ViewChild('bell', { static: true }) bell: ElementRef;

  @Input() notificationPopupClass: string;

  constructor(private notificationService: NotificationHubService,
    private overlay: Overlay, private log: LoggingService,
    private eventHubService: EventHubService)
  {
  }

  ngOnInit()
  {
    this.eventHubService.taskMessageClicked.subscribe(() => this.closeOverlay());
    this.notificationService.messageUpdate.subscribe(m => this.updateHasNewMessages());
    this.notificationService.connectedChange.subscribe(state => this.state = state);
    this.state = this.notificationService.connectionState;
    this.updateHasNewMessages();
  }

  private updateHasNewMessages()
  {
    this.log.trackTrace('NotificationBellComponent/updateHasNewMessages');
    this.hasNewMessages = this.notificationService.allMessages &&
                          _.any(this.notificationService.allMessages, msg => !msg.read && msg.visible === true);
  }

  closeOverlay()
  {
    if (this.overlayRef)
    {
      this.toggleOverlay();
    }
  }

  toggleOverlay()
  {
    if (this.overlayRef)
    {
      this.overlayRef.detach();
      this.overlayRef = undefined;
    }
    else
    {
      const connectedStrategy = this.overlay.position()
        .flexibleConnectedTo(this.bell.nativeElement)
        .withPositions([{originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'}]);
      this.overlayRef = this.overlay.create({positionStrategy: connectedStrategy, hasBackdrop: true});
      this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());
      const userProfilePortal = new ComponentPortal(NewMessageComponent);
      const msgListComponent = this.overlayRef.attach(userProfilePortal).instance;
      msgListComponent.notificationPopupClass = 'dummy2';
      if (this.notificationPopupClass)
      {
        msgListComponent.notificationPopupClass = this.notificationPopupClass;
      }
    }
  }

  tooltip(): string
  {
    switch (this.state)
    {
      case NotificationConnectionStates.initializing:
        return 'Initializing';
      case NotificationConnectionStates.connected:
        return this.hasNewMessages ? 'You have unread messages' : 'You have no unread messages';
      case NotificationConnectionStates.disconnected:
        return 'Disconnected';
      case NotificationConnectionStates.connecting:
        return 'Connecting';
      case NotificationConnectionStates.failed:
        return 'Connection failed';
    }
    return 'Unknown status';
  }
}
