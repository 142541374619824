import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-accept-legals',
  templateUrl: './accept-legals.component.html',
  styleUrls: ['./accept-legals.component.scss']
})
export class AcceptLegalsComponent
{

  acceptedPrivacy: boolean = false;
  acceptedTerms: boolean = false;

  constructor(public dialogRef: MatDialogRef<AcceptLegalsComponent>,
              private navService: NavigationService)
  { }

  goToPrivacy()
  {
    this.navService.privacy();
  }

  goToTermsAndConditions()
  {
    this.navService.termsAndConditions();
  }

  allChecked(): boolean
  {
    return this.acceptedPrivacy && this.acceptedTerms;
  }

  close()
  {
    if (this.allChecked())
    {
      this.dialogRef.close();
    }
  }
}
