import { Component, OnInit } from '@angular/core';
import { PushMessage,
         NotificationHubService,
         LoggingService,
         EventHubService,
         FeedbackService,
         SubTaskMessage,
         SubTask,
         MoonTaskType} from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import * as _ from 'underscore';
import { IllustratorService } from 'src/app/services/illustrator.service';
import { BACKEND_INFO } from 'src/app/services/illustrator-config.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit
{
  messages: PushMessage[] = [];
  notificationPopupClass: string = 'dummy';
  updating: boolean;
  constructor(
    private notificationService: NotificationHubService,
    private log: LoggingService,
    private eventHubService: EventHubService,
    private feedbackService: FeedbackService,
    private illService: IllustratorService)
    { }

  ngOnInit()
  {
    this.notificationService.messageUpdate.subscribe(() => this.getMessagesFromService());
    this.getMessagesFromService();
  }

  private getMessagesFromService()
  {
    this.log.trackTrace('NewMessageComponent/getMessagesFromService');
    let msgs = this.notificationService.allMessages.filter(m => m.visible === true);
    msgs = _.sortBy(msgs , msg => msg.timestampUtc);
    msgs.reverse();
    this.messages = msgs;
  }

  async readAll()
  {
    await this.notificationService.allMessagesRead();
  }

  async archiveAll()
  {
    await this.notificationService.archiveAllMessages();
  }

  async markAsRead(msg: PushMessage)
  {
    await this.notificationService.markAsRead([msg]);
  }

  async markAsUnRead(msg: PushMessage)
  {
    await this.notificationService.markAsUnRead([msg]);
  }

  async archive(msg: PushMessage)
  {
    await this.notificationService.archive([msg]);
  }

  async refresh()
  {
    this.updating = true;
    try
    {
      await this.notificationService.refreshMessages(true);
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error updating messages', err);
    }
    this.updating = false;
  }

  taskMessageClicked(msg: PushMessage)
  {
    this.markAsRead(msg);
    if (msg.taskNotification.task.taskType === MoonTaskType.DocumentShare)
    {
      const url = BACKEND_INFO.ipAddress + '/web/company/' + msg.taskNotification.task.companyId + '/share/' + msg.taskNotification.task.id;
      this.illService.openUrl(url);
    }
    else
    {
      this.eventHubService.taskMessageClicked.emit(msg.taskNotification);
    }
  }

  supportMessageClicked(msg: PushMessage)
  {
    this.markAsRead(msg);
    this.eventHubService.supportMessageClicked.emit(msg.supportMessage);
  }

  getSenderName(msg: SubTaskMessage): string
  {
    if (msg.user)
    {
      return msg.user.username;
    }
    if (msg.reviewer)
    {
      return msg.reviewer.name;
    }
    return '--';
  }

  getSubTaskName(subTask: SubTask): string
  {
    return subTask.subTaskInfos && subTask.subTaskInfos.classValueString ? subTask.subTaskInfos.classValueString :
                                                                            subTask.document.moonNumber;
  }
}
