import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService, FeedbackService, User, OAuthService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { IllustratorService } from '../../../services/illustrator.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy
{
  busy: boolean;
  username: string;
  password: string;
  error: string;

  showPasswordRecovery: boolean;

  waitingForOauth: boolean;
  oAuthCancelled: boolean;

  constructor(private authService: AuthService,
              private feedbackService: FeedbackService,
              private ilstService: IllustratorService,
              private oAuthService: OAuthService)
  {
  }
  sub: Subscription;
  isDark: boolean;
  async ngOnInit()
  {
    this.isDark = await this.ilstService.isDarkTheme();
    this.sub = this.ilstService.ThemeChangedEvent.subscribe(isDark => this.isDark = isDark);
  }

  ngOnDestroy(): void
  {
    this.sub.unsubscribe();
  }

  async signIn()
  {
    if (this.busy)
    {
      return;
    }
    try
    {
      this.busy = true;
      this.error = undefined;
      await this.authService.login(this.username, this.password);
    }
    catch (err)
    {
      console.log(err);
      this.error = err.error ? err.error : 'Error logging in';
    }
    finally
    {
      this.busy = false;
    }
  }

  keyup(event: KeyboardEvent)
  {
    if (event.key === 'Enter')
    {
      this.signIn();
    }
  }

  async forgotPassword()
  {
    try
    {
      if (!this.username)
      {
        this.feedbackService.notifyMessage('Please write your username or email address');
        return;
      }
      this.error = undefined;
      const user: User = <any>{};
      if (this.username.indexOf('@') > 0)
      {
        user.email = this.username;
      }
      else
      {
        user.username = this.username;
      }
      const response = await this.authService.sendPasswordResetMail(user);
      if (!response.error)
      {
        this.feedbackService.notifyMessage(`Email was sent to ${response.emailedTo}`);
        this.error = undefined;
        this.showPasswordRecovery = false;
      }
      else
      {
        this.error = response.error;
      }
    }
    catch (err)
    {
      this.error = 'Error requesting password reset';
    }
  }

  togglePasswordRecovery()
  {
    this.error = undefined;
    this.showPasswordRecovery = !this.showPasswordRecovery;
  }

  async signInMicrosoft()
  {
    if (this.waitingForOauth)
    {
      return;
    }
    try
    {
      this.waitingForOauth = true;
      this.oAuthCancelled = false;
      const state = Math.round(Math.random() * 10000);
      const oauthUrl = await this.oAuthService.getMicrosoftLoginUrl(`${state}`, null, false);
      this.ilstService.openUrl(oauthUrl);
      while (!this.oAuthCancelled)
      {
        await this.wait(2000);
        const result = await this.oAuthService.isOauthLoginDone('Microsoft', `${state}`);
        if (result.user)
        {
          await this.authService.handleLogin(result.user, undefined);
          return;
        }
        else
        {
          console.log(`OAuth not done: ${result.error}`);
        }
      }
    }
    catch (err)
    {
      this.feedbackService.notifyError('Error during signin with Microsoft', err);
    }
    finally
    {
      this.waitingForOauth = false;
    }
  }

  cancelOauthLogin()
  {
    this.oAuthCancelled = true;
  }

  private wait(ms: number): Promise<void>
  {
    return new Promise<void>(res => setTimeout(() => res(), ms));
  }
}
