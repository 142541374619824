<div class="md-popup paste-content-popup">
    <span class="title">Select your font styles to paste normal and bold content</span>
    <div class="normal-selector">

        <mat-form-field  class="form-field">
            <mat-label>Normal font</mat-label>
            <mat-select panelClass="font-selection-panel" disableOptionCentering [(value)]="normalFontSelected" (openedChange)="normalFontSearchCtrl.setValue('');  applyFilterNormal(''); searchInput.focus()">
                <div class="font-selection-panel-header">
                    <span class="text-input">
                        <input
                            #searchInput
                            class="font-search"
                            type="text"
                            disableOptionCentering
                            (keyup)="applyFilterNormal($event.target.value)"
                            [formControl]="normalFontSearchCtrl"
                            [placeholder]="'Search ...'">
                        <span class="close-icon" *ngIf="normalFontSearchCtrl.value" matSuffix (click)="normalFontSearchCtrl.setValue(''); applyFilterNormal(''); searchInput.focus()">
                            <mat-icon class="notranslate">close</mat-icon>
                        </span>
                    </span>
                </div>
                <div class="font-selection-panel-body">
                    <mat-option *ngFor="let normalFont of filteredNormalFonts" [value]="normalFont">
                        <span class="font-family">{{normalFont.family}}</span>
                        <span class="split">&nbsp;</span>
                        <span class="name">{{normalFont.style}}</span>
                    </mat-option>
                    <div class="no-results" *ngIf="!filteredNormalFonts || filteredNormalFonts.length === 0">
                        <span translate>No results</span>
                    </div>
                </div>
            </mat-select>
        </mat-form-field>

    </div>

    <div class="bold-selector">
        <mat-form-field  class="form-field">
            <mat-label class="bold">Bold font</mat-label>
            <mat-select panelClass="font-selection-panel" disableOptionCentering [(value)]="boldFontSelected" (openedChange)="boldFontSearchCtrl.setValue('');  applyFilterBold(''); searchInput.focus()">
                <div class="font-selection-panel-header">
                    <span class="text-input">
                        <input
                            #searchInput
                            class="font-search"
                            type="text"
                            disableOptionCentering
                            (keyup)="applyFilterBold($event.target.value)"
                            [formControl]="boldFontSearchCtrl"
                            [placeholder]="'Search ...'">
                        <span class="close-icon" *ngIf="boldFontSearchCtrl.value" matSuffix (click)="boldFontSearchCtrl.setValue(''); applyFilterBold(''); searchInput.focus()">
                            <mat-icon class="notranslate">close</mat-icon>
                        </span>
                    </span>
                </div>
                <div class="font-selection-panel-body">
                    <mat-option *ngFor="let boldFont of filteredBoldFonts" [value]="boldFont">
                        <span class="font-family">{{boldFont.family}}</span>
                        <span class="split">&nbsp;</span>
                        <span class="name bold">{{boldFont.style}}</span>
                    </mat-option>
                    <div class="no-results" *ngIf="!filteredBoldFonts || filteredBoldFonts.length === 0">
                        <span translate>No results</span>
                    </div>
                </div>
            </mat-select>
        </mat-form-field>
        
    </div>

    <div class="actions">
        <button class="md-button" (click)="cancel()">Cancel</button>
        <button class="md-button md-button-outstanding" (click)="confirm()">Confirm</button>
    </div>
    
</div>