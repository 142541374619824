import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { User, AuthService, FeedbackService, LoggingService } from '../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';


@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit
{
  busy: boolean;
  error: any;
  user: User = {};
  successfullRegister: boolean = false;
  constructor(
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private logger: LoggingService) { }

  ngOnInit() {
  }

  async register()
  {
    this.successfullRegister = false;
    if (this.busy)
    {
      return;
    }
    try {
      this.busy = true;
      this.error = undefined;
      const response = await this.authService.registerUserWithVerification(this.user);
      this.error = response.error;
      if (response.user)
      {
        this.successfullRegister = true;
        this.logger.trackEvent('User has been registered', null, {['username']: this.user.username});
      }
    }
    catch (err)
    {
      this.successfullRegister = false;
      this.error = err.error ? err.error : 'Unexpected error on register';
      // this.feedbackService.notifyError(err.error ? err.error : 'Unexpected error on register');
    }
    finally
    {
      this.busy = false;
    }
  }

  setRegister(sucessRegister: boolean)
  {
    this.successfullRegister = sucessRegister;
  }

  resetForm()
  {
  this.successfullRegister = false;
  this.user = {};
  }
}
