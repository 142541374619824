import { Injectable, EventEmitter } from '@angular/core';
import { IllustratorService } from './illustrator.service';
import { Configuration, FontSelection } from '../_models/Configuration';
import { FilesystemService } from './filesystem.service';
import { ExportOptions } from '../_models/export-options';

import {
        PlatformInfoProvider,
        UserProvider,
        User,
        Reviewer,
        FrontendInfo,
        BackendInfo
        } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MoonDeskConfigService } from './moon-desk-config.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export const tempFolderName = 'MoonDeskTemp';
const backendConfigName: string = 'BackendConfig';
const tempfolderConfigName: string = 'TempfolderConfigName';
const autoRefresh: string = 'AutoRefresh';
const exportOptionsJson: string = 'ExportOptions';
const fontSelectionJson: string = 'FontSelection';

const moonDeskUser: string = 'MoonDeskUser';
const moonDeskReviewer: string = 'MoonDeskReviewer';
const moonDeskCompanyId: string = 'MoonDeskCompanyId';

export const defaultConfiguration: Configuration =
{
  tempDirectory: undefined,
  autoRefresh: true,
  exportOptions:
  {
    aiFile: true,
    outlineFile: true,
    pdfFile: true,
    pngFile: true,
    jpgFile: true,
    zipFile: true,
    downloadFiles: true,
    uploadFiles: true
  },
  fontSelection:
  {
    normalFontName: 'ArialNarrow',
    normalFontStyle: 'Narrow',
    boldFontName: 'Arial-BoldMT',
    boldFontStyle: 'Bold'
  }
};

export let BACKEND_INFO: BackendInfo;

@Injectable({
  providedIn: 'root'
})
export class IllustratorConfigService implements PlatformInfoProvider, UserProvider
{
  private prefix: string;
  private cachedConfiguration: Configuration;
  private initPromise: Promise<void>;

  backendInfo: BackendInfo;

  backendChanged: EventEmitter<void> = new EventEmitter<void>();
  configChanged: EventEmitter<Configuration> = new EventEmitter<Configuration>();

  constructor(private http: HttpClient,
    private illService: IllustratorService,
    private fileService: FilesystemService,
    private moonDeskConfig: MoonDeskConfigService)
  {
    console.log('Constructing IllustratorConfigService');
    const home = this.fileService.getHomeDirectory();
    const temp = this.fileService.join(home, tempFolderName);
    defaultConfiguration.tempDirectory = temp;
    console.log(`Defaultconf.temp: ${defaultConfiguration.tempDirectory}`);
    this.initPromise = this.initCache();
  }



  getClientType(): string
  {
    return 'Illustrator Extension';
  }

  async getCurrentUser(): Promise<User>
  {
    await this.initPromise;
    const pref = await this.getPreference(`${this.prefix}${moonDeskUser}`);
    if (!pref || pref === 'undefined')
    {
      return undefined;
    }
    const user = <User>(JSON.parse(pref));
    return user;
  }

  async storeUser(user: User): Promise<void>
  {
    await this.setPreference(`${this.prefix}${moonDeskUser}`, JSON.stringify(user));
  }

  async getLastCompanyId(): Promise<string>
  {
    const pref = await this.getPreference(`${this.prefix}${moonDeskCompanyId}`);
    return pref;
  }

  async storeCompanyid(id: string)
  {
    await this.setPreference(`${this.prefix}${moonDeskCompanyId}`, id);
  }

  async getLastReviewer(): Promise<Reviewer>
  {
    await this.initPromise;
    const pref = await this.getPreference(`${this.prefix}${moonDeskReviewer}`);
    if (!pref || pref === 'undefined')
    {
      return undefined;
    }
    const reviewer = <Reviewer>(JSON.parse(pref));
    return reviewer;
  }

  async storeReviewer(reviewer: Reviewer)
  {
    await this.setPreference(`${this.prefix}${moonDeskReviewer}`, JSON.stringify(reviewer));
  }

  async getFrontendInfo(): Promise<FrontendInfo>
  {
    if (!this.illService.initialized)
    {
      await this.illService.initPromise;
    }
    return this.illService.frontendInfo;
  }

  async getLogProperties(): Promise<{ [name: string]: string; }>
  {
    const fi = await this.getFrontendInfo();
    const bi = await this.getBackendInfo();
    const properties =
    {
      frontendName: fi.frontendName,
      frontendLocale: fi.frontendLocale,
      frontendVersion: fi.frontendVersion,
      cloudName: bi.cloudName,
      cloudVersion: bi.version,
      adobeUserId: fi.adobeUserId,
      adobeVersion: fi.adobeVersion,
      nodePlatform: fi.nodePlatform,
      nodeHostname: fi.nodeHostname
    };
    return properties;
  }

  async initCache()
  {
    const mdConfig = await this.moonDeskConfig.getMoonDeskConfig();
    BACKEND_INFO = await this.getBackendInfo();

    this.prefix = mdConfig.settingsPrefix;
    console.log(`############## prefix: ${this.prefix}`);
    await this.getConfiguration();
    this.configChanged.emit(this.cachedConfiguration);
  }

  getBackendUrl(): string
  {
    return environment.backendUrl;
  }

  getWebUrl(): string
  {
    return BACKEND_INFO.ipAddress;
  }

  async getBackendInfo(): Promise<BackendInfo>
  {
    let bi: BackendInfo = await new Promise<BackendInfo>(async(res, rej) =>
    {
      this.http.post<BackendInfo>(`${this.getBackendUrl()}/api/test/extensionconnectiontest`, {})
        .subscribe(response => res(response) , error => res(undefined));
    });
    if (!bi)
    {
      bi = {cloudName: '', version: '', ipAddress: ''};
    }
    return bi;
  }

  async getConfiguration(): Promise<Configuration>
  {
    // if(!this.prefix)
    // {
    //   throw Error("You called config service too early");
    // }
    let result: Configuration = defaultConfiguration;
    // Use the cache if present
    if (this.cachedConfiguration)
    {
      result = this.cachedConfiguration;
    }
    else
    {
      // let's get every setting
      try
      {
        const tempPath = await this.getPreference(`${this.prefix}${tempfolderConfigName}`);
        const autoRefreshPref = await this.getPreference(`${this.prefix}${autoRefresh}`);
        const exportOptionsJsonPref = await this.getPreference(`${this.prefix}${exportOptionsJson}`);
        const fontSelectionJsonPref = await this.getPreference(`${this.prefix}${fontSelectionJson}`);
        const exportOptions: ExportOptions = exportOptionsJson ? JSON.parse(exportOptionsJsonPref) : defaultConfiguration.exportOptions;
        const fontSelection: FontSelection = fontSelectionJson ? JSON.parse(fontSelectionJsonPref) : defaultConfiguration.fontSelection;
        result = {
          tempDirectory: tempPath ? tempPath : defaultConfiguration.tempDirectory,
          autoRefresh: autoRefreshPref ? autoRefreshPref === 'true' : defaultConfiguration.autoRefresh,
          exportOptions: exportOptions,
          fontSelection: fontSelection
        };
      }
      catch (err)
      {
        console.log(`Error getting preference from Illustrator, using Default...`);
      }
    }
    this.updateDownloadFilesFlag(result.exportOptions);
    await this.verifyTempFolder(result);
    // Update the cache
    this.cachedConfiguration = result;
    // Return a copy, so the settings dialog doesn't change the original when user doesn't 'Save'
    return Object.assign({}, result);
  }

  private async verifyTempFolder(config: Configuration)
  {
    const tempDirectory = config.tempDirectory;
    const parsed = this.fileService.parse(tempDirectory);
    if (!this.fileService.exists(parsed.dir))
    {
      console.log(`Invalid temp folder, setting Default...`);
      const home = this.fileService.getHomeDirectory();
      config.tempDirectory = this.fileService.join(home, tempFolderName);
      await this.setConfiguration(config);
    }
  }

  /**
   * Sets the 'downloadFiles' flag based on the specified export options.
   * If no specific file format is set to be downloaded, 'downloadFiles' is set to false.
   */
  private updateDownloadFilesFlag(exportOptions: ExportOptions)
  {
    exportOptions.downloadFiles = (
      exportOptions.downloadAiFile ||
      exportOptions.downloadLightPdfFile ||
      exportOptions.downloadPdfFile ||
      exportOptions.downloadOutlineFile
    );
  }

  getDefaultConfiguration(): Configuration
  {
    return defaultConfiguration;
  }

  async setExportOptions(exOpts: ExportOptions)
  {
    await this.setPreference(`${this.prefix}${exportOptionsJson}`, JSON.stringify(exOpts));
    this.cachedConfiguration.exportOptions = exOpts;
    this.configChanged.emit(this.cachedConfiguration);
  }

  async setFontsOptions(fontSelection: FontSelection)
  {
    await this.setPreference(`${this.prefix}${fontSelectionJson}`, JSON.stringify(fontSelection));
    this.cachedConfiguration.fontSelection = fontSelection;
    this.configChanged.emit(this.cachedConfiguration);
  }

  async setConfiguration(configuration: Configuration)
  {
    const path = this.illService.prepareFilepath(configuration.tempDirectory);
    await this.setPreference(`${this.prefix}${tempfolderConfigName}`, path);
    await this.setPreference(`${this.prefix}${autoRefresh}`, `${configuration.autoRefresh}`);
    this.cachedConfiguration = configuration;
    this.configChanged.emit(configuration);
    this.backendChanged.emit();
  }

  private async getPreference(name: string): Promise<string>
  {
    const configString = await this.illService.evalScript(`getStringPreference('${name}')`, true);
    if (configString && configString.startsWith('Error'))
    {
      throw Error(configString);
    }
    return configString;
  }

  private async setPreference(name: string, value: string): Promise<void>
  {
    const res = await this.illService.evalScript(`setStringPreference('${name}', '${value}')`, true);
    if (res && res.startsWith('Error'))
    {
      throw Error(res);
    }
  }

  getEnvironment(): 'WEB' | 'EXT'
  {
    return 'EXT';
  }
}
