<div class="work">

  <!-- HEADER -->
  <div class="header">
    <work-header
      [id]="'work-header-component-' + mode"
      [mode]="mode"
      [(keepTags)]="keepTagsForNewVersion">
    </work-header>
  </div>
  <mat-divider class="header-divider" *ngIf="this.currentDocument"></mat-divider>

  <!-- NO DOCUMENT -->
  <div class="no-document" *ngIf="showImportHelp()">
    <button class="md-button md-button-highlighted" 
        (click)="illustratorMenuCommand('new')">
      New File
    </button>
    <button class="md-button md-button-highlighted" 
        (click)="illustratorMenuCommand('open')">
      Open File
    </button>
    <button class="md-button md-button-highlighted" 
        *ngIf="!isTaskOpen()"
        (click)="bulkImportClicked()">
      Bulk Import
    </button>
  </div>

  <div class="no-document" *ngIf="showEditFromQueue()">
    Please select a document from the todo list.
  </div>

  <div class="no-document" *ngIf="showEditFromSearch()">
      <button class="md-button md-button-highlighted" 
          *ngIf="!isTaskOpen()"
          (click)="navigate('search')">
        Search
      </button>
      <button class="md-button md-button-highlighted" 
          *ngIf="hasComm() && !isTaskOpen()"
          (click)="navigate('tasks')">
        Tasks
      </button>
      <button class="md-button md-button-highlighted" 
          *ngIf="isTaskOpen() && taskHasDocuments()"
          (click)="openCurrentTasksDocuments()">
        Open Documents from Task
      </button>
      <div *ngIf="isTaskOpen() && !taskHasDocuments()">The current Task has no documents</div>
  </div>
  
  <!-- DOWNLOAD PROGRESS -->
  <div class="progress" *ngIf="showDownloadProgress()">
    <mat-progress-bar
      [color]="'accent'" 
      [mode]="downloadProgressMode()" 
      [value]="workManagerStatus.progress ? workManagerStatus.progress : 0">
    </mat-progress-bar>
    <div *ngIf="workManagerStatus.details" class="status">{{workManagerStatus.details}}</div>
    <button class="md-button cancel-btn" *ngIf="workManagerStatus.cancelAction" (click)="workManagerStatus.cancelAction()">Cancel</button>
  </div>

  <!-- CONTENT -->
  <div class="content">
    <as-split direction="vertical" useTransition="true" disabled="false">
  
      <as-split-area class="split-area filters" minSize="30" maxSize="80">
        <div class="current-document">
          <app-current-document [mode]="mode"></app-current-document>
        </div>
      </as-split-area>
  
      <as-split-area class="split-area" minSize="20" maxSize="90">
  
        <mat-divider class="content-divider" *ngIf="mode==='Edit'&&this.currentDocument"></mat-divider>
        <div class="work-manager" [ngClass]="{'work-manage-min' : mode !== 'Import'}">
          <app-work-manager class="work-manager-component" [mode]="mode"></app-work-manager>
        </div>
  
      </as-split-area>
    </as-split>
  </div>


  <!-- FOOTER -->
  <div class="footer">
    <save-document [mode]="mode" [keepTags]="shouldKeepTags()"></save-document>
  </div>
  
</div>
