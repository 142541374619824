<div class="document-ai-analysis">
    <div class="spinner-container" *ngIf="busy">
        <mat-progress-spinner class="spinner" diameter="32" color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
    <ng-container *ngIf="!busy">
        <div class="main" *ngIf="aIAnalyses else noAnalysisTemplate">

            <!-- AI GENERAL -->
            <div class="expansion">
                <div class="expansion-panel-title" (click)="isGeneralExpanded = !isGeneralExpanded">
                    <span class="label" translate>lid.ext.pages.document-ai-analysis.general</span>
                    <mat-icon class="chevron-icon">{{ isGeneralExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </div>
                <ng-container *ngIf="isGeneralExpanded">
                    <div class="container-ai-analysis" *ngIf="aIGeneralAnalysis else noAnalysisTemplate">
                        <markdown class="data" [data]="aIGeneralAnalysis.message"></markdown>
                    </div>
                </ng-container>
            </div>
    
            <!-- AI RULES -->
            <div class="expansion">
                <div class="expansion-panel-title" (click)="isRulesExpanded = !isRulesExpanded">
                    <span class="label" translate>lid.ext.pages.document-ai-analysis.rules</span>
                    <mat-icon class="chevron-icon">{{ isRulesExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </div>
                <ng-container *ngIf="isRulesExpanded">
                    <div class="container-ai-analysis" *ngIf="aIRulesAnalysis else noAnalysisTemplate">
                        <markdown class="data" [data]="aIRulesAnalysis.message"></markdown>
                    </div>
                </ng-container>
            </div>

        </div>
    
        <ng-template #noAnalysisTemplate>
            <span class="no-ai-analysis" translate>lid.ext.pages.document-ai-analysis.noAnalysisTemplate</span>
        </ng-template>
    </ng-container>
</div>
