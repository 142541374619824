import { Injectable } from '@angular/core';
import { MoonDeskConfig } from '../../../../_shared/models/MoonDeskConfig';
import { CSInterface, SystemPath } from 'csinterface-ts';
import { FilesystemService } from './filesystem.service';
/**
 * This service provides the settings from the (static) asset moondesk.config.json
 * that is delivered with the extension, and can contain different values for
 * debug (in container use), test and production.
 */
@Injectable({
  providedIn: 'root'
})
export class MoonDeskConfigService
{
  private moonDeskConfig: MoonDeskConfig;
  private initialized: boolean = false;
  private initPromise: Promise<void>;
  private csInterface: CSInterface;

  constructor(private fileService: FilesystemService)
  {
    this.csInterface = new CSInterface();
    this.initPromise = this.loadMoonDeskConfig();
  }

  async getMoonDeskConfig(): Promise<MoonDeskConfig>
  {
    if (!this.initialized)
    {
      console.log('getMoondeskConfig, gotta wait...');
      await this.initPromise;
    }
    console.log('getMoondeskConfig done');
    return this.moonDeskConfig;
  }

  private async loadMoonDeskConfig(): Promise<void>
  {
    const configFilePath = this.getMoonDeskConfigFilePath();
    const configFile = <string>await this.fileService.readFileAsync(configFilePath, {encoding: 'utf8'});
    const config = <MoonDeskConfig>JSON.parse(configFile);
    console.log(`MoonDeskConfig: ${JSON.stringify(config)}`);
    this.moonDeskConfig = config;
  }

  private getMoonDeskConfigFilePath(): string
  {
    const extensionRoot = this.csInterface.getSystemPath(SystemPath.EXTENSION);
    const distFolder = this.fileService.join(extensionRoot, 'dist');
    const assetsFolder = this.fileService.join(distFolder, 'assets');
    const filepath = this.fileService.join(assetsFolder, 'moondesk.config.json');
    const cleanPath = this.prepareFilepath(filepath);
    return cleanPath;
  }

  private prepareFilepath(path: string): string
  {
    if (!path || path === '')
    {
      console.log('invalid path');
      return;
    }
    // backslashes (like in windows) must be duplicated,
    // because they're escape characters
    if (path.indexOf('\\') >= 0)
    {
      const x = path.split('\\');
      path = x.join('\\\\');
    }
    // apostrophes inside a string need an escape character
    if (path.indexOf(`\\'`) >= 0)
    {
      const x = path.split(`\\'`);
      path = x.join(`\\\'`);
    } {
    return path;
    }
  }
}
