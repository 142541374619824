import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Host,
  Inject,
  OnDestroy
} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {Subject} from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'moon-datepicker-header',
  templateUrl: './moon-datepicker-header.component.html',
  styleUrls: ['./moon-datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})

export class MoonDatepickerHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(@Host() private calendar: MatCalendar<D>,
              private dateAdapter: DateAdapter<D>,
              @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
              cdr: ChangeDetectorRef) {
   // calendar.stateChanges
   //    .pipe(takeUntil(this.destroyed))
   //     .subscribe(() => cdr.markForCheck());
                console.log('MoonDatepickerComponent constructor');
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    return this.dateAdapter
        .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
        .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate = mode === 'month' ?
        this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
        this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate = mode === 'month' ?
        this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
        this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }
}

