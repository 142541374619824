<div class="download-dropdown">

    <!-- DOWNLOAD ICON -->
    <mat-icon class="notranslate" [matMenuTriggerFor]="downloadOptions" *ngIf="!downloading">
        file_download
    </mat-icon>
    <div *ngIf="downloading">
        <mat-progress-spinner class="spinner" color="accent" diameter="16" mode="indeterminate"></mat-progress-spinner>
    </div>

    <!-- DOWNLOAD MENU -->
    <mat-menu #downloadOptions="matMenu" [yPosition]="'above'">
        <ng-container *ngIf="exportFiles">
            <button mat-menu-item class="dropdown-option" (click)="download('fullImage.png')">
                <mat-icon class="notranslate">
                    file_download
                </mat-icon>
                <span class="text">PNG</span>
            </button>
            <ng-container *ngFor="let eo of exportsOptions">
                <button mat-menu-item class="dropdown-option" (click)="download(eo.exportFile)" *ngIf="eo.allowed">
                    <mat-icon class="notranslate">
                        file_download
                    </mat-icon>
                    <span class="text">{{ eo.description }}</span>
                </button>
            </ng-container>
        </ng-container>

        <div class="progress" *ngIf="downloadingAll">
            <ng-container *ngIf="downloadAllProgress"> 
                <mat-progress-spinner class="spinner" color="accent" diameter="16" mode="indeterminate"></mat-progress-spinner>
                <div class="info">
                    <span class="text">Generating Zip</span>
                    <span class="amount">{{downloadAllProgress.percentage}}%</span>
                </div>    
                <button class="moon-btn pill-btn full-light-btn r-4" type="button" (click)="cancelDownloadAll()">Cancel</button>
            </ng-container>    
        </div>

    </mat-menu>
</div>
